import React from 'react';
import { connect } from 'react-redux';
import UserSide from '../userProfile/UserSide';
import SideNavItemList from './SideNavItemList';
import SideNavList from './SideNavList';
import { setCurrentForm } from '../../redux/Actions/trip';

import { FadeLoader } from 'react-spinners';

import css from '@emotion/css';

const override = css`
	display: block;
	margin: 5rem auto;
	border-color: red;
`;

const SideNav = ({ adminLoading }) => {
	const returnSecondaryInfo = () => {
		console.log('window.location', window.location);
		const pathname = window.location.pathname;
		if (pathname.includes('/dashboard/user')) {
			return <UserSide />;
		}
		if (pathname.includes('/create-itinerary/')) {
			return <SideNavItemList />;
		}
	};
	return (
		<aside className='sidenav'>
			<SideNavList />

			{adminLoading ? (
				<div className='loading'>
					<FadeLoader css={override} />
				</div>
			) : (
				returnSecondaryInfo()
			)}
			{null}
		</aside>
	);
};

export default connect(null, { setCurrentForm })(SideNav);
