import React, { useEffect } from 'react';
import { handleVehicleType } from '../../Utilities/carHelpers';
import { bootSizeCodes } from '../../Utilities/cars';
import { toDollarStyle } from '../../Utilities/Utils';
import carIcon from '../../images/noun-car-5226133-142350.svg';
import { addItineraryItem } from '../../redux/Actions/trip';
import { connect } from 'react-redux';
import { useState } from 'react';
import { post } from '../../Utilities/api';

import { FadeLoader } from 'react-spinners';
import css from '@emotion/css';

const override = css`
	display: block;
	margin: 5rem auto;
	border-color: red;
`;

const CarReview = ({
	vehicleTypeInfo,
	perDay,
	estimatedTotal,
	pictureURL,
	termsAndConditions,
	locationName,
	airportCode,
	terminalString,
	category,
	miles,
	type,
	transmission,
	hasAirConditioning,
	bootSize,
	deleteReviewItem,
	rate,
	addItineraryItem,
	tripId,
	isSelected,
	authorization,
	itemId,
}) => {
	const { carDetail, companyLocation } = rate;
	const [loading, setLoading] = useState(false);
	const handleAdd = () => {
		const itineraryItem = {
			type: 'amadeus-car',
			trip: tripId,
			data: { location: companyLocation, ...carDetail },
		};

		addItineraryItem('amadeusCars', itineraryItem);
	};
	const updateCar = async () => {
		setLoading(true);
		const res = await post(
			'/api/v1/amadeus_test/start_car_live',
			{ item_id: itemId },
			true
		);
		if (res.data.success) setLoading(false);
	};

	useEffect(() => {
		if (authorization) {
			updateCar();
		}
	}, []);
	return loading ? (
		<FadeLoader
			css={override}
			size={150}
			//size={"150px"} this also works
			color={'black'}
			//initializes own piece of state, was conflicting with state.loading
			loading={true}
		/>
	) : (
		<div className='car-review'>
			<img
				className='car-review__image'
				src={pictureURL}
				alt='car option'
				onError={({ currentTarget }) => {
					currentTarget.onerror = null; // prevents looping
					currentTarget.className = 'car-review__image-none';
					currentTarget.src = carIcon;
				}}
			/>

			<div className='car-review__vehicle-info'>
				<h3 className='car-review__title'>
					{handleVehicleType(vehicleTypeInfo)}
				</h3>
				<section className='car-review__details'>
					<div className='left'>
						<p>
							<strong>Category:</strong>
							{category}
						</p>
						<p>
							<strong>Transmission:</strong> {transmission}
						</p>

						<p className='luggage-capacity'>
							<strong>Luggage Capacity:</strong>
							{bootSize ? bootSizeCodes[bootSize] : 'Unavailable'}
						</p>
					</div>
					<div className='right'>
						<p>
							<strong>Type:</strong>
							{type}
						</p>
						{miles ? (
							<p>
								<strong>Mileage:</strong>
								{miles}
							</p>
						) : null}
					</div>
				</section>

				<section className='car-review__secondary-details'>
					{hasAirConditioning && (
						<p className='air-conditioning'>Air Conditioning Included</p>
					)}
					<p>
						{termsAndConditions
							? 'terms and conditions apply'
							: 'No conditions apply'}
					</p>
				</section>

				<div className='car-review__dropoff'>
					Pickup and Dropoff:{' '}
					<span className='capitalize'>{locationName?.toLowerCase()}</span> (
					{airportCode}) {terminalString}
				</div>
			</div>

			<div className='car-review__price'>
				<div className='car-review__price-text'>
					<span className='total'>
						{`${toDollarStyle(
							estimatedTotal.tariffInfo.rateAmount,
							estimatedTotal.tariffInfo.rateCurrency
						)} `}{' '}
					</span>

					<span className='per-day'>
						{`~${toDollarStyle(
							perDay,
							estimatedTotal.tariffInfo.rateCurrency
						)}/day`}
					</span>
				</div>
				{isSelected ? (
					<button className='item-review__button' onClick={deleteReviewItem}>
						Remove
					</button>
				) : (
					<button className='item-review__button' onClick={handleAdd}>
						Select
					</button>
				)}

				{/* {!authorization && rateDetailsInfo && rateDetailsInfo.length ? (
					<button onClick={handleAdd}>
						{selected ? 'Selected' : 'Select'}
					</button>
				) : null} */}
			</div>

			{/* {!authorization && <button onClick={handleAdd}>Select</button>} */}
		</div>
	);
};

const mapStateToProps = (state) => ({
	tripId: state.trip._id,
});

export default connect(mapStateToProps, { addItineraryItem })(CarReview);
