import React from 'react';
import { extractTotalPerNight } from '../../Utilities/helperFunctions';

import noImage from '../../images/noun_Camera_prohibited_1554484.svg';
import AddHotelUrl from './AddHotelUrl';

const HotelSummaryNew = ({
	imageURL,
	hotelName,
	address,
	url,
	distance,
	sortedRoomStays,
	setShowRoomStays,
	showRoomStays,
	hotelCode,
	customUrl,
}) => {
	const handleButtonDisplay = () => {
		if (sortedRoomStays && sortedRoomStays.length) {
			return (
				<div className='right'>
					<p>
						Starting at {extractTotalPerNight(sortedRoomStays[0])}
						{sortedRoomStays[0].hasConversion
							? `/${extractTotalPerNight(sortedRoomStays[0], true)}`
							: null}{' '}
						per night
					</p>

					<button
						className='hotel__button'
						onClick={() => setShowRoomStays(!showRoomStays)}>
						{showRoomStays ? 'Hide' : 'Show'} Rooms
					</button>
				</div>
			);
		}
	};

	return (
		<div className='hotel-summary'>
			<img
				src={imageURL}
				alt=''
				onError={({ currentTarget }) => {
					currentTarget.onerror = null; // prevents looping
					currentTarget.src = noImage;
				}}
			/>
			<main>
				<h3 className='capitalize'>{hotelName.toLowerCase()}</h3>
				{url || customUrl ? (
					<button
						href={url || customUrl}
						onClick={() => window.open(url || 'https://' + customUrl, '_blank')}
						className='itinerary-item-url'
						target='_blank'
						rel='noopener noreferrer'>
						website
					</button>
				) : (
					<AddHotelUrl hotelCode={hotelCode} />
				)}
				{address.AddressLine && address.AddressLine ? (
					<p className='capitalize'>
						{address.AddressLine.trim().toLowerCase()}
					</p>
				) : null}
				{address && address.CityName ? (
					<p className='capitalize'>
						{address.CityName.toLowerCase()},{' '}
						{address && address.StateCode ? address.StateCode + ' ' : null}
						{address && address.PostalCode ? address.PostalCode : null}
					</p>
				) : null}
				{distance ? (
					<>
						<p className='distance-from'>
							{`${distance.distance} ${distance.units}`} from city center
						</p>
					</>
				) : null}
			</main>
			{handleButtonDisplay()}
		</div>
	);
};

export default HotelSummaryNew;
