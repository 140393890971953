import React from 'react';

const DayOptions = ({ dayOptions, dayOptionCallback }) => {
	let {
		Monday = true,
		Tuesday = true,
		Wednesday = true,
		Thursday = true,
		Friday = true,
		Saturday = true,
		Sunday = true,
	} = dayOptions || {};
	console.log('Tuesday', Tuesday);
	return (
		<div className='flex-section day-options'>
			<label htmlFor='Monday'>
				<input
					type='checkbox'
					id='mon'
					checked={Monday}
					name='Monday'
					onChange={(e) => {
						dayOptionCallback(e);
					}}
				/>
				Monday
			</label>
			<label htmlFor='Tuesday'>
				<input
					type='checkbox'
					checked={Tuesday}
					name='Tuesday'
					onChange={(e) => {
						dayOptionCallback(e);
					}}
				/>
				Tuesday
			</label>
			<label htmlFor='Wednesday'>
				<input
					type='checkbox'
					checked={Wednesday}
					name='Wednesday'
					onChange={(e) => {
						dayOptionCallback(e);
					}}
				/>
				Wednesday
			</label>
			<label htmlFor='Thursday'>
				<input
					type='checkbox'
					checked={Thursday}
					name='Thursday'
					onChange={(e) => {
						dayOptionCallback(e);
					}}
				/>
				Thursday
			</label>
			<label htmlFor='Friday'>
				<input
					type='checkbox'
					checked={Friday}
					name='Friday'
					onChange={(e) => {
						dayOptionCallback(e);
					}}
				/>
				Friday
			</label>
			<label htmlFor='Saturday'>
				<input
					type='checkbox'
					checked={Saturday || false}
					name='Saturday'
					onChange={(e) => {
						dayOptionCallback(e);
					}}
				/>
				Saturday
			</label>
			<label htmlFor='Sunday'>
				<input
					type='checkbox'
					checked={Sunday || false}
					name='Sunday'
					onChange={(e) => {
						dayOptionCallback(e);
					}}
				/>
				Sunday
			</label>
		</div>
	);
};

export default DayOptions;
