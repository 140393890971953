import React from 'react';
import FileInput from '../CustomData/FileInput';

const ImageInput = ({ fileName, handleImage }) => {
	return (
		<FileInput
			fileName={fileName}
			name='imageFile'
			classNames='image'
			type='file'
			title='Image'
			handleChange={handleImage}
		/>
	);
};

export default ImageInput;
