import React from 'react';
import { connect } from 'react-redux';
import { overwriteTrip } from '../../redux/Actions/trip';
import CarResults from '../Cars/CarResults';
import FindFlights from '../Flights/FindFlights';
import FindHotels from '../Hotels/FindHotels';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormModal from '../Modal/FormModal';
import PreferenceSection from '../createItinerary/PreferenceSection';

import { FadeLoader } from 'react-spinners';
import css from '@emotion/css';
const override = css`
	display: block;
	margin: 5rem auto;
	border-color: red;
`;

toast.configure();

const QueryWindowMain = ({
	currentSearch,
	trip,
	overwriteTrip,
	currentForm,
	modalXAxis,
}) => {
	const queryComponent = {
		carRental: CarResults,
		flight: FindFlights,
		hotel: FindHotels,
	};

	const returnComponent = () => {
		if (currentSearch) {
			const Component = queryComponent[currentSearch];
			return <Component />;
		} else {
			return null;
		}
	};

	const saveForLater = async () => {
		const map = {};

		trip.hotelDetails.forEach((item1) => {
			item1.hotelCache.forEach((item2) => {
				map[item2] = item1._id;
			});
		});
		const newLodgingArray = trip.itineraryItems.lodging.map((item) => {
			return item.hotelDetail
				? item
				: {
						...item,
						hotelDetail: map[item._id],
				  };
		});
		let result = await overwriteTrip({
			...trip,
			saveForLater: true,
			itineraryItems: {
				...trip.itineraryItems,
				lodging: newLodgingArray,
			},
		});
		if (!result) {
			toast('Something went wrong...');
		} else {
			toast('Success!', { type: 'success' });
		}
	};

	const curateTrip = async (payload) => {
		const map = {};

		trip.hotelDetails.forEach((item1) => {
			item1.hotelCache.forEach((item2) => {
				map[item2] = item1._id;
			});
		});
		const newLodgingArray = trip.itineraryItems.lodging.map((item) => {
			return item.hotelDetail
				? item
				: {
						...item,
						hotelDetail: map[item._id],
				  };
		});
		let result = await overwriteTrip({
			...trip,
			itineraryItems: {
				...trip.itineraryItems,
				lodging: newLodgingArray,
			},
			curated: true,
		});

		if (!result) {
			toast('Something went wrong...');
		} else {
			toast('Success!', { type: 'success' });
		}
	};
	return (
		<main className='query-window__main'>
			<FormModal xAxis={modalXAxis} />

			{trip.loading ? (
				<div className='loader__container'>
					<FadeLoader
						css={override}
						size={150}
						//size={"150px"} this also works
						color={'black'}
						//initializes own piece of state, was conflicting with state.loading
						loading={true}
					/>
				</div>
			) : (
				<>
					<div className='top'>
						<h1>Create Itinerary</h1>
						<div className='button-section'>
							{trip.curated ? (
								<>
									<button onClick={saveForLater}>Save For Later</button>
									<button onClick={curateTrip}>Update</button>
								</>
							) : (
								<>
									<button onClick={saveForLater}>Save For Later</button>
									<button onClick={curateTrip}>Curate</button>
								</>
							)}
							<a
								href={`${process.env.REACT_APP_USER_URL}/admin-edit-trip/${trip._id}`}>
								{' '}
								Edit Trip Profile
							</a>
							<a
								href={`${process.env.REACT_APP_USER_URL}/admin-plan-trip/${trip._id}`}>
								Review Itinerary
							</a>
						</div>
					</div>

					{returnComponent()}
					<aside className='preferences'>
						<PreferenceSection
							preferences={trip.user.preferences}
							currentSearch={currentForm}
						/>
					</aside>
				</>
			)}
		</main>
	);
};
const mapStateToProps = (state) => ({
	currentSearch: state.trip.currentSearch,
	currentForm: state.trip.currentForm,
	trip: state.trip,
});

export default connect(mapStateToProps, { overwriteTrip })(QueryWindowMain);
