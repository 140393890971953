const initState = {};

const appErrors = (state = initState, action) => {
	const { type, payload } = action;
	switch (type) {
		case 'ADD_ERROR':
			return {
				...state,
				[payload.string]: true,
			};
		case 'REMOVE_ERRORS':
			return {};
		default:
			return state;
	}
};

export default appErrors;
