import React, { useEffect, useState } from 'react';
import { FadeLoader } from 'react-spinners';
import {
	setAttachments,
	removeAttachment,
	updateAttachments,
} from '../../redux/Actions/trip';
import css from '@emotion/css';
import { connect } from 'react-redux';
const override = css`
	display: block;
	margin: 1rem auto;
`;

const Attachments = ({
	trip_id,
	attachments,
	setAttachments,
	removeAttachment,
	updateAttachments,
}) => {
	const [loading, setLoading] = useState(true);
	const [buttonText, setButtonText] = useState('Save');

	const queryAttachments = async () => {
		try {
			const res = await setAttachments(trip_id);
			if (res === true) {
				setLoading(false);
			}
		} catch (err) {
			console.log(err.message);
		}
	};

	const saveAttachments = async () => {
		setButtonText('Loading');
		try {
			const res = await updateAttachments({ trip_id, attachments });
			if (res.data.success) {
				setButtonText('Saved!');
				setTimeout(() => {
					setButtonText('Save');
				}, 3000);
			}
		} catch (err) {
			console.log(err.message);
		}
	};

	const returnAttachments = () => {
		return attachments && attachments.length
			? attachments.map((attachment, index) => {
					return (
						<div key={`attachment-${attachment._id}`} className='attachment'>
							<a href={attachment.path}>{attachment.name}</a>
							<button
								onClick={() => removeAttachment(index)}
								className='delete'>
								<i className='fa fa-times'></i>
							</button>
						</div>
					);
			  })
			: null;
	};
	useEffect(() => {
		queryAttachments();
	}, []);

	return (
		<div className='attachments'>
			{loading ? (
				<div className='newcss-sweet-loading newcss-80vh'>
					<FadeLoader
						css={override}
						size={150}
						color={'black'}
						loading={true}
					/>
				</div>
			) : (
				<>{returnAttachments()}</>
			)}
			<button className='save-button' onClick={saveAttachments}>
				{buttonText}
			</button>
		</div>
	);
};

const mapStateToProps = (state) => ({
	attachments: state.trip.attachments,
});

export default connect(mapStateToProps, {
	setAttachments,
	removeAttachment,
	updateAttachments,
})(Attachments);
