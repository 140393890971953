import React from 'react';
import { Fragment } from 'react';
import { checkType } from '../../../../Utilities/helperFunctions';

const ItemDetails = ({
	title,
	url,
	rating,
	price,
	description,
	groupCapacity,
	type,
	selectedItem,
	perNight,
	hotelPickup,
	duration,
}) => {
	const { activity, lodging } = checkType(type);
	const handlePricePerPerson = () => {
		const isGroupPricing = type === ('private-transport' || 'group-activity');
		return isGroupPricing ? (
			<span>{`Max ${groupCapacity} per group`}</span>
		) : null;
	};
	const approximate = activity || lodging;
	const handleAnchor = () => {
		return url ? (
			<button
				href={url}
				onClick={() => window.open(url, '_blank')}
				className='itinerary-item-url'
				target='_blank'
				rel='noopener noreferrer'>
				{lodging && 'hotel '}website
			</button>
		) : (
			<span className='no-url'>No URL</span>
		);
	};
	return (
		<Fragment>
			<header className='itinerary-item-header'>
				<div className='left'>
					<h3 className='itinerary-item-title capitalize'>{title}</h3>
					{handleAnchor()}

					{rating && (
						<span className='rating'>&#9733; {rating ? rating : 'N/A'}/5</span>
					)}
				</div>
				<div className='price'>
					<span>
						{approximate && '~ '}
						{perNight ? (
							<>
								{`${perNight}`}
								<br />
							</>
						) : null}
						{price}

						{handlePricePerPerson()}
					</span>
					{activity && hotelPickup && <span>Hotel Pickup Included</span>}

					{activity && !hotelPickup && <span>Hotel Pickup Not Included</span>}
					{duration && <span>{duration} hours</span>}
				</div>
			</header>
			<main className='itinerary-item-main'>
				{description && <p>{description}</p>}
			</main>
		</Fragment>
	);
};

export default ItemDetails;
