import React from 'react';
import { selectUser } from '../../redux/Actions/adminActions';
import { connect } from 'react-redux';

const UserItem = ({ user, selectUser }) => {
	return user ? (
		<>
			<a href={`/dashboard/user/${user._id}`}>
				<div className='list-card'>
					<span>{user._id}</span>
					<span>{`${user.firstName} ${user.lastName}`}</span>
					<span>{user.email}</span>
					<span>{user.lastActive || 'N/A'}</span>
				</div>
			</a>
		</>
	) : null;
};

export default connect(null, { selectUser })(UserItem);
