import React from 'react';
import { connect } from 'react-redux';
import { addError } from '../redux/Actions/appErrors';
import { post } from '../Utilities/api';
import ErrorPage from './pages/ErrorPage';
import redirectHOC from './hoc/RedirectHOC';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };

		// Add name of the function to error logging
		Function.prototype.create = function () {
			this.displayName = this.name;
		};
	}

	static getDerivedStateFromError(error) {
		return { hasError: true };
	}

	//log the error to an error reporting service
	componentDidCatch(error, errorInfo) {
		let last_error_time = localStorage.getItem('last_error_time');
		let now_time = new Date();

		localStorage.setItem('last_error_time', now_time.toISOString());

		let error_string = error.message;
		let error_info_string = JSON.stringify(errorInfo);

		console.log('errorFull', error_string);
		console.log('errorInfoFull', error_info_string);

		let error_details = `${error_string}, ERROR STACK: ${error_info_string}`;

		let is_over_5_seconds;
		if (last_error_time) {
			var startDate = new Date(last_error_time);
			var endDate = now_time;
			var seconds = (endDate.getTime() - startDate.getTime()) / 1000;

			console.log('startDate', startDate);
			console.log('endDate', endDate);
			console.log('seconds', seconds);

			is_over_5_seconds = seconds >= 5;
		} else {
			is_over_5_seconds = true;
		}

		if (is_over_5_seconds) {
			post(
				'/api/v1/auth/save_error',
				{
					location: 'front-end-admin',
					error_details: {
						error_details,
						number_of_occurances: 1,
					},
					endpoint: window.location.href,
				},
				true
			);
		}

		return <ErrorPage />;
	}

	render() {
		if (this.state.hasError) {
			return <ErrorPage />;
		} else {
			return this.props.children;
		}
	}
}

const mapStateToProps = (state) => ({
	appErrors: state.appErrors,
});

export default connect(mapStateToProps, { addError })(
	redirectHOC(ErrorBoundary)
);
