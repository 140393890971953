const initState = {
	hotels: [],
	bookingOptions: [],
	descriptiveInfo: null,
	liveHotelItem: null,
	loading: false,
	error: '',
	hotelDetail: null,
};

const flightReducer = (state = initState, action) => {
	const { type, payload } = action;
	switch (type) {
		case 'SET_LIVE_HOTEL_ITEM':
			return {
				...state,
				liveHotelItem: payload,
				loading: false,
			};
		case 'SET_HOTEL_ERROR':
			return {
				...state,
				error: payload,
				loading: false,
			};
		case 'CLEAR_LIVE_HOTEL_ITEM':
			return {
				...state,
				liveHotelItem: null,
				loading: false,
			};
		case 'SET_HOTEL_DETAIL':
			return {
				...state,
				hotelDetail: payload,
			};
		case 'ADD_HOTEL_URL':
			return {
				...state,
				hotels: state.hotels?.map((hotel) => {
					const currentHotelCode = hotel.hotel_info.info['HotelCode'];
					if (payload.hotelCode === currentHotelCode) {
						return {
							...hotel,
							customUrl: payload.url,
						};
					} else {
						return hotel;
					}
				}),
			};
		case 'SET_HOTELS':
			let hotels = payload.hotels;
			let descriptiveInfo = payload.descriptiveInfo;

			if (state.more_indicator) {
				hotels = state.hotels.concat(hotels);
				descriptiveInfo = {
					...state.descriptiveInfo,
					...descriptiveInfo,
				};
			}

			return {
				...state,
				hotels,
				descriptiveInfo,
				more_indicator: payload.more_indicator,
				loading: false,
			};
		case 'STATELESS_HOTEL_SEARCH':
			return {
				...state,
				bookingOptions: payload.bookingOptions,
			};
		case 'FETCH_HOTELS_SUCCESS':
			let dictionaries;
			if (payload.data.data.dictionaries) {
				dictionaries = payload.data.data.dictionaries;
			} else {
				dictionaries = null;
			}
			return {
				...state,
				hotels: payload.data.data.data,
				descriptiveInfo: dictionaries,
				loading: false,
			};
		case 'SET_HOTEL_LOADING':
			return {
				...state,
				loading: payload,
			};
		case 'CLEAR_HOTELS':
			return {
				hotels: [],
				descriptiveInfo: null,
				selectedFlight: [],
				loading: false,
			};
		case 'FETCH_HOTELS_ERROR':
			return state;
		default:
			return state;
	}
};

export default flightReducer;
