import React from 'react';
import arrow from '../../images/noun_Arrow_1256499.svg';
import ItemList from '../lists/ItemList';

const TripContainer = ({ trips, toggleContainer, redirectPath, newTrips }) => {
	return (
		<>
			<button onClick={toggleContainer} className='auth-item__back'>
				<img src={arrow} alt='' />
			</button>

			<ItemList newTrips={newTrips} redirectPath={redirectPath} trips={trips} />
		</>
	);
};

export default TripContainer;
