import React from 'react';

const SideNavButton = ({ title, icon }) => {
	return (
		<li className='sidenav__button'>
			<div className='sidenav__button-inner'>
				<img className='sidenav__button-icon' src={icon} alt='' />
				{title}
			</div>
		</li>
	);
};

export default SideNavButton;
