import { post } from '../../Utilities/api';
import { setCurrentSearch } from './trip';

export const fetchFlights = (searchBody) => async (dispatch) => {
	dispatch(setFlightsLoading());
	dispatch(setCurrentSearch('flight'));
	try {
		const authRequired = true;
		const res = await post(
			'/api/v1/amadeus_test/search_flights',
			searchBody,
			authRequired
		).catch((err) => {
			throw new Error(err.message);
		});
		dispatch({ type: 'FETCH_FLIGHTS_SUCCESS', payload: res.data.flights });
	} catch (err) {
		const message = err.message;
		console.log(message);
	}
};

export const filterFlightRec = (index) => (dispatch) => {
	console.log('FILTER_FLIGHT_REC');
	dispatch({
		type: 'FILTER_FLIGHT_REC',
		payload: index,
	});
};

export const saveFlightId = (payload) => {
	return (dispatch) => {
		if (payload.bool === true) {
			payload = payload.payload;
			dispatch({ type: 'SAVE_FLIGHT_ID_SUCCESS', payload });
		} else {
			dispatch({ type: 'SAVE_FLIGHT_ID_ERROR' });
		}
	};
};

export const setItineraryFlights = (payload) => (dispatch) => {
	dispatch({
		type: 'SET_ITINERARY_FLIGHTS',
		payload,
	});
};

export const clearFlightQuery = () => (dispatch) => {
	dispatch({
		type: 'CLEAR_FLIGHT_QUERY',
	});
};

export const setFlightsLoading = () => (dispatch) => {
	dispatch({
		type: 'SET_FLIGHTS_LOADING',
	});
};
