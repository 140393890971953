import React, { useState } from 'react';
import moment from 'moment';
import HotelQuery from './HotelQuery';
import { returnTrueDate } from '../Utilities/returnTrueDate';
import { connect } from 'react-redux';
import { fetchHotels } from '../../redux/Actions/hotelActions';
import { setCurrentForm } from '../../redux/Actions/trip';

const HotelQueryFormDisplay = ({
	fetchHotels,
	setCurrentForm,
	trip,
	more_indicator,
	formRef,
	formPosition,
}) => {
	const { adults, children, infants, hotelDetails } = trip;
	const [currentIndex, setCurrentIndex] = useState(0);

	const handleSubmit = async (e, is_next) => {
		e.preventDefault();

		let hotel = hotelDetails[currentIndex];
		let priceRange = hotel.priceRange;
		let start_date = moment(hotel.startDate.slice(0, 10)).format('YYYY-MM-DD');
		let end_date = moment(hotel.endDate.slice(0, 10)).format('YYYY-MM-DD');

		let destination = hotel.city;
		let radius = hotel.radius;

		if (typeof priceRange === 'string') {
			priceRange = JSON.parse(priceRange);
		}
		const num_adults = parseInt(adults);
		const num_children = parseInt(children);
		const num_infants = parseInt(infants);

		const body = {
			hotel_detail_id: hotel._id,
			start_date,
			end_date,
			city_code: destination?.cityCode.toUpperCase(),
			country_code: destination?.countryCode.toUpperCase(),
			distance: radius?.toString(),
			min_rate: priceRange?.min || '0.00',
			max_rate: priceRange?.max || '0.00',
			currency_code: 'USD',
			guest_count: num_adults + num_children,
			num_adults,
			num_children,
			num_infants,
			room_count: hotel.rooms,
			more_indicator: is_next ? more_indicator : null,
		};

		fetchHotels({ body, hotelDetail: hotel });
		setCurrentForm('');
	};

	const detailProps = [];

	hotelDetails.forEach((hotel, index) => {
		detailProps.push({
			prevEndDate:
				index > 0 ? returnTrueDate(hotelDetails[index - 1].endDate) : null,
			handleSubmit: handleSubmit,
			key: hotel._id,
			hotelDetails: hotelDetails,
			index: index,
			endIndex: hotelDetails.length - 1,
			hotel: hotel,
			adults: adults,
			children: children,
			infants: infants,
			checkinDate: moment(hotel.startDate.slice(0, 10)).format('YYYY-MM-DD'),
			checkoutDate: moment(hotel.endDate.slice(0, 10)).format('YYYY-MM-DD'),
			radius: hotel.radius,
			priceRange: hotel.priceRange,
			rooms: hotel.rooms,
			destination: hotel.city,
			setCurrentIndex: () => setCurrentIndex(currentIndex + 1),
		});
	});

	const returnHotelDetails = () => {
		return currentIndex >= 0 ? (
			<HotelQuery formRef={formRef} {...detailProps[currentIndex]} />
		) : null;
	};
	return (
		<div style={formPosition} className='itinerary-form__container'>
			{returnHotelDetails()}
		</div>
	);
};

const mapStateToProps = (state) => ({
	trip: state.trip,
	more_indicator: state.hotel.more_indicator,
});

export default connect(mapStateToProps, { fetchHotels, setCurrentForm })(
	HotelQueryFormDisplay
);
