import React from 'react';
import ManyTimes from './ManyTimes';
import FormField from '../FormField';
import { connect } from 'react-redux';

const ActivityFormInputs = ({
	formData,
	handleChange,
	timeRange,
	handleRange,
	handleDayOptions,
	handlePrice,
	dayOptionCallback,
	hotelDetails,
}) => {
	let {
		description,
		url,
		imageUrl,
		cityName,
		countryName,
		price,
		groupCapacity,
		timeInterval,
		hotelPickup,
		adminNotes,
		name,
		type,
	} = formData || {};
	console.log('hotelpickup', hotelPickup);
	return (
		<>
			<div className='text-field'>
				<label htmlFor='type'>Activity Type:</label>
				<select
					name='type'
					id='type'
					onChange={handleChange}
					className='dropbox'
					value={type}>
					<option value=''>--Please choose an option--</option>
					<option value='individual-activity'>Individual Activity</option>
					<option value='group-activity'>Group Activity</option>
				</select>
			</div>

			<FormField
				handleChange={handleChange}
				type='text'
				name='name'
				title='Name'
				value={name || ''}
				required={true}
			/>

			<div className='text-field'>
				<label htmlFor='description'>Description:</label>
				<textarea
					required
					value={description}
					type='text'
					name='description'
					onChange={handleChange}
					maxLength='500'></textarea>
			</div>

			<FormField
				handleChange={handleChange}
				type='text'
				name='url'
				title='Website'
				value={url || ''}
				required={true}
			/>

			<FormField
				handleChange={handleChange}
				type='text'
				name='imageUrl'
				title='Photo Url'
				value={imageUrl || ''}
				required={true}
			/>
			<div className='flex-section'>
				<div className='text-field'>
					<label htmlFor='cityName'>City Name:</label>

					<input
						onChange={handleChange}
						name='cityName'
						id='cityName'
						type='text'
						list='locations'
						value={cityName || ''}
						autoComplete='off'
					/>
					<datalist id='locations'>
						{hotelDetails.map((detail, index) => {
							return (
								<option key={`activity-location-${index}`} name='cityName'>
									{detail.city.cityName}
								</option>
							);
						})}
						{/* <option>Volvo</option>
						<option>Saab</option>
						<option>Mercedes</option>
						<option>Audi</option> */}
					</datalist>
				</div>

				{/* <FormField
					handleChange={handleChange}
					type='text'
					name='cityName'
					title='City Name'
					value={cityName || ''}
					required={true}
					cname='capitalize'
				/> */}

				<FormField
					handleChange={handleChange}
					type='text'
					name='countryName'
					title='Country'
					value={countryName || ''}
					required={true}
					cname='capitalize'
				/>
			</div>
			<div className='flex-section'>
				<FormField
					handleChange={handlePrice}
					type='text'
					name='amount'
					title={`Price Per ${type === 'group-activity' ? 'Group' : 'Person'}`}
					value={(price && price.amount) || ''}
					required={true}
				/>

				<FormField
					handleChange={handlePrice}
					type='text'
					name='currencyCode'
					title='Currency Code'
					value={(price && price.currencyCode) || ''}
					required={true}
				/>

				{type === 'group-activity' && (
					<FormField
						handleChange={handleChange}
						type='number'
						name='groupCapacity'
						title='Group Capacity'
						value={groupCapacity || ''}
						required={true}
					/>
				)}
			</div>
			<h4>Time Options:</h4>
			<ManyTimes
				dayOptionCallback={dayOptionCallback}
				handleChange={handleChange}
				handleDayOptions={handleDayOptions}
				handleRange={handleRange}
				formData={formData}
				timeRange={timeRange}
				timeInterval={timeInterval}
			/>
			<label htmlFor=''>
				Hotel Pickup Included?{' '}
				<input
					type='checkbox'
					checked={hotelPickup || false}
					// value={hotelPickup || false}
					name='hotelPickup'
					onChange={handleDayOptions}
				/>
			</label>
			<div className='text-field'>
				<label htmlFor='description'>Admin Notes:</label>
				<textarea
					value={adminNotes}
					type='text'
					name='adminNotes'
					onChange={handleChange}
					maxLength='500'></textarea>
			</div>
		</>
	);
};
const mapStateToProps = (state) => ({
	hotelDetails: state.trip.hotelDetails,
});

export default connect(mapStateToProps, null)(ActivityFormInputs);
