const initState = {
	item: null,
	alerts: [],
	loading: false,
	paymentSuccess: false,
	event: '',
};

const authItemReducer = (state = initState, action) => {
	const { payload, type } = action;
	switch (type) {
		case 'SELECT_AUTH_ITEM':
			return {
				...state,
				item: payload.item,
				event: 'payment',
				loading: false,
			};
		case 'SELECT_EDIT_ITEM':
			return {
				...state,
				item: payload.item,
				event: 'edit',
			};
		case 'SELECT_REVIEW_ITEM':
			return {
				...state,
				item: payload.item,
				event: 'review',
			};

		case 'AUTH_ITEM_LOADING':
			return {
				...state,
				loading: true,
			};
		case 'REMOVE_AUTH_ITEM':
			return {
				...state,
				item: null,
				loading: true,
				event: '',
				alerts: [],
			};
		case 'SET_PAYMENT_SUCCESS':
			return {
				...state,
				paymentSuccess: true,
				alerts: payload,
				loading: false,
			};

		case 'SET_PAID_ACTIVITY':
			return {
				...state,
				item: null,
				loading: true,
			};
		case 'SET_PAID_TRANSPORT':
			return {
				...state,
				item: null,
				loading: true,
			};
		case 'SET_PAID_FLIGHT':
			return {
				...state,
				item: null,
				loading: true,
			};
		default:
			return state;
	}
};

export default authItemReducer;
