import React from 'react';
import trip from '../../images/noun-earth-1792107-142350.svg';

import flights from '../../images/noun-airplane-1028372-142350.svg';
import transport from '../../images/noun-vehicle-839688-142350-cropped.svg';
import amadeusCars from '../../images/noun-car-5226133-142350-cropped.svg';

import lodging from '../../images/noun-hotel-3849427-142350-cropped.svg';

const ListItemType = ({ itemKey }) => {
	const icons = {
		trip: {
			uri: trip,
			style: {
				height: '2rem',
			},
			text: 'Trip',
		},
		flights: {
			uri: flights,
			style: {
				height: '2rem',
			},
			text: 'Flight',
		},
		transport: {
			uri: transport,
			style: {
				height: '1.5rem',
			},
			text: 'Transport',
		},
		lodging: {
			uri: lodging,
			style: {
				height: '2rem',
			},
			text: 'Lodging',
		},
		activities: {
			uri: null,
			style: {
				height: '2rem',
			},
			text: 'Activity',
		},
		amadeusCars: {
			uri: amadeusCars,
			style: {
				height: '1.25rem',
			},
			text: 'Car Rental',
		},
	};
	return (
		<div className='item-list__item-type'>
			<img src={icons[itemKey].uri} style={icons[itemKey].style} />
			<span className='capitalize'>{icons[itemKey].text}</span>
		</div>
	);
};

export default ListItemType;
