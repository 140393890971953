import React from 'react';
import { returnItemFields } from '../../Utilities/helperFunctions';

const LodgingDates = ({ descriptive_info, roomRates }) => {
	const returnCheckinCheckout = () => {
		const { Rate } = roomRates[0]['RoomRate'][0]['Rates'][0];
		const policy =
			descriptive_info &&
			descriptive_info['Policies'] &&
			descriptive_info['Policies'].length &&
			descriptive_info['Policies'][0]['Policy'] &&
			descriptive_info['Policies'][0]['Policy'].length
				? descriptive_info['Policies'][0]['Policy'].find(
						(item) => item['PolicyInfo']
				  )
				: null;
		const { CheckInTime, CheckOutTime } = returnItemFields(policy, [
			'CheckInTime',
			'CheckOutTime',
		]);
		return policy ? (
			<div className='checkin-checkout'>
				<span>
					Check In Time: {Rate[0].info.EffectiveDate} {CheckInTime}
				</span>
				<span>
					Check Out Time:{' '}
					{Rate && Rate.length && Rate[Rate.length - 1]['Total']
						? Rate[Rate.length - 2].info.ExpireDate
						: Rate[Rate.length - 1].info.ExpireDate}{' '}
					{CheckOutTime}
				</span>
			</div>
		) : (
			<div className='checkin-checkout'>
				<span>
					Check In Date: {Rate && Rate.length && Rate[0].info.EffectiveDate}
				</span>
				<span>
					Check Out Date:{' '}
					{Rate && Rate.length && Rate[Rate.length - 1]['Total']
						? Rate[Rate.length - 2].info.ExpireDate
						: Rate[Rate.length - 1].info.ExpireDate}
				</span>
			</div>
		);
	};
	return <div className='reservation-section'>{returnCheckinCheckout()}</div>;
};

export default LodgingDates;
