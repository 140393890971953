import React from 'react';
import moment from 'moment';

import flightImage from '../../../../../images/flight2.svg';
import FlightPrice from '../FlightPrice';
import SegmentTitle from '../SegmentTitle';
import ModalFlightDetails from './ModalFlightDetails';
import { airlines } from '../../../../../Utilities/airlines';

const FlightInfoContainer = ({ flight_details, index, selectedItem }) => {
	const returnNewDate = (date, time) => {
		const newDate = new Date(date);
		if (time) {
			const hours = time.slice(0, 2);
			const min = time.slice(3);
			newDate.setHours(hours);
			newDate.setMinutes(min);
		}
		return newDate;
	};
	return (
		<div className='flight__info'>
			{index === 0 && (
				<FlightPrice
					price={`$${selectedItem.data.recommendation.pricing_info.passenger_pricing[0].total_amount}`}
					// currency={selectedItem.data.price.currency}
				/>
			)}

			<SegmentTitle
				date={moment(flight_details[0].departure.date).format('dddd MMM Do')}
				direction={index === 0 ? 'Departing' : 'Returning'}
				image={
					airlines[flight_details[0].companyId.marketingCarrier]
						? airlines[flight_details[0].companyId.marketingCarrier].image
						: flightImage
				}
			/>
			<ModalFlightDetails
				returnNewDate={returnNewDate}
				flight_details={flight_details}
				selectedItem={selectedItem}
			/>
		</div>
	);
};

export default FlightInfoContainer;
