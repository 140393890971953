import React from 'react';

const DashboardButton = ({
	title,
	newItemAmount,
	handleClick,
	clickArguement,
}) => {
	return (
		<>
			<button
				onClick={() => handleClick(clickArguement)}
				className='dashboard-button'>
				<h3>
					{title} {newItemAmount ? <span>{newItemAmount}</span> : null}
				</h3>
			</button>
		</>
	);
};

export default DashboardButton;
