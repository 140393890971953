import React, { useState } from 'react';
import { connect } from 'react-redux';
import { addItineraryHotelUrl } from '../../redux/Actions/trip';
import { addHotelUrl } from '../../redux/Actions/hotelActions';

const AddHotelUrl = ({ hotelCode, addItineraryHotelUrl, addHotelUrl }) => {
	const [inputOpen, setInputOpen] = useState(false);
	const [value, setValue] = useState('');

	const addUrl = () => {
		addItineraryHotelUrl(hotelCode, value);
		addHotelUrl(hotelCode, value);
		setInputOpen(false);
	};

	return inputOpen ? (
		<div className='hotel-url__input-container'>
			<input
				className='hotel-url__input'
				value={value}
				onChange={(e) => setValue(e.target.value)}
				placeholder='Add url'
			/>{' '}
			<div className='action__container'>
				<button onClick={addUrl}>Submit</button>
				<button onClick={() => setInputOpen(false)}>Cancel</button>
			</div>
		</div>
	) : (
		<>
			{value ? (
				<a href={'https://' + value}>website</a>
			) : (
				<button onClick={() => setInputOpen(true)}>Add url</button>
			)}
		</>
	);
};
export default connect(null, { addItineraryHotelUrl, addHotelUrl })(
	AddHotelUrl
);
