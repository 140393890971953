import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
	addItineraryItem,
	editItineraryItem,
} from '../../../redux/Actions/trip';
import ActivityFormInputs from './ActivityFormInputs';
import {
	handleTripEndDate,
	returnTimeTable,
} from '../../../Utilities/helperFunctions';
import { removeAuthItem } from '../../../redux/Actions/authItem';
import CloseSection from '../../Queries/CloseSection';
import { setCustomForm } from '../../../redux/Actions/customItemForm';
import { setCurrentForm } from '../../../redux/Actions/trip';

const ActivityForm = ({
	addItineraryItem,
	trip,
	index,
	editItineraryItem,
	activity,
	setEdit,
	updateActivity,
	removeAuthItem,
	formRef,
	formPosition,
	setCurrentForm,
	customItemForm,
	setCustomForm,
}) => {
	const { formData: customFormData } = customItemForm;
	const emptyTimeRange = {
		startTime: activity ? activity.startTime : '',
		endTime: activity ? activity.endTime : '',
	};

	const [timeRange, setTimeRange] = useState(emptyTimeRange);
	const [hotelMap, setHotelMap] = useState(null);

	const checkObjectFields = (obj) => {
		for (let key in obj) {
			if (!obj[key]) {
				return false;
			}
		}
		return true;
	};

	const handleAddActivity = (e) => {
		e.preventDefault();
		let {
			type,
			name,
			description,
			url,
			imageUrl,
			cityName,
			countryName,
			price,
			timeLength,
			dateRange,
			startTimeRange,
			endTimeRange,
			timeInterval,
		} = customFormData || {};

		const requiredInputs = !customFormData.singleTime
			? {
					type,
					name,
					description,
					url,
					imageUrl,
					cityName,
					countryName,
					timeLength,
					startTimeRange,
					endTimeRange,
			  }
			: {
					type,
					name,
					description,
					url,
					imageUrl,
					cityName,
					countryName,
			  };
		let validSingleInputs = checkObjectFields(requiredInputs);
		let validPrice = checkObjectFields(price);
		let validDateRange = !customFormData.singleTime
			? checkObjectFields(dateRange)
			: dateRange.start;

		if (customFormData.singleTime) {
			// all items found here are initialized in form template, should not throw errors
			const itemDate = customFormData.dateRange.start;
			const { start, end } = customFormData;
			const startHour = start && parseInt(start.slice(0, 2));
			const startMin = start && parseInt(start.slice(3));
			const endHour = end && parseInt(end.slice(0, 2));
			const endMin = end && parseInt(end.slice(3));
			const monthIndex = itemDate && parseInt(itemDate.slice(5, 7)) - 1;
			const year = itemDate && parseInt(itemDate.slice(0, 4));
			const day = itemDate && parseInt(itemDate.slice(8, 10));
			const startTime = new Date(year, monthIndex, day, startHour, startMin);
			const endTime = new Date(year, monthIndex, day, endHour, endMin);
			customFormData.start = startTime;
			customFormData.end = endTime;
		}

		if (!customFormData.singleTime) {
			customFormData.start = '';
			customFormData.end = '';
		}

		if (validSingleInputs && validPrice && validDateRange && timeInterval) {
			customFormData.cityName = customFormData.cityName.trim().toLowerCase();
			customFormData.countryName = customFormData.countryName
				.trim()
				.toLowerCase();
			const timeOptions = returnTimeTable(
				timeInterval,
				startTimeRange,
				endTimeRange
			);
			timeLength = parseFloat(timeLength);
			let cleanedIndex =
				customFormData.itineraryIndex >= 0 || customFormData.index >= 0
					? customFormData.itineraryIndex || customFormData.index
					: index;
			if (cleanedIndex >= 0) {
				updateActivity
					? updateActivity({
							...customFormData,
							timeLength,
							timeOptions,
							trip: trip._id,
					  })
					: editItineraryItem(
							'activities',
							{
								...customFormData,
								timeLength,
								timeOptions,
								trip: trip._id,
							},
							cleanedIndex
					  );

				removeAuthItem();
				setEdit && setEdit();
			} else {
				addItineraryItem('activities', {
					...customFormData,
					timeLength,
					timeOptions,
					trip: trip._id,
				});
				setCustomForm('', null);
				setTimeRange({
					startTime: '',
					endTime: '',
				});
			}
			setCurrentForm('');
		}
	};

	const handleNested = (key1, key2, value) => {
		setCustomForm('activity', {
			...customFormData,
			[key1]: {
				...customFormData[key1],
				[key2]: value,
			},
		});
	};

	const handleRange = (e) => {
		e.preventDefault();
		let valid = true;
		const { name, value } = e.target;

		if (
			new Date(value) < new Date(trip.flightDetails[0].departureDate) ||
			new Date(value) > handleTripEndDate(trip)
		) {
			valid = false;
			handleNested('dateRange', name, '');
		}

		if (valid) {
			handleNested('dateRange', name, value);
		}
	};

	const dayOptionCallback = useCallback((e) => {
		const { name, checked } = e.target;
		return name === 'hotelPickup'
			? setCustomForm('activity', {
					...customFormData,
					hotelPickup: checked,
			  })
			: handleNested('dayOptions', name, checked);
	});

	const handleDayOptions = (e) => {
		const { name, checked } = e.target;
		return name === 'hotelPickup'
			? setCustomForm('activity', {
					...customFormData,
					hotelPickup: checked,
			  })
			: handleNested('dayOptions', name, checked);
	};
	const handleChange = async (e) => {
		// e.preventDefault();
		let { value, name } = e.target;
		console.log('e.target.value', e.target.value);
		console.log('hotelMap', hotelMap);

		if (name === 'cityName' && hotelMap[value.toLowerCase()]) {
			const mapValue = hotelMap[value.toLowerCase()];
			setCustomForm('activity', {
				...customFormData,
				cityName: mapValue.city.cityName.toLowerCase(),
				countryName: mapValue.city.countryCode,
				dateRange: {
					start: mapValue.startDate.slice(0, 10),
					end: mapValue.endDate.slice(0, 10),
				},
			});
		} else {
			setCustomForm('activity', {
				...customFormData,
				[name]: value,
			});
		}
	};

	const handlePrice = (e) => {
		e.preventDefault();
		setCustomForm('activity', {
			...customFormData,
			price: {
				...customFormData.price,
				[e.target.name]: e.target.value,
			},
		});
	};

	useEffect(() => {
		if (trip && trip.hotelDetails) {
			const map = {};
			trip.hotelDetails.forEach((detail) => {
				map[detail.city.cityName.toLowerCase()] = detail;
			});
			setHotelMap(map);
		}
		if (!customFormData) {
			setCustomForm('activity');
		}
	}, []);

	return (
		<div
			style={formPosition}
			className='itinerary-form__container'
			ref={formRef}>
			<CloseSection />
			<form className='white activity-form'>
				<ActivityFormInputs
					dayOptionCallback={dayOptionCallback}
					formData={customFormData}
					handleChange={handleChange}
					timeRange={timeRange}
					handleRange={handleRange}
					handleDayOptions={handleDayOptions}
					handlePrice={handlePrice}
				/>

				<button
					className='btn cta-button lighten-2 z-depth-2'
					onClick={handleAddActivity}>
					Generate Card
				</button>
			</form>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		activities: state.admin.activity,
		trip: state.trip,
		customItemForm: state.customItemForm,
	};
};

export default connect(mapStateToProps, {
	addItineraryItem,
	editItineraryItem,
	removeAuthItem,
	setCurrentForm,
	setCustomForm,
})(ActivityForm);
