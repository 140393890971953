import React, { useState } from 'react';
import { newReturnCitySearch } from '../../Utilities/Utils';
import { connect } from 'react-redux';
import { post } from '../../Utilities/api';
import { setCurrentForm } from '../../redux/Actions/trip';
import LoadingOverlay from '../Layout/LoadingOverlay';

const DestinationForm = ({ setCurrentForm, customItemForm, updates }) => {
	const { formData } = customItemForm || {};
	const [destinationInfo, setDestinationInfo] = useState(
		formData
			? formData
			: {
					location: null,
					relativeLocation: 'Domestic',
					geographicalType: [],
					activityTypes: [],
					groupType: 'Family Friendly',
			  }
	);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [locationInput, setLocationInput] = useState(
		formData
			? `${formData.location.cityName}, ${
					formData.location.stateCode !== 'null'
						? formData.location.stateCode + ', '
						: ''
			  }${formData.location.countryName}`
			: ''
	);
	const [searchResults, setSearchResults] = useState([]);

	const dataOptions = [
		{
			dataName: 'relativeLocation',
			label: 'Location',
			description: '',
			options: ['Domestic', 'International'],
		},
		{
			dataName: 'groupType',
			label: 'Group Type:',
			description: '',
			options: ['Family Friendly', 'Adults Only'],
		},
		{
			dataName: 'geographicalType',
			label: 'Geographical Type (select all that apply):',
			description: '',
			options: ['Beach', 'City', 'Mountains', 'Countryside/Wineries'],
		},
		{
			dataName: 'activityTypes',
			label: 'Activities (select all that apply):',
			description: '',
			options: [
				'Spa',
				'Skiing/Snowsports',
				'Shopping',
				'Nature',
				'History',
				'Art',
			],
		},
	];

	const handleSelected = (selected, currentValue) => {
		if (Array.isArray(selected) && selected.includes(currentValue)) {
			return true;
		}
		if (selected === currentValue) {
			return true;
		}
		return false;
	};

	const handleSelectClick = (currentField, currentValue) => {
		const selected = destinationInfo[currentField];
		if (Array.isArray(selected)) {
			if (!selected.includes(currentValue)) {
				setDestinationInfo({
					...destinationInfo,
					[currentField]: [...selected, currentValue],
				});
			} else {
				setDestinationInfo({
					...destinationInfo,
					[currentField]: selected.filter((value) => currentValue !== value),
				});
			}
		} else {
			setDestinationInfo({
				...destinationInfo,
				[currentField]: currentValue,
			});
		}
	};

	const handleSelectCity = (location) => {
		setDestinationInfo({
			...destinationInfo,
			location,
		});
		setLocationInput(
			`${location.cityName}, ${location.stateCode}, ${location.countryName}`
		);
		setSearchResults([]);
	};

	const returnSearchResults = () => {
		return searchResults && searchResults.length ? (
			<div className='autofill__container'>
				<div className='autofill-section'>
					{searchResults.map((result, resultIndex) => {
						return (
							<div
								key={`destination-form-search-result-${resultIndex}`}
								className='autofill-option'
								onClick={() => handleSelectCity(result)}>
								<p className='autofill-airport-name'>
									{result['cityName']}, {result['stateCode']}{' '}
									{result['countryName']}
								</p>
							</div>
						);
					})}
				</div>
			</div>
		) : null;
	};
	// TODO
	// Set Edit Destination Route
	const handleEditDestination = async (e) => {};

	const handleAddDestination = async (e) => {
		e.preventDefault();
		setLoading(true);
		setSubmitted(true);
		const { location, geographicalType, activityTypes, _id } = destinationInfo;
		if (location && geographicalType.length && activityTypes.length) {
			let res;
			if (_id) {
				res = await post(
					'/api/v1/data/private/suggested-destination/update',
					destinationInfo,
					true
				);
			} else {
				res = await post(
					'/api/v1/data/private/suggested-destination/add',
					destinationInfo,
					true
				);
			}

			if (res.status === 200) {
				setSuccess(true);
				setTimeout(() => {
					setCurrentForm(null);
					updates && updates();
				}, 5000);
			}
			setLoading(false);
			setTimeout(() => {
				setSubmitted(false);
			}, 5000);
			setDestinationInfo({
				location: null,
				relativeLocation: 'Domestic',
				geographicalType: [],
				activityTypes: [],
				groupType: 'Family Friendly',
				results: [],
			});
		}
	};

	const handleCitySearch = (e) => {
		const { value } = e.target;
		setLocationInput(value);
		if (value.length >= 3) {
			const results = newReturnCitySearch(value);
			console.log('results', results);
			setSearchResults(results);
		} else {
			setSearchResults([]);
		}
	};

	return (
		<div className='itinerary-form__container'>
			{' '}
			{submitted ? (
				<LoadingOverlay loading={loading} success={success} />
			) : null}
			<form className='destination__form'>
				<button
					className='modal-close'
					onClick={(e) => {
						e.preventDefault();
						setCurrentForm(null);
					}}>
					<i className='fa fa-times'></i>
				</button>
				<div className='flex-section'>
					<div className='text-field' style={{ width: '100%' }}>
						<label
							className='red-x'
							style={{ cursor: 'default' }}
							type='text'
							id='destinationLocationCode'>
							Location
						</label>
						<div>
							<input
								required
								autocomplete='off'
								value={locationInput}
								type='text'
								id='location'
								name='location'
								className='capitalize'
								onChange={handleCitySearch}
							/>
							{returnSearchResults()}
						</div>
					</div>
				</div>
				{/* <FormField
					required={true}
					name='location'
					type='text'
					value={null}
					handleChange={null}
					title='Location'
				/> */}
				{dataOptions?.map((option) => {
					return (
						<div className='option__container'>
							<h4>{option.label}</h4>
							{option.options?.map((optionValue) => {
								const isSelected = handleSelected(
									destinationInfo[option.dataName],
									optionValue
								);
								return (
									<button
										className={isSelected ? 'selected' : ''}
										onClick={(e) => {
											e.preventDefault();
											handleSelectClick(option.dataName, optionValue);
										}}>
										{optionValue}
									</button>
								);
							})}
						</div>
					);
				})}
				<div className='action__container'>
					<button onClick={handleAddDestination} className='submit'>
						Save
					</button>
				</div>
			</form>
		</div>
	);
};

const mapStateToProps = (state) => ({
	customItemForm: state.customItemForm,
});

export default connect(mapStateToProps, { setCurrentForm })(DestinationForm);
