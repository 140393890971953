import React from 'react';
import { useState } from 'react';
import { FiMoreVertical } from 'react-icons/fi';
import { IconContext } from 'react-icons';
import { useRef } from 'react';
import { useEffect } from 'react';

const ListItemOptions = ({ remove, edit, contact, show }) => {
	const [isVisible, setIsVisible] = useState(false);
	const listRef = useRef(null);

	useEffect(() => {
		if (isVisible) {
			listRef.current.focus();
		}
	}, [isVisible]);

	return (
		<div className='item-list__item-options--container'>
			<button
				onMouseEnter={(e) => {
					setIsVisible(true);
				}}
				className='item-list__item-options--button'>
				<IconContext.Provider value={{ size: '1.75em' }}>
					<FiMoreVertical />
				</IconContext.Provider>
			</button>
			{isVisible ? (
				<ul
					ref={listRef}
					tabIndex={0}
					onMouseLeave={() => setIsVisible(false)}
					className='item-list__item-options--list'>
					{remove}
					{edit}
					{show}
					{contact && (
						<li className='item-list__item-options--button'>Contact</li>
					)}
				</ul>
			) : null}
		</div>
	);
};

export default ListItemOptions;
