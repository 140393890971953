import React from 'react';
import { connect } from 'react-redux';
import { setCurrentForm } from '../../redux/Actions/trip';
import FormModal from '../Modal/FormModal';

const Destinations = ({ setCurrentForm }) => {
	return (
		<div className='dashboard'>
			<button onClick={() => setCurrentForm('destination')}>
				Add Destination
			</button>
			<FormModal xAxis={0} />
		</div>
	);
};

export default connect(null, { setCurrentForm })(Destinations);
