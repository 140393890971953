import React, { useState } from 'react';
import { connect } from 'react-redux';
import uuid from 'react-uuid';
import SideNavItemGroup from './SideNavItemGroup';
import carIcon from '../../images/noun-car-5226133-142350.svg';

const SideNavItemList = ({
	flights,
	transport,
	lodging,
	activities,
	amadeusCars,
	photoUrl,
	trip_id,
}) => {
	console.log('photoUrl', photoUrl);
	const [openItems, setOpenItems] = useState({
		flight: true,
		hotel: true,
		carRental: true,
		activity: true,
		transportation: true,
		photoUrl: true,
	});
	const itemGroups = [
		{
			title: 'Flights',
			items: flights,
			formName: 'flight',
		},
		{
			title: 'Hotel',
			items: lodging,
			formName: 'hotel',
		},
		{
			title: 'Car Rentals',
			items: amadeusCars,
			formName: 'carRental',
			noImage: carIcon,
		},
		{
			title: 'Activities',
			items: activities,
			formName: 'activity',
		},
		{
			title: 'Transportation',
			items: transport,
			formName: 'transportation',
		},
		{
			title: 'Trip Image',
			items: [photoUrl],
			formName: 'photoUrl',
		},
	];

	return (
		<section className='item-group__list'>
			{itemGroups.map((itemGroup) => {
				return (
					<SideNavItemGroup
						key={uuid()}
						openItems={openItems}
						setOpenItems={setOpenItems}
						trip_id={trip_id}
						{...itemGroup}
					/>
				);
			})}
		</section>
	);
};

const mapStateToProps = (state) => ({
	flights: state.trip.itineraryItems.flights,
	transport: state.trip.itineraryItems.transport,
	lodging: state.trip.itineraryItems.lodging,
	activities: state.trip.itineraryItems.activities,
	amadeusCars: state.trip.itineraryItems.amadeusCars,
	trip_id: state.trip._id,
	photoUrl: state.trip.photoUrl,
});

export default connect(mapStateToProps, null)(SideNavItemList);
