const initState = {
	cars: [],

	loading: false,
};

const carReducer = (state = initState, action) => {
	switch (action.type) {
		case 'SET_CARS':
			return {
				...state,
				cars: action.payload,
				loading: false,
			};

		case 'SET_CARS_LOADING':
			return {
				...state,
				loading: true,
			};

		default:
			return state;
	}
};

export default carReducer;
