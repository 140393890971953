import moment from 'moment';
import React from 'react';
import CustomItemReviewField from '../CustomData/CustomItemReviewField';

const ListItemCustom = ({
	url,
	name,
	isTransport,
	startLocation,
	endLocation,
	imageUrl,
	cityName,
	dateRange,
}) => {
	return (
		<div className='item-list__custom'>
			<img src={imageUrl} alt='' />
			<div className='item-list__custom-details'>
				<h3>{name}</h3>
				<a href={url}> {url}</a>
				<div className='date-range'>
					<label>Date Range</label>
					<p>
						{`${moment(dateRange.start).format('LL')} to ${moment(
							dateRange.end
						).format('LL')}`}
					</p>
				</div>
				{isTransport ? (
					<>
						<CustomItemReviewField
							title='Start Location'
							value={startLocation}
						/>
						<CustomItemReviewField title='End Location' value={endLocation} />
					</>
				) : (
					<CustomItemReviewField title='Location' value={cityName} />
				)}
			</div>
		</div>
	);
};

export default ListItemCustom;
