const initState = {
	autofill: [],
};

const autofillReducer = (state = initState, action) => {
	switch (action.type) {
		case 'AUTOFILL_OPTIONS_SUCCESS':
			return { autofill: action.payload };

		case 'AUTOFILL_OPTIONS_ERROR':
			return {
				...state,
			};

		case 'AUTOFILL_EMPTY':
			return {
				autofill: null,
				...state,
			};
		default:
			return state;
	}
};

export default autofillReducer;
