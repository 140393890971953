import React from 'react';
import { connect } from 'react-redux';

const UserPreference = ({ name, value }) => {
	return (
		<div className='user-preference'>
			<span className='preference-name'>
				<b>{name}:</b>
			</span>{' '}
			<span className='preference-value'>{value}</span>
		</div>
	);
};

const mapStateToProps = (state) => ({
	selectedUser: state.admin.selectedUser,
});

export default connect(mapStateToProps, null)(UserPreference);
