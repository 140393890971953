import React from 'react';
import editIcon from '../../images/noun-edit-2162449-17234d.svg';
import uploadIcon from '../../images/noun-upload-document-1316856-142350.svg';
import { useState } from 'react';
import { updateAuthorization } from '../../redux/Actions/authItem';
import { connect } from 'react-redux';
import { checkType } from '../../Utilities/helperFunctions';
import AttachmentPopup from '../popups/AttachmentPopup';

const ReviewActions = ({
	item,
	authorized,
	updateAuthorization,
	close,
	uploadDocument,
	tripId,
}) => {
	const [hovered, setHovered] = useState(false);
	const { key } = checkType(item ? item.type || item.data.type : null);

	return (
		<div className='review-actions' onMouseEnter={() => setHovered(true)}>
			{uploadDocument ? (
				<AttachmentPopup
					trip_id={tripId}
					buttonText={<img src={uploadIcon} />}
				/>
			) : null}
			<button>
				<img src={editIcon} alt='' />
			</button>
			{hovered && (
				<>
					<ul>
						<li>
							<button
								onClick={() => {
									updateAuthorization(item, authorized._id, key);
									close();
								}}>
								Cancel Authorization
							</button>
						</li>
					</ul>
				</>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	authorized: state.trip.authorized,
	item: state.authItem.item,
});

export default connect(mapStateToProps, { updateAuthorization })(ReviewActions);
