import React, { useState } from 'react';
import { connect } from 'react-redux';
import { addTripUrl, setCurrentForm } from '../../redux/Actions/trip';
import CloseSection from '../Queries/CloseSection';

const TripImageInput = ({ addTripUrl, setCurrentForm }) => {
	const [tripPhoto, setTripPhoto] = useState('');

	const handleClick = () => {
		addTripUrl(tripPhoto);
		setCurrentForm('');
	};

	const handleChange = (e) => {
		e.preventDefault();
		setTripPhoto(e.target.value);
	};
	return (
		<div className='itinerary-form__container'>
			<CloseSection />
			<div className='image-url__container'>
				<div className='input__container'>
					<label htmlFor='imageUrl'>Image Url:</label>
					<input id='imageUrl' type='text' onChange={handleChange} />{' '}
				</div>
				<button
					className='btn cta-button lighten-2 z-depth-2'
					onClick={handleClick}>
					Add
				</button>
			</div>
		</div>
	);
};

export default connect(null, { addTripUrl, setCurrentForm })(TripImageInput);
