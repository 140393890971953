import moment from 'moment';
import React from 'react';
import UserIdCopy from '../listItems/UserIdCopy';
import { returnTrueDate } from '../Utilities/returnTrueDate';

const ConfirmationForm = ({
	formData,
	handleInput,
	flight,
	user,
	secondaryTravelers,
}) => {
	return user ? (
		<>
			<form className='white auth transport-form confirmation-form'>
				<div className='travelers'>
					<h3>Traveler Information</h3>
					<ul>
						<li className='capitalize'>
							<b>Full Name:</b>
							{user.title ? `${user.title} ` : ''}
							{user.firstName}
							{user.middleNameOrInitial
								? ` ${user.middleNameOrInitial}`
								: ''}{' '}
							{user.lastName}
							{user.suffix ? ` ${user.suffix}` : ''}
						</li>
						<li className='capitalize'>
							<b>Date of Birth:</b>
							{moment(returnTrueDate(user.dob?.toString().slice(0, 10))).format(
								'L'
							)}
						</li>
					</ul>
					{secondaryTravelers?.map((traveler) => {
						return (
							<ul>
								<li className='capitalize'>
									<b>Full Name:</b>
									{traveler.title ? `${traveler.title} ` : ''}
									{traveler.firstName}
									{traveler.middleName ? ` ${traveler.middleName}` : ''}{' '}
									{traveler.lastName}
									{traveler.suffix ? ` ${traveler.suffix}` : ''}
								</li>
								<li className='capitalize'>
									<b>Date of Birth:</b>
									{moment(
										returnTrueDate(traveler.dob?.toString().slice(0, 10))
									).format('L')}
								</li>
							</ul>
						);
					})}
				</div>
				<h5>User: {`${user.firstName} ${user.lastName}`}</h5>
				<UserIdCopy userId={user._id} />
				<div className='text-field'>
					<label htmlFor='confirmation'>Vendor:</label>
					<input
						required
						type='text'
						name='vendor'
						value={formData.vendor}
						onChange={handleInput}
					/>
				</div>
				<div className='text-field'>
					<label htmlFor='confirmation'>Confirmation Number :</label>
					<input
						required
						type='text'
						name='controlNumber'
						value={formData.controlNumber}
						onChange={handleInput}
					/>
				</div>
				{flight ? (
					<div className='text-field'>
						<label htmlFor='pnr'>PNR:</label>
						<input
							required
							type='text'
							name='pnr'
							value={formData.pnr}
							onChange={handleInput}
						/>
					</div>
				) : null}

				<div className='text-field'>
					<label htmlFor='confirmation'>Amount Confirmed:</label>
					<input
						required
						type='text'
						name='amount'
						value={formData.amount}
						onChange={handleInput}
					/>
				</div>
				<div className='text-field'>
					<label htmlFor='confirmation'>Admin Notes (optional):</label>
					<textarea
						type='text'
						name='adminNotes'
						value={formData.adminNotes}
						onChange={handleInput}
					/>
				</div>
			</form>
		</>
	) : null;
};

export default ConfirmationForm;
