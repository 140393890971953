import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FlightInfoContainer from './Flights/FlightInfoContainer';

const FlightInfo = ({ selectedItem, itineraryItem, index }) => {
	const { flight_details } = itineraryItem;

	return (
		<FlightInfoContainer
			flight_details={flight_details}
			index={index}
			selectedItem={selectedItem}
		/>
	);
};

FlightInfo.propTypes = {
	selectedItem: PropTypes.object,
};

const mapStateToProps = (state) => ({
	selectedItem: state.trip.selectedItem,
});

export default connect(mapStateToProps, null)(FlightInfo);
