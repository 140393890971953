const initState = {
	pathname: '',
};

const redirectReducer = (state = initState, action) => {
	const { payload, type } = action;
	switch (type) {
		case 'SET_REDIRECT':
			return {
				...state,
				pathname: payload,
			};
		case 'REMOVE_REDIRECT':
			return {
				...state,
				pathname: '',
			};
		default:
			return state;
	}
};

export default redirectReducer;
