import React from 'react';
import { connect } from 'react-redux';
import { setCustomForm } from '../../redux/Actions/customItemForm';
import { setCurrentForm } from '../../redux/Actions/trip';

const CloseSection = ({ setCurrentForm, setCustomForm }) => {
	return (
		<div className='button__section'>
			<button
				onClick={() => {
					setCustomForm('', null);
					setCurrentForm('');
				}}
				className='button__close'>
				+
			</button>
		</div>
	);
};

export default connect(null, { setCurrentForm, setCustomForm })(CloseSection);
