const initState = {
	flights: [],
	lodging: [],
	activity: [],
	transport: [],
	authorizedItems: {
		flights: [],
		transport: [],
		activities: [],
		lodging: [],
	},

	pendingBookings: [],
	pendingCurations: [],
	trips: [],
	users: [],
	userItnerary: {},
	selectedTrip: null,
	selectedUser: null,
	selectedItem: null,
	newUserData: {},
	loading: true,
};

const adminReducer = (state = initState, action) => {
	const { payload, type } = action;
	switch (type) {
		case 'ADD_ITEM_SUCCESS':
			return {
				...state,
				selectedTrip: {
					...state.selectedTrip,
					itineraryItems: {
						[payload.key]: [
							...state.selectedTrip.itineraryItems[payload.key],
							payload.item,
						],
					},
				},
			};
		case 'ADD_USER_ATTACHMENT':
			return {
				...state,
				selectedTrip: {
					...state.selectedTrip,
					attachments: [...state.selectedTrip.attachments, payload],
				},
			};
		case 'SET_AUTHORIZED_ITEMS':
			return {
				...state,
				authorizedItems: payload.authItems,
				pendingBookings: payload.pendingBookings,
				pendingCurations: payload.pendingCurations,
			};
		case 'REMOVE_TRIP_AUTHORIZATION':
			return {
				...state,
				itineraryItems: {
					...state.itineraryItems,
					[payload.key]: state.itineraryItems[payload.key].map((item) =>
						item._id === payload.item._id ? payload.item : item
					),
				},
				authorized: {
					...state.authorized,
					...payload.authorized,
				},
			};
		case 'FILTER_AUTH_ITEM':
			return {
				...state,
				authorizedItems: {
					...state.authorizedItems,
					[payload.key]: state.authorizedItems[payload.key].filter(
						(item) => item._id !== payload.id
					),
				},
			};
		case 'SET_LOADING':
			return {
				...state,
				loading: payload,
			};
		case 'SET_SELECTED_TRIP':
			return {
				...state,
				selectedTrip: payload,
				loading: false,
			};
		case 'ADD_ITINERARY_NESTED':
			return {
				...state,
				selectedTrip: {
					...state.selectedTrip,

					[payload.key]: {
						preferences: state.selectedTrip[payload.key].preferences,
						itineraryItems: state.selectedTrip[payload.key].itineraryItems.map(
							(item) =>
								item.id === payload.id
									? { ...item, selected: true, inCart: true }
									: item
						),
					},
				},
			};
		case 'CLEAR_REVIEW_DATA':
			return {
				...state,
				selectedTrip: {
					...state.selectedTrip,
					flights: {
						...state.selectedTrip.flights,
						itineraryItems: state.selectedTrip.flights.itineraryItems.map(
							(item) => {
								return {
									...item,
									selected: false,
									inCart: false,
								};
							}
						),
					},
					transport: state.selectedTrip.transport.map((item) => {
						return { ...item, selected: false, inCart: false };
					}),
					lodging: state.selectedTrip.lodging.map((item) => {
						return { ...item, selected: false, inCart: false };
					}),
					activities: {
						...state.selectedTrip.activities,
						itineraryItems: state.selectedTrip.activities.itineraryItems.map(
							(item) => {
								return {
									...item,
									selected: false,
									inCart: false,
								};
							}
						),
					},
				},
			};
		case 'REMOVE_TRIP_SUCCESS':
			return {
				...state,
				selectedTrip: null,
			};
		case 'SET_START_END_NESTED':
			return {
				...state,
				selectedTrip: {
					...state.selectedTrip,
					[payload.key]: {
						preferences: state.selectedTrip[payload.key].preferences,
						itineraryItems: state.selectedTrip[payload.key].itineraryItems.map(
							(item) => {
								if (item.id === payload.id) {
									return {
										...item,
										start: payload.start,
										end: payload.end,
									};
								}
								return item;
							}
						),
					},
				},
			};
		case 'SET_START_END_UNNESTED':
			return {
				...state,
				selectedTrip: {
					...state.selectedTrip,
					[payload.key]: state.selectedTrip[payload.key].map((item) => {
						if (item.id === payload.id) {
							return {
								...item,
								start: payload.start,
								end: payload.end,
							};
						}
						return item;
					}),
				},
			};
		case 'ADD_ITINERARY_UNNESTED':
			return {
				...state,
				selectedTrip: {
					...state.selectedTrip,

					[payload.key]: state.selectedTrip[payload.key].map((item) =>
						item.id === payload.id
							? { ...item, selected: true, inCart: true }
							: item
					),
				},
			};
		case 'FILTER_PURCHASE':
			return {
				...state,
				[payload.key]: state[payload.key].filter((item) => {
					return item._id !== payload.savedPurchase._id;
				}),
			};
		case 'REMOVE_ITINERARY_NESTED':
			return {
				...state,
				selectedTrip: {
					...state.selectedTrip,

					[payload.key]: {
						preferences: state.selectedTrip[payload.key].preferences,
						itineraryItems: state.selectedTrip[
							payload.key
						].itineraryItems.filter((item) => item.id !== payload.id),
					},
				},
			};
		case 'REMOVE_ITINERARY_UNNESTED':
			return {
				...state,
				selectedTrip: {
					...state.selectedTrip,

					[payload.key]: state.selectedTrip[payload.key].filter(
						(item) => item.id !== payload.id
					),
				},
			};
		case 'SELECT_NESTED_ITEM':
			return {
				...state,
				selectedItem: state.selectedTrip[payload.key].itineraryItems.find(
					(item) => item.id === payload.id
				),
			};
		case 'SELECT_UNNESTED_ITEM':
			return {
				...state,
				selectedItem: state.selectedTrip[payload.key].find(
					(item) => item.id === payload.id
				),
			};
		case 'REMOVE_MODAL_SELECTED':
			return {
				...state,
				selectedItem: null,
			};

		case 'ADD_FLIGHT_ERROR':
			return { ...state };
		case 'SELECT_USER':
			return {
				...state,
				selectedUser: payload.user,
				loading: payload.loading,
			};
		case 'REMOVE_USER':
			return {
				...state,
				selectedUser: null,
			};
		case 'ADD_HOTEL_SUCCESS':
			return {
				...state,
				selectedTrip: {
					...state.selectedTrip,
					lodging: [...state.selectedTrip.lodging, payload],
				},
			};
		case 'ADD_HOTEL_ERROR':
			return { ...state };
		case 'UPDATE_LODGING_MEDIA':
			return {
				...state,
				selectedTrip: {
					...state.selectedTrip,
					lodging: [
						...state.selectedTrip.lodging.map((item) => {
							if (item.id === payload.id) {
								return {
									...item,
									url: payload.url,
									image: payload.image,
								};
							} else {
								return item;
							}
						}),
					],
				},
			};
		case 'ADD_ACTIVITY_SUCCESS':
			return {
				...state,
				selectedTrip: {
					...state.selectedTrip,
					activities: {
						preferences: state.selectedTrip.activities.preferences,
						itineraryItems: [
							...state.selectedTrip.activities.itineraryItems,
							payload,
						],
					},
				},
			};
		case 'SELECT_TRIP_SUCCESS':
			return {
				...state,
				selectedTrip: payload,
			};
		case 'ADD_ACTIVITY_ERROR':
			return { ...state };

		case 'ADD_TRANSPORT_SUCCESS':
			return {
				...state,
				selectedTrip: {
					...state.selectedTrip,

					transport: [...state.selectedTrip.transport, payload],
				},
			};
		case 'ADD_TRANSPORT_ERROR':
			return { ...state };

		case 'USERS_QUERY_SUCCESS':
			return { ...state, users: payload };
		case 'USERS_QUERY_ERROR':
			return { ...state };

		case 'TRIPS_QUERY_SUCCESS':
			return { ...state, trips: payload };
		case 'TRIPS_QUERY_ERROR':
			return { ...state };

		case 'UPLOAD_USER_DATA_SUCCESS':
			let selectedUser = JSON.parse(payload);
			return { ...state, selectedUser: selectedUser };
		case 'UPLOAD_USER_DATA_ERROR':
			return { ...state };

		case 'VIEW_ITINERARY_SUCCESS':
			let userItnerary = JSON.parse(payload);
			return { ...state, userItnerary: userItnerary };
		case 'UPLOAD_TRIP_DATA_SUCCESS':
			let selectedTrip;
			try {
				selectedTrip = JSON.parse(payload);
			} catch {
				selectedTrip = payload;
			}

			let data;
			try {
				data = JSON.parse(selectedTrip.data);
			} catch {
				data = selectedTrip.data;
			}
			let flights = data.flights;
			let lodging = data.lodging;
			let activities = data.activities;
			let transport = data.transport;

			return {
				...state,
				selectedTrip,
				flights,
				lodging,
				activities,
				transport: transport,
			};
		case 'UPLOAD_TRIP_DATA_ERROR':
			return { ...state };

		case 'PUSH_ADMIN_SELECTIONS':
			let newUserData = state.selectedTrip;
			newUserData.data.flight = state.flights;
			newUserData.data.hotel = state.hotels;
			newUserData.data.activities = state.activity;
			newUserData.data.transport = state.transport;
			return { ...state, newUserData };
		case 'PUSH_ADMIN_SELECTIONS_ERROR':
			return { ...state };

		case 'OVERWRITE_TRIP_SUCCESS':
			return { ...state };
		case 'OVERWRITE_TRIP_SUCCESS_ERROR':
			return { ...state };

		default:
			return state;
	}
};

export default adminReducer;
