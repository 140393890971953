import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { signOut } from '../../redux/Actions/authActions';

const Unauthorized = ({ signOut }) => {
	useEffect(() => {
		setTimeout(() => {
			signOut();
		}, 5000);
	}, []);
	return (
		<div className='error-window'>
			<main>
				<h2>
					You are being redirected to the sign in page. Please sign in as an
					administrator.
				</h2>
			</main>
		</div>
	);
};

export default connect(null, { signOut })(Unauthorized);
