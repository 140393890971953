import React from 'react';
import { connect } from 'react-redux';
import { FadeLoader } from 'react-spinners';

import css from '@emotion/css';
import { useCallback } from 'react';
import FlightGroup from './FlightGroup';

const override = css`
	display: block;
	margin: 5rem auto;
	border-color: red;
`;

const FindFlights = ({ flights, loading }) => {
	const returnRecommendations = useCallback(() => {
		return flights && flights.recommendations && flights.recommendations.length
			? flights.recommendations.map((recommendation, index) => {
					return (
						<>
							<FlightGroup
								legs={recommendation.correspondingLegs}
								fareDetails={recommendation.fareDetails}
								recommendation={recommendation}
								recommendationIndex={index}
								itemIndex={index}
							/>
						</>
					);
			  })
			: null;
	});
	return (
		<>
			<div></div>
			<div className='flight-results'>
				{loading ? (
					<FadeLoader
						css={override}
						size={150}
						color={'black'}
						loading={true}
					/>
				) : (
					<>{returnRecommendations()}</>
				)}
			</div>
		</>
	);
};
const mapStateToProps = (state) => {
	return {
		flights: state.flight.flights,
		loading: state.flight.loading,
	};
};

export default connect(mapStateToProps, null)(FindFlights);
