import React from 'react';
import { handleTime } from '../../Utilities/helperFunctions';
import { IoIosArrowRoundForward } from 'react-icons/io';

import noImage from '../../images/noun_Camera_prohibited_1554484.svg';

const FlightLegNew = ({
	marketingCarrier,
	airlineLogo,
	durationString,
	airlineName,
	stopsString,
	origin,
	destination,
	isListItem,
}) => {
	const {
		airport_info: originAirportInfo,
		date: originDate,
		time: originTime,
	} = origin;
	const {
		airport_info: destinationAirportInfo,
		date: destinationDate,
		time: destinationTime,
	} = destination;

	return (
		<div className='flight__group-leg'>
			<img
				src={airlineLogo}
				alt=''
				onError={({ currentTarget }) => {
					currentTarget.onerror = null; // prevents looping
					currentTarget.src = noImage;
				}}
			/>
			<main>
				<h3
					style={
						isListItem ? { display: 'flex', flexDirection: 'column' } : null
					}>
					{airlineName}{' '}
					<span style={isListItem ? { marginLeft: 0 } : null}>
						{durationString}
					</span>
				</h3>
				<div className='leg__sub-info'>
					<span>Economy</span>
					<span>{!isListItem ? 'Seats Available: 9' : null}</span>
					<span>{stopsString}</span>
					<span>{!isListItem ? 'Checked Bags: none' : null}</span>
				</div>
			</main>
			<section className='leg__time-location'>
				<div className='departure-arrival'>
					<h3>{originAirportInfo['IATA']}</h3>
					<span>{handleTime(originTime)}</span>
					<span>{originDate}</span>
				</div>
				<div className='icon'>
					<IoIosArrowRoundForward />
				</div>

				<div className='departure-arrival'>
					<h3>{destinationAirportInfo['IATA']}</h3>
					<span>{handleTime(destinationTime)}</span>
					<span>{destinationDate}</span>
				</div>
			</section>
		</div>
	);
};

export default FlightLegNew;
