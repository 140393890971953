import React, { useState } from 'react';
import { sanitizeFlightLeg } from '../../Utilities/flightHelpers';
import uuid from 'react-uuid';
import FlightLegNew from './FlightLegNew';
import { connect } from 'react-redux';
import {
	addItineraryItem,
	deleteItineraryItem,
} from '../../redux/Actions/trip';
import { filterFlightRec } from '../../redux/Actions/flightActions';

const FlightGroup = (props) => {
	const {
		legs,
		fareDetails,
		_id,
		addItineraryItem,
		recommendation,
		recommendationIndex,
		deleteReviewItem,
		isSelected,
		filterFlightRec,
		adults,
		children,
	} = props;
	const [selected, setSelected] = useState(isSelected);
	const totalPassengers = adults + children;
	const { total_amount } = recommendation?.pricing_info;

	const trimData = (data) => {
		const trimmedData = {
			data,
			trip: _id,
			type: 'flight-offer',
			correspondingSearchItem: { recommendation, recommendationIndex },
		};
		return trimmedData;
	};

	const trimmedData = trimData({ recommendation, legs });

	return (
		<div className={`flight-group${!selected ? '' : ' selected'}`}>
			<div className='flight-group__legs'>
				{legs.map((leg, index) => {
					const sanitizedLeg = sanitizeFlightLeg({ leg, index, fareDetails });
					return <FlightLegNew key={uuid()} {...sanitizedLeg} />;
				})}
			</div>
			<div className='actions'>
				<div className='price'>
					<h3>${total_amount}</h3>
					<span>~${(total_amount / totalPassengers).toFixed(2)}/person</span>
				</div>
				{!selected ? (
					<button
						className='item-review__button'
						onClick={(e) => {
							e.preventDefault();
							console.log('button clicked');
							setSelected(true);
							filterFlightRec(recommendationIndex);
							addItineraryItem('flights', trimmedData);
						}}>
						Select
					</button>
				) : (
					<button className='item-review__button' onClick={deleteReviewItem}>
						Remove
					</button>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	_id: state.trip._id,
	adults: state.trip.adults,
	children: state.trip.children,
	infants: state.trip.infants,
});

export default connect(mapStateToProps, {
	addItineraryItem,
	deleteItineraryItem,
	filterFlightRec,
})(FlightGroup);
