import React from 'react';
import { connect } from 'react-redux';
import UserPreference from './UserPreference';

const UserPreferenceSection = ({ preferences }) => {
	const {
		flightPrice,
		seatLocation,
		seating,
		fareType,
		tripInsurance,
		lodgingPrice,
		idealLocation,

		lodgingType,
		driveVsDriver,
		publicTransportationPreferences,
		publicVsPrivate,
		activitySamples,
		tours,
		sightSeeingTours,
		physicalActivity,
	} = preferences;

	return (
		<div className='user-preferences'>
			<div className='container'>
				<h5>Flight Preferences</h5>
				<span></span>
				<UserPreference name='Flight Price' value={flightPrice} />
				<UserPreference name='Seat Location' value={seatLocation} />
				<UserPreference name='Seating' value={seating} />
				<UserPreference name='Fare Type' value={fareType} />
				<UserPreference name='Trip Insurance' value={tripInsurance} />
				<span></span>
				{/* <p>Checked Bag: {checkedBag}</p> */}

				<h5>Transportation Preferences</h5>
				<span></span>
				<UserPreference name='Driver Needed?' value={driveVsDriver} />
				<UserPreference
					name='Public Transportation'
					value={publicTransportationPreferences}
				/>
				<UserPreference name='PublicVsPrivate' value={publicVsPrivate} />
				<span></span>

				<h5>Activity Preferences</h5>
				<span></span>
				<UserPreference
					name='Activity Samples'
					value={
						activitySamples && activitySamples.length
							? activitySamples.map((item, index) =>
									index === activitySamples.length - 1 ? item : `${item}, `
							  )
							: null
					}
				/>
				<UserPreference name='SightSeeing Tours?' value={sightSeeingTours} />
				<UserPreference name='Physical Activity?' value={physicalActivity} />
				<UserPreference
					name='Tours'
					value={
						tours && tours.length
							? tours.map((item, index) =>
									index === tours.length - 1 ? item : `${item}, `
							  )
							: null
					}
				/>
				{/* <p>privateVsPublicTour: {privateVsPublicTour}</p> */}
				{/* <p>specialEventInterest: {specialEventInterest}</p> */}

				<h5>Lodging Preferences</h5>
				<span></span>
				<UserPreference
					name='Price Range'
					value={`$${lodgingPrice.min} - ${
						!lodgingPrice.max ? 'no max' : `$${lodgingPrice.max}`
					}`}
				/>
				<UserPreference
					name='Ideal Location'
					value={`${idealLocation.min} miles - ${
						!idealLocation.max ? 'no max' : `${idealLocation.max} miles`
					}`}
				/>
				<UserPreference name='Lodging Type' value={lodgingType} />
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	preferences: state.admin.selectedUser.preferences,
});

export default connect(mapStateToProps)(UserPreferenceSection);
