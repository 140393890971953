import React, { useState } from 'react';
import { connect } from 'react-redux';
import { searchCars } from '../../redux/Actions/carActions';
import { useEffect } from 'react';

const CarForm = ({
	searchCars,
	populate,
	disabled,
	setCurrentIndex,
	currentIndex,
	index,
	formRef,
	endIndex,
	setCurrentForm,
}) => {
	const [formData, setFormData] = useState(
		populate || {
			pickup_info: {
				formattedDate: '',
				formattedTime: '12:00 p.m.',
				year: '',
				month: '',
				day: '',
				hour: '12',
				minutes: '0',
				location: {
					code: '1A',
					name: '',
					formattedText: '',
				},
			},
			dropoff_info: {
				formattedDate: '',
				formattedTime: '12:00 p.m.',
				year: '',
				month: '',
				day: '',
				hour: '12',
				minutes: '0',
				location: {
					code: '1A',
					name: '',
					formattedText: '',
				},
			},
		}
	);
	const handleDateChange = (e, key) => {
		e.preventDefault();
		const { value } = e.target;
		const year = parseInt(value.slice(0, 4)) + '';
		const month = parseInt(value.slice(5, 7)) + '';
		const day = parseInt(value.slice(8)) + '';
		setFormData({
			...formData,
			[key]: {
				...formData[key],
				year,
				month,
				day,
				formattedDate: value,
			},
		});
	};

	const handleTimeChange = (e, key) => {
		e.preventDefault();
		let { value } = e.target;
		value = JSON.parse(value);
		setFormData({
			...formData,
			[key]: {
				...formData[key],
				...value,
			},
		});
	};
	const handleLocationChange = (e, key) => {
		e.preventDefault();
		const { value } = e.target;

		setFormData({
			...formData,
			[key]: {
				...formData[key],
				location: { ...formData[key].location, name: value.toUpperCase() },
			},
		});
	};
	const returnTimeOptions = (keyText) => {
		const results = [];
		let hour = 0;
		let minutes = 0;
		let time = 'a.m.';
		while (hour < 24) {
			let hourCopy = hour;
			let minutesCopy = minutes;
			if (!hourCopy) hourCopy = 12;
			hourCopy = hourCopy < 10 ? `0${hourCopy}` : hourCopy;
			let formattedHourCopy = hourCopy > 12 ? `0${hourCopy - 12}` : hourCopy;
			minutesCopy = !minutesCopy ? '00' : '30';
			if (hour >= 12 && time !== 'p.m.') time = 'p.m.';
			results.push({
				formattedTime: `${formattedHourCopy}:${minutesCopy} ${time}`,
				minutes: parseInt(minutesCopy) + '',
				hour: hour + '',
			});
			hour = !minutes ? hour : hour + 1;
			minutes = !minutes ? 30 : 0;
		}

		const {
			formattedTime,
			minutes: currentMinutes,
			hour: currentHour,
		} = formData[keyText];
		return (
			<div className='text-field'>
				<label htmlFor={keyText}>
					{keyText === 'dropoff_time' ? 'Dropoff time:' : 'Pickup time'}
				</label>
				<select
					disabled={disabled}
					onChange={(e) => handleTimeChange(e, keyText)}
					name={keyText}
					defaultValue={JSON.stringify({
						formattedTime,
						minutes: currentMinutes,
						hour: currentHour,
					})}>
					{results.map((item) => {
						return (
							<option
								value={JSON.stringify(item)}
								key={`${keyText}-${item.formattedTime}`}>
								{item.formattedTime}
							</option>
						);
					})}
				</select>
			</div>
		);
	};

	useEffect(() => {
		if (populate) {
			setFormData({
				...formData,
				...populate,
			});
		}
	}, [populate]);

	return (
		<form
			className={`car-form`}
			onSubmit={(e) => {
				e.preventDefault();
				searchCars(formData);
				setCurrentForm('');
			}}
			disabled={disabled}
			ref={formRef}>
			<div className='text-field'>
				<label>Pickup Location:</label>
				<input
					disabled={disabled}
					onChange={(e) => handleLocationChange(e, 'pickup_info')}
					value={formData.pickup_info.location.name}
					type='text'
				/>
			</div>

			<div className='flex-section'>
				<div className='text-field'>
					<label htmlFor='departureDate'>Pickup Date:</label>
					<input
						disabled={disabled}
						onChange={(e) => handleDateChange(e, 'pickup_info')}
						type='date'
						name='departureDate'
						id='departureDate'
						value={formData.pickup_info.formattedDate}
					/>
				</div>

				{returnTimeOptions('pickup_info')}
			</div>
			<div className='text-field'>
				<label>Dropoff Location:</label>
				<input
					disabled={disabled}
					onChange={(e) => handleLocationChange(e, 'dropoff_info')}
					value={formData.dropoff_info.location.name}
					type='text'
				/>
			</div>

			<div className='flex-section'>
				<div className='text-field'>
					<label htmlFor='departureDate'>Dropoff Date:</label>
					<input
						disabled={disabled}
						onChange={(e) => handleDateChange(e, 'dropoff_info')}
						type='date'
						value={formData.dropoff_info.formattedDate}
					/>
				</div>
				{returnTimeOptions('dropoff_info')}
			</div>

			<div className='button-section'>
				<button
					disabled={disabled}
					className='cta-button '
					type='submit'
					name='action'>
					Submit
				</button>
				{index > 0 && (
					<button
						disabled={disabled}
						onClick={() => {
							setCurrentIndex(index - 1);
						}}
						className='cta-button '
						type='button'>
						Previous Rental
					</button>
				)}
				<button
					disabled={disabled}
					onClick={() => {
						if (currentIndex !== endIndex) setCurrentIndex(index + 1);
					}}
					className='cta-button '
					type='button'>
					Next Rental
				</button>
			</div>
		</form>
	);
};

export default connect(null, { searchCars })(CarForm);
