import React from 'react';
import { connect } from 'react-redux';
import { setCurrentForm } from '../../redux/Actions/trip';
import ErrMessage from '../Layout/ErrMessage';
import { returnTrueDate } from '../Utilities/returnTrueDate';
import CloseSection from './CloseSection';

const HotelQueryForm = ({
	enabled,
	handleSubmit,
	handleCitySearch,
	handleChange,
	prevEndDate,
	state,
	handleSelectCity,
	index,
	endIndex,
	clearHotels,
	setCurrentIndex,
	setCurrentForm,
	formRef,
}) => {
	let {
		locationInput,
		searchResults,
		startDate,
		endDate,
		radius,
		rooms,
		adults,
		children,
		infants,
	} = state;

	const returnSearchResults = () => {
		return searchResults && searchResults.length ? (
			<div className='autofill-section'>
				{searchResults.map((result, resultIndex) => {
					return (
						<div
							key={`hotel-search-result-${index}-${resultIndex}`}
							className='autofill-option'
							onClick={() => handleSelectCity(result)}>
							<p className='autofill-airport-name'>
								{result['cityName']}, {result['stateCode']}{' '}
								{result['countryName']}
							</p>
						</div>
					);
				})}
			</div>
		) : null;
	};

	const handleDateError = (name) => {
		let formattedStartDate = returnTrueDate(startDate);
		let formattedEndDate = returnTrueDate(endDate);
		if (
			(name === 'startDate' && formattedStartDate < prevEndDate) ||
			formattedStartDate < new Date()
		) {
			return (
				<ErrMessage text='Please select a valid date from the drop down menu' />
			);
		}

		if (name === 'endDate' && formattedEndDate < formattedStartDate) {
			return (
				<ErrMessage text='Please select a valid date from the drop down menu' />
			);
		}
	};
	return (
		<div ref={formRef}>
			<CloseSection />
			<form
				className={`hotel-form${enabled ? ' disabled' : ''}`}
				onSubmit={(event) => {
					handleSubmit(event, false);
				}}>
				<div className='flex-section'>
					<div className='text-field' style={{ width: '100%' }}>
						<label
							className='red-x'
							style={{ cursor: 'default' }}
							type='text'
							id='destinationLocationCode'>
							Location
						</label>
						<input
							disabled={enabled}
							value={locationInput}
							type='text'
							id='destinationLocationCode'
							name='locationInput'
							className='capitalize'
							onChange={handleCitySearch}
						/>
						{returnSearchResults()}
					</div>
				</div>
				<div className='flex-section'>
					<div className='text-field' style={{ width: '50%' }}>
						<label htmlFor='startDate'>Check In Date</label>
						<input
							disabled={enabled}
							type='date'
							name='startDate'
							value={startDate}
							onChange={handleChange}
						/>
						{handleDateError('startDate')}
					</div>
					<div className='text-field' style={{ width: '50%' }}>
						<label htmlFor='endDate'>Check Out Date</label>
						<input
							disabled={enabled}
							type='date'
							name='endDate'
							value={endDate}
							onChange={handleChange}
						/>
						{handleDateError('endDate')}
					</div>
				</div>
				<div className='flex-section passengers'>
					<div class='text-field' style={{ width: '33.33%' }}>
						<label htmlFor='adults'>Adults:</label>
						<input
							disabled={enabled}
							value={adults}
							name='adults'
							type='text'
							onChange={handleChange}
						/>
					</div>
					<div className='text-field' style={{ width: '33.33%' }}>
						<label htmlFor='children'>Children:</label>
						<input
							disabled={enabled}
							value={children}
							name='children'
							type='text'
							onChange={handleChange}
						/>
					</div>
					<div className='text-field' style={{ width: '33.33%' }}>
						<label htmlFor='infants'>Infants:</label>
						<input
							disabled={enabled}
							value={infants}
							name='infants'
							type='text'
							onChange={handleChange}
						/>
					</div>
				</div>

				<div className='flex-section'>
					<div class='text-field' style={{ width: '500%' }}>
						<label htmlFor='radius'>Radius:</label>
						<input
							disabled={enabled}
							name='radius'
							value={radius}
							type='number'
							onChange={handleChange}
						/>
					</div>
					<div className='text-field' style={{ width: '500%' }}>
						<label htmlFor='rooms'>Rooms:</label>
						<select name='rooms' onChange={handleChange} value={rooms}>
							<option value={1}>1</option>
							<option value={2}>2</option>
							<option value={3}>3</option>
						</select>
					</div>
				</div>

				<div className='button-section'>
					<div className='form-nav__section'>
						{index === endIndex ? null : (
							<button
								disabled={enabled}
								onClick={() => {
									clearHotels();
									setCurrentIndex();
								}}
								className='cta-button'
								type='button'>
								Next Hotel
							</button>
						)}
					</div>
					<button disabled={enabled} type='submit' name='action'>
						Submit
					</button>
				</div>
			</form>
		</div>
	);
};

export default connect(null, { setCurrentForm })(HotelQueryForm);
