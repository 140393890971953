import React, { Component } from 'react';
import { connect } from 'react-redux';
//Utils
//Store
import { clearHotels, fetchHotels } from '../../redux/Actions/hotelActions';
import { editHotel } from '../../redux/Actions/trip';
//API
import { returnCitySearch } from '../../Utilities/Utils';
import HotelQueryForm from './HotelQueryForm';

class HotelQuery extends Component {
	state = {
		email: this.props.profile.email,
		subType: 'CITY',
		destinationLocationCode: {},
		startDate: this.props.checkinDate || '',
		endDate: this.props.checkoutDate || '',
		adults: this.props.adults || 1,
		children: this.props.children || 0,
		childAges: [],
		infants: this.props.infants || 0,
		nonStop: false,
		currency: 'USD',
		radius: this.props.radius,
		priceRange: this.props.priceRange,
		rooms: this.props.rooms,
		destination: this.props.destination,
		locationInput: `${this.props.destination?.cityName.toLowerCase()}, ${
			this.props.destination?.countryCode
		}`,
		stage: 0,
		searchResults: [],
	};

	nonStopToggle = () => {
		this.setState({
			nonStop: !this.state.nonStop,
		});
	};

	cabin = ['ECONOMY', 'PREMIUM_ECONOMY', 'BUSINESS', 'FIRST'];
	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
		this.props.editHotel(this.props.index, e.target.name, e.target.value);
	};

	handlePriceChange = (e, name) => {
		let priceRange = this.state.priceRange;

		switch (e.target.value) {
			case 0:
				break;
			default:
				priceRange[name] = e.target.value;
				break;
		}
		this.props.editHotel(this.props.index, 'priceRange', priceRange);

		this.setState({
			priceRange,
		});
	};

	handleCitySearch = (event) => {
		event.preventDefault();

		const { name, value } = event.target;
		const searchValue = value.toUpperCase();
		const searchLength = searchValue.length;
		if (!searchValue) {
			this.setState({ searchResults: [] });
		} else if (searchLength > 2) {
			const searchResults = returnCitySearch(searchValue);
			this.setState({ searchResults });
		}
		this.setState({
			[name]: value,
		});
	};

	handleSelectCity = (result) => {
		this.props.editHotel(this.props.index, 'city', {
			cityCode: result['cityCode'],
			countryCode: result['countryCode'],
			cityName: result['cityName'],
		});
		this.setState({
			locationInput: `${result['cityName'].toLowerCase()}, ${
				result['countryCode']
			}`,
			searchResults: [],
			destination: {
				cityCode: result['cityCode'].toUpperCase(),
				countryCode: result['countryCode'].toUpperCase(),
				cityName: result['cityName'],
			},
		});
	};

	handleDateError = () => {};

	render() {
		return (
			<HotelQueryForm
				formRef={this.props.formRef}
				prevEndDate={this.props.prevEndDate}
				state={this.state}
				handleSubmit={this.props.handleSubmit}
				handleCitySearch={this.handleCitySearch}
				handleChange={this.handleChange}
				handlePriceChange={this.handlePriceChange}
				index={this.props.index}
				endIndex={this.props.endIndex}
				clearHotels={this.props.clearHotels}
				setCurrentIndex={this.props.setCurrentIndex}
				handleSelectCity={this.handleSelectCity}
			/>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		profile: state.profile.profile,
		trip: state.trip,
		more_indicator: state.hotel.more_indicator,
	};
};

export default connect(mapStateToProps, {
	clearHotels,
	fetchHotels,
	editHotel,
})(HotelQuery);
