import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
	handleDescription,
	handleImage,
	handleRating,
	handleTitleDisplay,
	handleURL,
	checkType,
} from '../../Utilities/helperFunctions';
import noImage from '../../images/noun_Camera_prohibited_1554484.svg';
import { toDollarStyle } from '../../Utilities/Utils';
import { removeAuthItem } from '../../redux/Actions/authItem';
import {
	returnItemFields,
	checkItemStatus,
} from '../../Utilities/helperFunctions';
import ItemDetails from '../ItineraryReview/ItinerarySidenav/selectedModal/ItemDetails';
import ItemOptions from '../Hotels/ItemOptions';
import FlightInfo from '../ItineraryReview/ItinerarySidenav/selectedModal/FlightInfo';
import ModalImage from '../ItineraryReview/ItinerarySidenav/ModalImage';
import SelectedButton from '../ItineraryReview/ItinerarySidenav/selectedModal/SelectedButton';
import moment from 'moment';

const ReviewItem = ({
	selectedItem,
	removeSelected,
	removeFromCart,
	deselectModalItem,
	trip,
	addToCart,
	descriptiveInfo,
	removeAuthItem,
}) => {
	const typeChecker = checkType(
		selectedItem ? selectedItem.type || selectedItem.data.type : null
	);
	const { flight, lodging, key } = typeChecker;
	const { itemPaid, itemAuthorized, itemSelected, itemInCart } =
		checkItemStatus(trip, selectedItem);

	let HotelCode,
		MultimediaDescription,
		HotelInfo,
		Rate,
		AmountAfterTax,
		CurrencyCode,
		// descriptive_info,
		Total;

	if (lodging) {
		({ HotelCode, Total } = returnItemFields(selectedItem.data, [
			'HotelCode',
			'Total',
		]))(
			({ HotelInfo } = returnItemFields(descriptiveInfo[HotelCode], [
				'HotelInfo',
			]))
		)(
			({ MultimediaDescription } = returnItemFields(HotelInfo, [
				'MultimediaDescription',
			]))
		)(
			({ Rate, AmountAfterTax, CurrencyCode } = returnItemFields(Total, [
				'Rate',
				'AmountAfterTax',
				'CurrencyCode',
			]))
		);
	}

	// descriptive_info =
	// 	descriptiveInfo &&
	// 	lodging &&
	// 	descriptiveInfo[selectedItem.data.hotel_info['info']['HotelCode']];

	const handleRemoveItem = () => {
		const { _id } = selectedItem;
		if (itemSelected) removeSelected(_id, key);
		if (itemInCart) removeFromCart(_id, key);
	};

	const handleClick = () => {
		if (itemSelected || itemInCart) {
			handleRemoveItem();
			deselectModalItem();
		} else if ((!itemPaid, !itemAuthorized)) {
			addToCart(selectedItem._id, key);
			deselectModalItem();
		}
	};

	const handlePriceDisplay = () => {
		if (selectedItem) {
			const { type, price, data } = selectedItem;
			if (type === 'individual-activity' || type === 'public-transport') {
				return `${price.amount} ${price.currencyCode}/ Person`;
			}
			if (type === 'group-activity') {
				return `${price.amount} ${price.currencyCode}/ Group`;
			}
			if (type === 'private-transport') {
				return `${price.amount} ${price.currencyCode}/ Trip`;
			}
			if (data && lodging) {
				const startDate = moment(new Date(Rate[0].info['EffectiveDate']));
				const endDate = moment(
					new Date(
						Rate[Rate.length - 1]['Total']
							? Rate[Rate.length - 2].info.ExpireDate
							: Rate[Rate.length - 1].info.ExpireDate
					)
				);
				const days = endDate.diff(startDate, 'days');
				return `${toDollarStyle(AmountAfterTax / days, CurrencyCode)}/ Night`;
			}
			if (data & flight) {
				return `${price.total} ${price.currency}`;
			}
		}
	};

	const handleDetails = () => {
		return !flight ? (
			<Fragment>
				<ModalImage src={handleImage(MultimediaDescription) || noImage} />
				<div className='content-container'>
					<ItemDetails
						title={handleTitleDisplay(selectedItem, typeChecker)}
						url={handleURL(selectedItem, lodging)}
						rating={handleRating(selectedItem, lodging)}
						price={handlePriceDisplay()}
						description={handleDescription(selectedItem, typeChecker)}
						type={selectedItem.type || selectedItem.data.type}
						groupCapacity={
							selectedItem.groupCapacity && selectedItem.groupCapacity
						}
						hotelPickup={selectedItem.hotelPickup}
					/>
					<ItemOptions type={selectedItem.type || selectedItem.data.type} />
				</div>
			</Fragment>
		) : (
			returnFlightInfo()
		);
	};

	const returnFlightInfo = () => {
		return selectedItem &&
			selectedItem.itineraries &&
			selectedItem.itineraries.length
			? selectedItem.itineraries.map((itinerary, index) => {
					return (
						<FlightInfo
							key={`${selectedItem._id}-flight-info-${index}`}
							itineraryItem={itinerary}
							index={index}
						/>
					);
			  })
			: null;
	};

	const returnButton = () => {
		return (
			<SelectedButton
				id={selectedItem._id}
				handleClick={handleClick}
				inItinerary={itemSelected || itemInCart}
			/>
		);
	};

	const returnComponents = () => {
		return selectedItem ? (
			<Fragment>
				<button className='modal-close' onClick={removeAuthItem}>
					<i className='fa fa-times'></i>
				</button>

				{handleDetails()}
				{returnButton()}
			</Fragment>
		) : null;
	};

	return returnComponents();
};

const mapStateToProps = (state) => ({
	selectedItem: state.authItem.item,
	trip: state.trip,
	descriptiveInfo: state.hotel.descriptiveInfo,
});

export default connect(mapStateToProps, {
	removeAuthItem,
})(ReviewItem);
