import React from 'react';
import CreditCardInput from 'react-credit-card-input';
import FormField from '../CustomData/FormField';

const CCForm = ({ ccData, handleChange }) => {
	return (
		<form onSubmit={null} className='white transport-form'>
			<FormField
				required={true}
				name='user_id'
				type='text'
				value={ccData.user_id}
				handleChange={handleChange}
				title='User Idendification'
			/>
			<FormField
				required={true}
				name='cc_name'
				type='text'
				value={ccData.cc_name}
				handleChange={handleChange}
				title='Name on Card'
			/>
			<CreditCardInput
				cardCVCInputRenderer={({ handleCardCVCChange, props }) => (
					<input
						{...props}
						name='cvc_code'
						value={ccData.cvc_code}
						onChange={handleCardCVCChange((e) => handleChange(e))}
					/>
				)}
				cardExpiryInputRenderer={({ handleCardExpiryChange, props }) => (
					<input
						{...props}
						name='cc_exp'
						value={ccData.cc_exp}
						onChange={handleCardExpiryChange((e) => handleChange(e))}
					/>
				)}
				cardNumberInputRenderer={({ handleCardNumberChange, props }) => (
					<input
						{...props}
						name='cc_number'
						value={ccData.cc_number}
						onChange={handleCardNumberChange((e) => handleChange(e))}
					/>
				)}
				containerClassName='credit-card__container'
				fieldClassName='credit-card__field'
				containerStyle={{
					width: '100%',
					display: 'flex',
				}}
				fieldStyle={{
					width: '100%',
				}}
			/>
		</form>
	);
};

export default CCForm;
