import React from 'react';
import { setRedirect } from '../../redux/Actions/redirectActions';
import { connect } from 'react-redux';

const ErrorPage = ({ setRedirect }) => {
	// useEffect(() => {
	// 	setTimeout(() => {
	// 		setRedirect('/itinerary');
	// 	}, 4000);
	// }, []);
	return (
		<div className='error-window'>
			<main>
				<h2>Oops, something went wrong. We are working on it.</h2>
				<button
					onClick={() => {
						if (window.location.pathname === '/') {
							window.location.reload(false);
						} else {
							setRedirect('/');
						}
					}}>
					Let's try this again
				</button>
			</main>
		</div>
	);
};

export default connect(null, { setRedirect })(ErrorPage);
