import React, { useState } from 'react';
import copy from '../../images/noun-copy-5649964-142350.svg';

import checkmark from '../../images/noun-checkmark-3345771-0DC25E.svg';

const UserIdCopy = ({ userId }) => {
	const [hovered, setHovered] = useState(false);
	const [copied, setCopied] = useState(false);
	const handleCopy = () => {
		navigator.clipboard.writeText(userId);
		setCopied(true);
	};
	return (
		<div
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => {
				setHovered(false);
				setCopied(false);
			}}
			className='item-list__item-customer-id'>
			{hovered ? (
				<button onClick={handleCopy}>
					{!copied ? <img src={copy} alt='' /> : <img src={checkmark} alt='' />}
				</button>
			) : null}
			{userId}
		</div>
	);
};

export default UserIdCopy;
