import { airlines } from './airlines';

export const sanitizeFlightData = (data) => {
	let { legs, recommendation } = data;
	const { pricing_info, baggage_info } = recommendation;

	const { total_amount, passenger_pricing } = pricing_info;

	const leg = legs && legs[0];

	const hasLength = leg && leg.flight_details && leg.flight_details.length;
	const flightDetailEnd = hasLength && leg.flight_details.length - 1;

	const departure = hasLength && leg.flight_details[0].departure;
	const arrival =
		hasLength &&
		leg.flight_details[flightDetailEnd] &&
		leg.flight_details[flightDetailEnd].arrival;

	const marketingCarrier =
		hasLength &&
		leg.flight_details[0].companyId &&
		leg.flight_details[0].companyId.marketingCarrier;
	const imageURL =
		marketingCarrier &&
		airlines[marketingCarrier] &&
		airlines[marketingCarrier].image;

	const firstLegAirline =
		marketingCarrier &&
		airlines[marketingCarrier] &&
		airlines[marketingCarrier].name;

	const fareDetails =
		pricing_info &&
		pricing_info.passenger_pricing &&
		pricing_info.passenger_pricing.length &&
		pricing_info.passenger_pricing[0] &&
		pricing_info.passenger_pricing[0].fareDetails;

	return {
		fareDetails,
		recommendation,
		baggageInfo: baggage_info,
		numberOfBookableSeats: passenger_pricing,
		departure,
		arrival,
		leg,
		imageURL,
		total: total_amount,
		firstLegAirline,
		legs,
		marketingCarrier,
	};
};

export const sanitizeFlightLeg = ({ leg, index, fareDetails }) => {
	const { flight_duration, flight_details: flightDetails, baggageInfo } = leg;

	const travelerPricings = fareDetails && fareDetails[index];
	const groupOfFares = travelerPricings && travelerPricings.groupOfFares;
	const [hours, minutes] = flight_duration.split(':');
	const durationString = `${hours}h ${minutes}m`;
	const marketingCarrier =
		flightDetails &&
		flightDetails.length &&
		flightDetails[0] &&
		flightDetails[0].companyId &&
		flightDetails[0].companyId.marketingCarrier
			? flightDetails[0].companyId.marketingCarrier
			: null;

	const airlineName =
		marketingCarrier && airlines[marketingCarrier]
			? airlines[marketingCarrier].name
			: null;

	const airlineLogo =
		marketingCarrier && airlines[marketingCarrier]
			? airlines[marketingCarrier].image
			: null;

	const endIndex = flightDetails.length - 1;

	const stopsString = endIndex ? endIndex + ' stops' : 'Nonstop';

	const origin = flightDetails[0].departure;
	const destination = flightDetails[endIndex].arrival;

	return {
		origin,
		destination,
		stopsString,
		airlineLogo,
		airlineName,
		flightDetails,
		groupOfFares,
		travelerPricings,
		marketingCarrier,
		baggageInfo,
		durationString,
	};
};
