import React from 'react';
import ListItemNew from '../listItems/ListItemNew';
import FeeItem from '../authorizedItems/FeeItem';
import ListItemOptions from '../listItems/ListItemOptions';
import { setCurrentForm } from '../../redux/Actions/trip';
import { connect } from 'react-redux';
import { setCustomForm } from '../../redux/Actions/customItemForm';

const CMDestinations = ({
	currentList,
	containerClass,
	authKey,
	setCurrentForm,
	window,
	setCustomForm,
}) => {
	const mapItems = () => {
		return currentList.map((item, index) => {
			const { cityName, stateCode, countryName } = item.location;
			return (
				<div key={item._id} className='item-list__item suggested-destination'>
					<button
					// onClick={() => {
					// 	setCurrentForm('authItem');
					// 	selectPurchaseObject({ index, purchase: item, type });
					// }}
					>
						<h3 className='capitalize'>{cityName}</h3>
						<div className='item-list__item-type'>
							<span className='capitalize'>
								{stateCode !== 'null' ? stateCode : '-'}
							</span>
						</div>
						<div className='item-list__item-status capitalize'>
							{countryName}
						</div>
					</button>
					<div className='secondary-actions'>
						<ListItemOptions
							edit={
								<>
									<button
										className='item-list__item-options--button'
										onClick={(e) => {
											e.preventDefault();
											setCustomForm('destination', item);
											setCurrentForm('destination');
										}}>
										Edit
									</button>
								</>
							}
							// remove={
							// 	<>
							// 		<button
							// 			className='item-list__item-options--button'
							// 			onClick={(e) => {
							// 				e.preventDefault();
							// 				setCustomForm('destination', item);
							// 				setCurrentForm('destination');
							// 			}}>
							// 			Edit
							// 		</button>
							// 	</>
							// }
						/>
					</div>
				</div>
			);
		});
	};

	return (
		<>
			<div
				className={`item-list__container destinations ${
					containerClass ? ` ${containerClass}` : ''
				}`}>
				<header className={`item-list__header suggested-destination`}>
					<span className='item-list__header-item'>City</span>
					<span className='item-list__header-type'>State</span>
					<span className='item-list__header-status'>Country</span>

					<span className='item-list__header-options'>Options</span>
				</header>

				{currentList && currentList.length && window === 'suggested'
					? mapItems()
					: null}
			</div>
		</>
	);
};

export default connect(null, { setCurrentForm, setCustomForm })(CMDestinations);
