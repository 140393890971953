import React, { Fragment } from 'react';
import SegmentSection from '../../selectedModal/SegmentSection';
import FlightSubInfo from '../FlightSubInfo';
import {
	handleLayover,
	handleTime,
	returnSameTimezone,
} from '../../../../../Utilities/helperFunctions';

const ModalFlightDetails = ({ flight_details, selectedItem }) => {
	const returnFlightDetails = () => {
		return flight_details && flight_details.length
			? flight_details.map((segment, index) => {
					return (
						<Fragment key={`${selectedItem._id}-flight-details-${index}`}>
							<div className='flight__info-section__container'>
								<SegmentSection
									time={`${handleTime(segment.departure.time)} - ${
										segment.departure.airport_info['Airport Name']
									} (${segment.departure.location})`}
									location={segment.departure.iataCode}
								/>

								<SegmentSection
									time={`${handleTime(segment.arrival.time)} - ${
										segment.arrival.airport_info['Airport Name']
									} (${segment.arrival.location})`}
									location={segment.arrival.iataCode}
								/>
							</div>
							<FlightSubInfo
								time={returnSameTimezone(segment.departure, segment.arrival)}
								bookingClass={
									selectedItem.data.recommendation.pricing_info
										.passenger_pricing[0].fareDetails[index].majCabin
								}
							/>
							{index < flight_details.length - 1
								? handleLayover(
										segment.arrival,
										flight_details[index + 1].departure,
										segment.arrival.iataCode
								  )
								: null}
						</Fragment>
					);
			  })
			: null;
	};

	return <>{returnFlightDetails()}</>;
};

export default ModalFlightDetails;
