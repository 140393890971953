import React from 'react';
import uuid from 'react-uuid';
import authorizationsIcon from '../../images/noun-bank-card-approve-524377-142350.svg';
import customItemsIcon from '../../images/noun-custom-729128-142350.svg';
import tripsIcon from '../../images/noun-earth-1792107-142350.svg';
import usersIcon from '../../images/noun-users-4299472-142350.svg';
import SideNavButton from './SideNavButton';
const SideNavList = () => {
	const buttonInfo = [
		{
			title: 'TRIPS',
			icon: tripsIcon,
			href: '',
		},
		{
			title: 'AUTHORIZATIONS',
			icon: authorizationsIcon,
			href: '',
			imageStyle: {
				height: '4rem',
				position: 'relative',
				left: '-2rem',
			},
		},
		{
			title: 'USERS',
			icon: usersIcon,
			href: '',
		},
		{
			title: 'CUSTOM ITEMS',
			icon: customItemsIcon,
			href: '',
		},
	];
	return (
		<ul className='sidenav__list-primary'>
			{buttonInfo.map((button) => {
				return <SideNavButton key={uuid()} {...button} />;
			})}
		</ul>
	);
};

export default SideNavList;
