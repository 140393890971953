const initState = {
	profile: {},
};

const profileReducer = (state = initState, action) => {
	switch (action.type) {
		case 'UPDATE_SUCCESS':
			return {
				...state,
				profile: action.payload,
			};
		case 'UPDATE_ERROR':
			return state;
		default:
			return state;
	}
};

export default profileReducer;
