import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import FormFieldSection from './FormFieldSection';
import {
	addItineraryItem,
	editItineraryItem,
	transportCache,
} from '../../../redux/Actions/trip';
import PropTypes from 'prop-types';
import { removeAuthItem } from '../../../redux/Actions/authItem';
import {
	handleTripEndDate,
	returnTimeTable,
} from '../../../Utilities/helperFunctions';
import CloseSection from '../../Queries/CloseSection';
import { setCustomForm } from '../../../redux/Actions/customItemForm';

import { setCurrentForm } from '../../../redux/Actions/trip';
import { transportFormTemplate } from '../../../Utilities/formTemplates';

const TransportForm = ({
	trip,
	addItineraryItem,
	editItineraryItem,
	index,
	setEdit,
	removeAuthItem,
	updateTransport,
	formRef,
	formPosition,
	customItemForm,
	setCustomForm,
	setCurrentForm,
}) => {
	const { formData: customFormData } = customItemForm;

	const [formData, setFormData] = useState(
		(customFormData && { ...customFormData, trip }) || {
			transportFormTemplate,
			trip,
		}
	);

	const [timeInterval, setTimeInterval] = useState(1);
	const [hotelMap, setHotelMap] = useState(null);

	let {
		type,
		name,
		description,
		imageUrl,
		startLocation,
		endLocation,
		price,
		timeLength,
		dateRange,

		startTimeRange,
		endTimeRange,
	} = formData || {};

	let requiredInputs = {
		type,
		name,
		description,
		startLocation,
		endLocation,
		imageUrl,
		timeLength,
		startTimeRange,
		endTimeRange,
	};

	const checkObjectFields = (obj) => {
		let valid = true;
		for (let key in obj) {
			if (!obj[key]) {
				valid = false;
			}
		}
		return valid;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let validSingleInputs = checkObjectFields(requiredInputs);
		let validPrice = checkObjectFields(price);
		let validDateRange = checkObjectFields(dateRange);
		if (validSingleInputs && validPrice && validDateRange && timeInterval) {
			const timeOptions = returnTimeTable(
				timeInterval,
				startTimeRange,
				endTimeRange
			);
			timeLength = parseFloat(timeLength);

			if (index >= 0 || formData.index >= 0) {
				updateTransport
					? updateTransport({
							...formData,
							timeLength,
							timeOptions,
					  })
					: editItineraryItem(
							'transport',
							{
								...formData,
								timeLength,
								timeOptions,
							},
							index >= 0 ? index : formData.index
					  );
				setCurrentForm('');
				removeAuthItem();
				setEdit && setEdit();
			} else {
				addItineraryItem('transport', {
					...formData,
					timeLength,
					timeOptions,
				});
				setTimeInterval(1);
				setCurrentForm('');
				setCustomForm('', null);
			}
		}
	};

	const handleDateRange = (e) => {
		e.preventDefault();
		let valid = true;
		const { name, value } = e.target;
		if (
			new Date(value) < new Date(trip.flightDetails[0].departureDate) ||
			new Date(value) > handleTripEndDate(trip)
		) {
			valid = false;
			setFormData({
				...formData,
				dateRange: {
					...formData.dateRange,
					[name]: '',
				},
			});
		}

		if (valid) {
			setFormData({
				...formData,
				dateRange: {
					...formData.dateRange,
					[name]: value,
				},
			});
		}
	};

	const handlePrice = (e) => {
		setFormData({
			...formData,
			price: {
				...formData.price,
				[e.target.name]: e.target.value,
			},
		});
	};

	const handleChange = (e) => {
		e.preventDefault();
		let { name, value } = e.target;
		console.log('e.target.value', e.target.value);
		console.log(
			' hotelMap[value.toLowerCase()]',
			hotelMap[value.toLowerCase()]
		);
		if (name === 'startLocation' && hotelMap && hotelMap[value.toLowerCase()]) {
			const mapValue = hotelMap[value.toLowerCase()];
			setFormData({
				...formData,
				startLocation: mapValue.city.cityName.toLowerCase(),
				endLocation: mapValue.city.cityName.toLowerCase(),
				dateRange: {
					start: mapValue.startDate.slice(0, 10),
					end: mapValue.endDate.slice(0, 10),
				},
			});
		} else {
			setFormData({
				...formData,
				[name]: value,
			});
		}
	};

	useEffect(() => {
		if (trip && trip.hotelDetails) {
			const map = {};
			trip.hotelDetails.forEach((detail) => {
				map[detail.city.cityName.toLowerCase()] = detail;
			});
			setHotelMap(map);
		}
	}, []);
	console.log('rerender');

	return (
		<div
			style={formPosition}
			className='itinerary-form__container'
			ref={formRef}>
			<CloseSection />
			<form onSubmit={handleSubmit} className='white transport-form'>
				<FormFieldSection
					hotelDetails={trip.hotelDetails}
					formData={formData}
					handleChange={handleChange}
					handlePrice={handlePrice}
					setTimeInterval={setTimeInterval}
					timeInterval={timeInterval}
					handleDateRange={handleDateRange}
				/>
			</form>
		</div>
	);
};

TransportForm.propTypes = {
	addItineraryItem: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
	trip: state.trip,
	customItemForm: state.customItemForm,
});

export default connect(mapStateToProps, {
	addItineraryItem,
	editItineraryItem,
	removeAuthItem,
	transportCache,
	setCustomForm,
	setCurrentForm,
})(TransportForm);
