import React from 'react';
import UserList from '../lists/UserList';
import { connect } from 'react-redux';
import { useState } from 'react';
import UserProfile from './UserProfile';
import { useEffect } from 'react';
import { removeUser } from '../../redux/Actions/adminActions';
import requireAuth from '../hoc/AuthComponent';
import redirectHOC from '../hoc/RedirectHOC';

const UsersDashboard = ({ users, selectedUser, removeUser }) => {
	const [searchInput, setSearchInput] = useState('');
	const handleChange = (e) => {
		e.preventDefault();
		setSearchInput(e.target.value);
	};
	useEffect(() => {
		return function cleanUp() {
			setSearchInput('');
			removeUser();
		};
	}, [removeUser]);
	return (
		<div className='dashboard'>
			{selectedUser ? (
				<UserProfile />
			) : (
				<>
					<div className='text-field'>
						<label htmlFor='searchInput'>Filter By Email</label>
						<input
							type='text'
							name='searchInput'
							value={searchInput}
							onChange={handleChange}
						/>
					</div>
					<UserList users={users} searchInput={searchInput} />
				</>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	users: state.admin.users,
	selectedUser: state.admin.selectedUser,
});

export default connect(mapStateToProps, { removeUser })(
	redirectHOC(requireAuth(UsersDashboard))
);
