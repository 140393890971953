import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	extractTotalPerNight,
	returnRoomStayLength,
} from '../../Utilities/helperFunctions';
import SortedHotels from './SortedHotels';
import moment from 'moment';

import css from '@emotion/css';
import PriceFilter from '../Queries/PriceFilter';
import { FadeLoader } from 'react-spinners';

const override = css`
	display: block;
	margin: 5rem auto;
	border-color: red;
`;

class FindHotels extends Component {
	constructor(props) {
		super(props);
		this.state = { text: '$0 - $150', min: 0, max: 150 };
	}

	handleChange = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		let parsedEvent =
			e.target.value === 'Custom'
				? { text: 'Custom', max: 0, min: 0 }
				: JSON.parse(e.target.value);
		if (name === 'min' || name === 'max') {
			this.setState({ [name]: parseInt(value) });
		} else this.setState({ ...parsedEvent });
	};
	render() {
		const { hotelDetail, trip } = this.props;
		const { lodging } = trip && trip.itineraryItems;
		let { startDate, endDate } = hotelDetail || {};
		startDate = startDate && moment(new Date(startDate.slice(0, 10)));
		endDate = endDate && moment(new Date(endDate.slice(0, 10)));
		const hotelDays =
			endDate && startDate ? endDate.diff(startDate, 'days') : null;
		const bookingMap = {};
		const lowestOptionPerHotel = {};

		lodging.forEach((item) => {
			if (
				bookingMap &&
				item &&
				item.data &&
				item.data.booking_code &&
				item.data.hotel_info &&
				item.data.hotel_info.info &&
				item.data.hotel_info.info['HotelCode']
			) {
				bookingMap[
					`${item.data.booking_code}${item.data.hotel_info.info['HotelCode']}`
				] = true;
			}
		});

		this.props.hotels &&
			this.props.hotels.length &&
			this.props.hotels.forEach((data1, index1) => {
				const hotelCode =
					data1 &&
					data1.hotel_info &&
					data1.hotel_info.info &&
					data1.hotel_info.info['HotelCode'];
				data1.hotel_room_stays.filter((data2, index) => {
					if (returnRoomStayLength(data2) !== hotelDays) return false;
					else {
						const roomRateTotal = extractTotalPerNight(data2);
						let compareTotal =
							(lowestOptionPerHotel[hotelCode] &&
								lowestOptionPerHotel[hotelCode].roomRateTotal) ||
							0;

						if (compareTotal === 0 || roomRateTotal < compareTotal) {
							lowestOptionPerHotel[hotelCode] = {
								hotelCode,
								roomRateTotal,
								index: index1,
							};
						}

						return true;
					}
				});
			});

		return (
			<>
				<PriceFilter handleChange={this.handleChange} range={this.state} />
				<div className='hotel-results'>
					{this.props.loading ? (
						<FadeLoader
							css={override}
							size={150}
							color={'black'}
							loading={true}
						/>
					) : (
						<SortedHotels
							state={this.state}
							min={this.state.min}
							max={this.state.max}
							lowestOptionPerHotel={lowestOptionPerHotel}
							hotels={this.props.hotels}
							hotelDetail={this.props.hotelDetail}
							descriptiveInfo={this.props.descriptiveInfo}
							bookingMap={bookingMap}
						/>
					)}
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		more_indicator: state.hotel.more_indicator,
		trip: state.trip,
		loading: state.hotel.loading,
		hotels: state.hotel.hotels,
		hotelDetail: state.hotel.hotelDetail,
		descriptiveInfo: state.hotel.descriptiveInfo,
	};
};

export default connect(mapStateToProps, null)(FindHotels);
