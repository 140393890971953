import React, { Fragment } from 'react';

import arrow from '../../images/noun_Arrow_1256499.svg';

import tripIcon from '../../images/noun-trip-brochure-5074158-142350-cropped.svg';
import { sanitizeFlightLeg } from '../../Utilities/flightHelpers';
import FlightLegNew from '../Flights/FlightLegNew';
import uuid from 'react-uuid';
import ListItemHotel from './ListItemHotel';
import { sanitizeHotelData } from '../../Utilities/hotelHelpers';
import ListItemCar from './ListItemCar';
import { sanitizeCarData } from '../../Utilities/carHelpers';
import ListItemCustom from './ListItemCustom';

const ListItemMain = ({ itemKey, item }) => {
	const handleMain = () => {
		switch (itemKey) {
			case 'trip':
				return (
					<div className='item-list__trip'>
						<img className='trip-icon' src={tripIcon} />
						<div className='item-list__trip-details'>
							{item && item.flightDetails && item.hotelDetails.length
								? item.hotelDetails.map((detail, index) => {
										if (detail) {
											if (index > 0) {
												return (
													<Fragment key={`detial-${detail._id}`}>
														<img className='arrow' src={arrow} alt='' />
														<span className='capitalize'>
															{(detail.city &&
																detail.city &&
																detail.city.cityName.toLowerCase()) ||
																null}
														</span>
													</Fragment>
												);
											} else {
												return (
													<span className='capitalize'>
														{(detail.city &&
															detail.city &&
															detail.city.cityName.toLowerCase()) ||
															null}
													</span>
												);
											}
										}
										return null;
								  })
								: null}
						</div>
					</div>
				);
			case 'flights':
				return (
					<div className='flight-group__legs'>
						{item && item.data && item.data.legs && item.data.legs.length
							? item.data.legs.map((leg, index) => {
									const sanitizedLeg = sanitizeFlightLeg({
										leg,
										index,
										fareDetails: item.data.recommendation.fareDetails,
									});
									return (
										<FlightLegNew isListItem key={uuid()} {...sanitizedLeg} />
									);
							  })
							: null}
					</div>
				);
			case 'activities':
			case 'transport':
				return (
					<ListItemCustom isTransport={itemKey === 'transport'} {...item} />
				);
			case 'lodging':
				return <ListItemHotel {...sanitizeHotelData(item.data)} />;
			case 'amadeusCars':
				console.log('item', item);
				return <ListItemCar {...sanitizeCarData(item.data)} />;
			default:
				return null;
		}
	};
	return <main className='item-list__item-main'>{handleMain()}</main>;
};

export default ListItemMain;
