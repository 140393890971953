import React from 'react';
import { Fragment } from 'react';

const ModalImage = ({ src }) => {
	return (
		<Fragment>
			<img className='itinerary-modal-image' src={src} alt='' />
		</Fragment>
	);
};

export default ModalImage;
