import React, { useEffect, useRef } from 'react';
import SideNav from '../sidenav/SideNav';
import QueryWindowMain from './QueryWindowMain';

const QueryWindow = () => {
	const sidenavRef = useRef(null);
	useEffect(() => {
		console.log('sidnavRef', sidenavRef);
	}, [sidenavRef]);
	return (
		<div className={`create-itinerary`}>
			<SideNav sidenavRef={sidenavRef} />
			<QueryWindowMain modalXAxis={sidenavRef.current?.offsetWidth} />
		</div>
	);
};

export default QueryWindow;
