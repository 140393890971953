import React from 'react';
import { connect } from 'react-redux';
import DashboardButton from '../Layout/DashboardButton';

const Quicknav = ({
	handleClick,
	filteredTrips,
	authorizedItems,
	pendingBookings,
	pendingCurations,
}) => {
	const topButtons = [
		{
			clickArguement: 'newTrips',
			title: 'New Trips',
			newItemAmount: filteredTrips.newTrips.length,
		},
		{
			clickArguement: 'activeTrips',
			title: 'Active Trips',
			newItemAmount: filteredTrips.activeTrips.length,
		},
		{
			clickArguement: 'Archived Trips',
			title: 'Archived Trips',
		},
	];

	const middleButtons = [
		{
			clickArguement: 'activities',
			title: 'Authorized Activities',
			newItemAmount: authorizedItems.activities.length,
		},
		{
			clickArguement: 'flights',
			title: 'Authorized Flights',
			newItemAmount: authorizedItems.flights.length,
		},
		{
			clickArguement: 'lodging',
			title: 'Authorized Lodging',
			newItemAmount: filteredTrips.lodging.length,
		},
		{
			clickArguement: 'transport',
			title: 'Authorized Transport',
			newItemAmount: filteredTrips.transport.length,
		},
		{
			clickArguement: 'amadeusCars',
			title: 'Authorized Car Rental',
			newItemAmount: filteredTrips.amadeusCars.length,
		},
	];

	const bottomButtons = [
		{
			clickArguement: 'curation',
			title: 'Curation Fee',
			newItemAmount: pendingCurations.length,
		},
		{
			clickArguement: 'booking',
			title: 'Booking Fee',
			newItemAmount: pendingBookings.length,
		},
	];

	const returnButtons = (btnArray) => {
		return btnArray.map((props) => {
			return <DashboardButton handleClick={handleClick} {...props} />;
		});
	};

	return (
		<div className='quick-nav'>
			<div className='top'>{returnButtons(topButtons)}</div>
			<div className='middle'>{returnButtons(middleButtons)}</div>
			<div className='bottom'>{returnButtons(bottomButtons)}</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	pendingBookings: state.admin.pendingBookings,
	pendingCurations: state.admin.pendingCurations,
});

export default connect(mapStateToProps, null)(Quicknav);
