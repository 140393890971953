import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Redirect } from 'react-router';
import Navbar from './Components/Layout/Navbar';
import LogIn from './Components/Auth/LogIn';
import { connect } from 'react-redux';
import CreateItinerary from './Components/CreateItinerary';
import TripsDashboard from './Components/pages/TripsDashboard';
import UsersDashboard from './Components/pages/UsersDashboard';
import Destinations from './Components/pages/Destinations';
import TripProfile from './Components/pages/TripProfile';
import UserProfile from './Components/pages/UserProfile';
import ErrorBoundary from './Components/ErrorBoundary';
import ErrorPage from './Components/pages/ErrorPage';
import Unauthorized from './Components/pages/Unauthorized';
import ContentManagement from './Components/pages/ContentManagement';

function App() {
	try {
		console.log('App rerender');
		return (
			<BrowserRouter>
				<ErrorBoundary>
					<div className='App'>
						<Navbar />
						<Switch>
							<Route path='/' exact component={LogIn} />
							{/* <Route
								path={config.SignedInLinks.flights}
								component={FlightQueryContainer}
							/> */}
							<Route
								path={'/dashboard/trip'}
								exact
								component={TripsDashboard}
							/>
							<Route
								path={'/dashboard/content-management'}
								exact
								component={ContentManagement}
							/>
							<Route
								path={'/dashboard/trip/:id'}
								exact
								component={TripProfile}
							/>
							<Route
								path={'/dashboard/users'}
								exact
								component={UsersDashboard}
							/>
							<Route
								path={'/dashboard/destinations'}
								exact
								component={Destinations}
							/>
							<Route
								path={'/dashboard/user/:id/:tripId?'}
								exact
								component={UserProfile}
							/>
							<Route
								path='/create-itinerary/:id'
								exact
								component={CreateItinerary}
							/>
							<Route path='/unauthorized' exact component={Unauthorized} />
							{/* <Route
								path={config.SignedInLinks.hotels}
								component={HotelQueryContainer}
							/> */}
							{/* <Route path={config.SignedInLinks.activity} component={Activity} />
						<Route path={config.SignedInLinks.transport} component={Transport} /> */}
							{/* <Route path={config.SignedInLinks.trip} component={Trip} /> */}
							<Route render={() => <Redirect to='/' />} />
						</Switch>

						{/* <Footer /> */}
					</div>
				</ErrorBoundary>
			</BrowserRouter>
		);
	} catch (err) {
		return <ErrorPage />;
	}
}

const mapStateToProps = (state) => {
	return {
		token: state.auth.token,
	};
};

export default connect(mapStateToProps, null)(App);
