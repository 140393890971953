import React from 'react';
import { connect } from 'react-redux';
import ListItemOptions from '../listItems/ListItemOptions';
import { selectPurchaseObject } from '../../redux/Actions/authItem';
import { setCurrentForm } from '../../redux/Actions/trip';
import UserIdCopy from '../listItems/UserIdCopy';

const FeeItem = ({
	item,
	index,
	type,
	setCurrentForm,
	selectPurchaseObject,
}) => {
	const { userName } = item;
	return (
		<div className='item-list__item fee-item'>
			<button
				onClick={() => {
					setCurrentForm('authItem');
					selectPurchaseObject({ index, purchase: item, type });
				}}>
				<h3 className='capitalize'>{type} Fee</h3>
				<div className='item-list__item-type'>
					<span className='capitalize'>{type} Fee</span>
				</div>
				<div className='item-list__item-status capitalize'>Authorized</div>
				<div className='item-list__item-customer'>{userName}</div>
			</button>
			<div className='secondary-actions'>
				<UserIdCopy userId={item.userId} />
				<ListItemOptions />
			</div>
		</div>
	);
};

export default connect(null, { setCurrentForm, selectPurchaseObject })(FeeItem);
