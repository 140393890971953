import React from 'react';
import CarReview from '../Cars/CarReview';
import FlightGroup from '../Flights/FlightGroup';
import sanitizeItem from '../hoc/SanitizeItem';
import HotelReview from '../Hotels/HotelReview';
import {
	deleteItineraryItem,
	setCurrentReviewItem,
} from '../../redux/Actions/trip';
import { connect } from 'react-redux';
import AddedCustomItem from '../CustomData/Activity/AddedCustomItem';

const SideNavItemReview = ({
	activity,
	flight,
	lodging,
	transportation,
	amadeusCars,
	sanitizedData,
	setCurrentReviewItem,
	className,
	itemIndex,
	deleteItineraryItem,
	itemInfo,
}) => {
	const clearReviewItem = () => setCurrentReviewItem(null);
	const deleteReviewItem = (key) => {
		deleteItineraryItem(key, itemIndex);
		clearReviewItem();
	};
	const handleComponent = () => {
		if (flight) {
			return (
				<FlightGroup
					deleteReviewItem={() => deleteReviewItem('flights')}
					clearReviewItem={clearReviewItem}
					itemIndex={itemIndex}
					isSelected={true}
					{...sanitizedData}
				/>
			);
		}
		if (activity || transportation) {
			return (
				<AddedCustomItem
					item={itemInfo}
					itemIndex={itemIndex}
					isTransport={transportation}
					isActivity={activity}
				/>
			);
		}
		if (amadeusCars) {
			return (
				<CarReview
					rate={itemInfo.data}
					deleteReviewItem={() => deleteReviewItem('amadeusCars')}
					itemIndex={itemIndex}
					clearReviewItem={clearReviewItem}
					isSelected={true}
					{...sanitizedData}
				/>
			);
		}
		if (lodging) {
			return (
				<HotelReview
					data={itemInfo.data}
					customUrl={itemInfo.customUrl}
					deleteReviewItem={() => deleteReviewItem('lodging')}
					itemIndex={itemIndex}
					clearReviewItem={clearReviewItem}
					isSelected={true}
					{...sanitizedData}
				/>
			);
		}
	};

	return (
		<div className={`sidenav__item-review ${className}`}>
			<button onClick={clearReviewItem} className='close'>
				+
			</button>
			{handleComponent()}
		</div>
	);
};

export default connect(null, { setCurrentReviewItem, deleteItineraryItem })(
	sanitizeItem(SideNavItemReview)
);
