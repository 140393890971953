import React, { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import FlightQuery from '../Queries/FlightQuery';
import HotelQueryFormDisplay from '../Queries/HotelQueryFormDisplay';
import CarQuery from '../Queries/CarQuery';
import ActivityForm from '../CustomData/Activity/ActivityForm';
import TransportForm from '../CustomData/Transport/TransportForm';
import { connect } from 'react-redux';
import SideNavItemReview from '../sidenav/SideNavItemReview';
import uuid from 'react-uuid';
import TripImageInput from '../CustomData/TripImageInput';
import AuthItemModal from '../authorizedItems/AuthItemModal';
import DestinationForm from '../destinations/DestinationForm';
import TestimonialForm from '../TestimonialForm';

const FormModal = ({ currentForm, currentItemReview, xAxis, updates }) => {
	const forms = {
		flight: FlightQuery,
		hotel: HotelQueryFormDisplay,
		carRental: CarQuery,
		activity: ActivityForm,
		transportation: TransportForm,
		authItem: AuthItemModal,
		destination: DestinationForm,
		photoUrl: TripImageInput,
		newTestimonial: TestimonialForm,
		testimonialGroup: TestimonialForm,
		testimonial: TestimonialForm,
	};

	const handleDisplay = () => {
		if (currentForm) {
			const Form = forms[currentForm];
			return <Form updates={updates} />;
		} else if (currentItemReview) {
			return (
				<SideNavItemReview
					itemIndex={currentItemReview.itemIndex}
					itemInfo={currentItemReview.itemInfo}
				/>
			);
		} else {
			return null;
		}
	};

	return (
		<AnimatePresence initial={false} exitBeforeEnter={true} mode='wait'>
			{currentForm || currentItemReview ? (
				<motion.div
					key={uuid()}
					style={{
						position: 'fixed',
						height: '100%',
						zIndex: 100,
					}}
					initial={{ top: '-100vh', left: xAxis || 0 }}
					animate={{ top: 0, left: xAxis || 0 }}
					exit={{ top: '-100vh', left: xAxis || 0 }}>
					{handleDisplay()}
				</motion.div>
			) : null}
		</AnimatePresence>
	);
};

const mapStateToProps = (state) => ({
	currentForm: state.trip.currentForm,
	currentItemReview: state.trip.currentItemReview,
});

export default connect(mapStateToProps, null)(FormModal);
