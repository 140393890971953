import React from 'react';
import noImage from '../../../../images/noun_Camera_prohibited_1554484.svg';

const SegmentTitle = ({ date, direction, image }) => {
	return (
		<div className='segment__title'>
			<img
				src={image}
				alt=''
				onError={({ currentTarget }) => {
					currentTarget.onerror = null; // prevents looping
					currentTarget.src = noImage;
				}}
			/>
			<h3>
				{direction} Flight | {date}
			</h3>
		</div>
	);
};

export default SegmentTitle;
