import React from 'react';

import moment from 'moment';
import { editTripField } from '../../redux/Actions/trip';
import { connect } from 'react-redux';
import CloseSection from './CloseSection';

const FlightQueryForm = ({
	handleSubmit,
	returnTimeLocations,
	roundTrip,
	returnDate,
	cabin,
	adults,
	children,
	infants,
	formRef,
	editTripField,
	formPosition,
}) => {
	const returnCabinItems = () => {
		return cabin && cabin.length
			? cabin.map((item, index) => (
					<option key={`cabin-${index}`} id='cabin' value={index}>
						{item}
					</option>
			  ))
			: null;
	};
	return (
		<div
			style={formPosition}
			ref={formRef}
			className='itinerary-form__container'>
			<CloseSection />
			<form className={`flight-form`} onSubmit={handleSubmit}>
				<div className='row'>{returnTimeLocations()}</div>
				{roundTrip && (
					<div className='row'>
						<div className='flex-section'>
							<div className='text-field'>
								<label htmlFor='returnDate'>Return Date:</label>
								<input
									onChange={(e) => editTripField(e.target.name, e.target.value)}
									type='date'
									name='returnDate'
									value={moment(returnDate.slice(0, 10)).format('YYYY-MM-DD')}
								/>
							</div>
						</div>
					</div>
				)}
				<div className='text-field'>
					<label className='cabin' htmlFor='cabin'>
						Travel Class:
					</label>
					<select
						style={{ display: 'block', width: '100%' }}
						name='cabin'
						id='cabin'
						defaultValue={cabin[0]}
						className='col s4 select-field'>
						{returnCabinItems()}
					</select>
				</div>
				<div className='flex-section'>
					<div className='text-field'>
						<label htmlFor='adults'>Adults:</label>
						<input
							onChange={(e) =>
								editTripField(e.target.name, parseInt(e.target.value))
							}
							name='adults'
							type='number'
							className=''
							value={adults}
						/>
					</div>
					<div className='text-field'>
						<label htmlFor='children'>Children:</label>
						<input
							onChange={(e) =>
								editTripField(e.target.name, parseInt(e.target.value))
							}
							name='children'
							type='number'
							className=''
							value={children}
						/>
					</div>
					<div className='text-field'>
						<label htmlFor='infants'>Infants:</label>
						<input name='infants' type='text' className='' value={infants} />
					</div>
				</div>

				<div className='button-section'>
					<div></div>
					<button className='cta-button ' type='submit' name='action'>
						Submit
					</button>
				</div>
			</form>
		</div>
	);
};

export default connect(null, { editTripField })(FlightQueryForm);
