import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
	autofillOptions,
	autofillEmpty,
} from '../../redux/Actions/autofillActions';
import {
	clearFlightQuery,
	fetchFlights,
} from '../../redux/Actions/flightActions';
import FlightQueryForm from './FlightQueryForm';
import { useState } from 'react';
import { editFlightDetail, setCurrentForm } from '../../redux/Actions/trip';
import { returnTrueDate } from '../Utilities/returnTrueDate';
import ErrMessage from '../Layout/ErrMessage';

const FlightQuery = ({
	fetchFlights,
	index,
	endIndex,
	adults,
	children,
	setCurrentIndex,
	flightDetails,
	returnDate,
	clearFlightQuery,
	setCurrentForm,
	editFlightDetail,
	formRef,
	formPosition,
}) => {
	const cabin = ['ECONOMY', 'PREMIUM_ECONOMY', 'BUSINESS', 'FIRST'];

	const roundTrip = flightDetails.length === 1 ? true : false;

	const [formData, setFormData] = useState({
		flightDetails,
		adults,
		children,
		returnDate,
	});

	const handleChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const trimDate = (date) => {
			const formattedDate = date.slice(0, 10);
			const splitDate = formattedDate.split('-');
			splitDate[0] = splitDate[0].slice(2);
			let newDateArray = [splitDate[2], splitDate[1], splitDate[0]];
			const joinedDate = newDateArray.join('');
			return joinedDate;
		};
		const itineraries = flightDetails.map((detail, index) => {
			return {
				time: '0000',
				date: trimDate(detail.departureDate),
				departure_city: detail.departure.toUpperCase(),
				arrival_city: detail.destination.toUpperCase(),
			};
		});
		if (roundTrip) {
			itineraries.push({
				time: '0000',
				date: trimDate(returnDate),
				departure_city: flightDetails[0].destination.toUpperCase(),
				arrival_city: flightDetails[0].departure.toUpperCase(),
			});
		}
		let body = {
			num_seats: adults + children,
			itineraries,
			passengers: {
				adults: adults,
				children: children,
			},
		};
		fetchFlights(body);
		setCurrentForm('');
	};

	const returnTimeLocations = () => {
		return flightDetails && flightDetails.length
			? flightDetails.map((detail, index) => {
					return (
						<div className='flex-section' key={`${detail._id}-detail-time`}>
							<div className='text-field'>
								<label>Departure:</label>
								<input
									onChange={(e) =>
										editFlightDetail(e.target.name, e.target.value, index)
									}
									value={detail.departure}
									type='text'
									id='originLocationCode'
									name='departure'
									className=''
								/>
							</div>
							<div className='text-field'>
								<label>Destination:</label>
								<input
									onChange={(e) =>
										editFlightDetail(e.target.name, e.target.value, index)
									}
									value={detail.destination}
									type='text'
									id='destinationLocationCode'
									name='destination'
									className=''
								/>
							</div>
							<div className='text-field'>
								<label htmlFor='departureDate'>Departure Date:</label>
								<input
									onChange={(e) =>
										editFlightDetail(e.target.name, e.target.value, index)
									}
									type='date'
									name='departureDate'
									id='departureDate'
									value={moment(detail.departureDate.slice(0, 10)).format(
										'YYYY-MM-DD'
									)}
								/>
								{index === 0 &&
								returnTrueDate(detail.departureDate.slice(0, 10)) <
									new Date() ? (
									<ErrMessage text='Please select a valid date from the drop down menu' />
								) : null}
								{index > 0 &&
								returnTrueDate(detail.departureDate.slice(0, 10)) <
									returnTrueDate(
										flightDetails[index - 1].departureDate.slice(0, 10)
									) ? (
									<ErrMessage text='Please select a valid date from the drop down menu' />
								) : null}
							</div>
						</div>
					);
			  })
			: null;
	};

	return (
		<FlightQueryForm
			formPosition={formPosition}
			formRef={formRef}
			handleSubmit={handleSubmit}
			returnTimeLocations={returnTimeLocations}
			roundTrip={roundTrip}
			returnDate={returnDate}
			handleChange={handleChange}
			cabin={cabin}
			adults={adults}
			children={children}
			setCurrentIndex={setCurrentIndex}
			clearFlightQuery={clearFlightQuery}
			formData={formData}
			index={index}
			endIndex={endIndex}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		autofill: state.autofill.autofill,
		selectedTrip: state.admin.selectedTrip,
		token: state.auth.token,
		profile: state.profile.profile,
		adults: state.trip.adults,
		children: state.trip.children,
		returnDate: state.trip.returnDate,
		flightDetails: state.trip.flightDetails,
	};
};

export default connect(mapStateToProps, {
	editFlightDetail,
	clearFlightQuery,
	fetchFlights,
	autofillOptions,
	autofillEmpty,
	setCurrentForm,
})(FlightQuery);
