import authReducer from './authReducer';
import profileReducer from './profileReducer';
import { combineReducers } from 'redux';
import autofillReducer from './autofillReducer';
import adminReducer from './adminReducer';
import flightReducer from './flightReducer';
import hotelReducer from './hotelReducer';
import redirectReducer from './redirectReducer';
import selectedItemMapsReducer from './selectedItemMaps';
import trip from './trip';
import authItem from './authItem';
import appErrors from './appErrors';
import customItemForm from './customItemForm';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import carReducer from './carReducer';
const persistConfig = {
	key: 'root',
	storage,
	whitelist: [
		// 'admin',
		// 'profile',
		'auth',
		//  'flight',
		//   'cars',
		// 	'trip',
		// 'hotel',
	],
	blacklist: [
		'profile',
		'flight',
		'cars',
		'trip',
		//  'hotel'
	],
};
const appReducer = combineReducers({
	auth: authReducer,
	autofill: autofillReducer,
	flight: flightReducer,
	hotel: hotelReducer,
	cars: carReducer,
	profile: profileReducer,
	admin: adminReducer,
	redirect: redirectReducer,
	trip: trip,
	authItem: authItem,
	appErrors: appErrors,
	customItemForm,
	selectedItemMaps: selectedItemMapsReducer,
});

const rootReducer = (state, action) => {
	if (action.type === 'LOGOUT_SUCCESS') {
		state = undefined;
	}

	return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
