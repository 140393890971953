import React, { useState } from 'react';
import { Fragment } from 'react';
import moment from 'moment';
import ActivityForm from './ActivityForm';
import { connect } from 'react-redux';
import noImage from '../../../images/noun_Camera_prohibited_1554484.svg';
import { deleteItineraryItem } from '../../../redux/Actions/trip';
import TransportForm from '../Transport/TransportForm';
import CustomItemReviewField from '../CustomItemReviewField';
import { toDollarStyle } from '../../../Utilities/Utils';

const AddedCustomItem = ({ item, index, isTransport }) => {
	const [edit, setEdit] = useState(false);
	const {
		name,
		cityName,
		dateRange,
		price,
		imageUrl,
		type,
		description,
		url,
		startLocation,
		endLocation,
		dayOptions,
		timeOptions,
		hotelPickup,
		groupCapacity,
		adminNotes,
	} = item;

	const excludedDays = [];
	for (let key in dayOptions) {
		if (!dayOptions[key]) excludedDays.push(key);
	}

	const returnTimeOptions = () => {
		return timeOptions && timeOptions.length
			? timeOptions.map((option, index) => {
					return ((index + 1) % 6 === 0 && index > 0) ||
						(index === timeOptions.length - 1 && timeOptions.length < 6) ? (
						<span
							key={`time-option-item-${index}`}
							className='data'
							style={{ width: '16.666%' }}>
							{option}
						</span>
					) : (
						<span
							key={`time-option-item-${index}`}
							className='data'
							style={{
								borderRight: '1px solid black',
								width: '16.666%',
							}}>
							{option}
						</span>
					);
			  })
			: null;
	};

	return (
		<Fragment>
			{edit ? (
				<>
					{isTransport ? (
						<TransportForm
							item={item}
							index={index}
							setEdit={() => setEdit(false)}
						/>
					) : (
						<ActivityForm
							index={index}
							activity={item}
							setEdit={() => setEdit(false)}
						/>
					)}
				</>
			) : (
				<div className='activity-review'>
					<section className='activity-review__top'>
						<img
							src={imageUrl}
							alt=''
							onError={({ currentTarget }) => {
								currentTarget.onerror = null; // prevents looping
								currentTarget.src = noImage;
							}}
						/>
						<div className='quick-info'>
							<div className='quick-info__main'>
								<h2>{name}</h2>
								<p className='capitalize item-type'>{type.replace('-', ' ')}</p>
								<button
									// href={url}
									onClick={() => window.open('https://' + url, '_blank')}
									className='itinerary-item-url'
									target='_blank'
									rel='noopener noreferrer'>
									website
								</button>
							</div>
							<p className='capitalize price'>
								{`Price Per ${type === 'group-activity' ? 'Group' : 'Person'}`}/
								<br />
								{toDollarStyle(price.amount, price.currencyCode)}
							</p>
						</div>
					</section>
					{isTransport ? (
						<>
							<CustomItemReviewField
								title='Start Location'
								value={startLocation}
							/>
							<CustomItemReviewField title='End Location' value={endLocation} />
						</>
					) : (
						<CustomItemReviewField title='Location' value={cityName} />
					)}

					{hotelPickup && (
						<CustomItemReviewField
							title='Hotel Pickup Included?'
							value={hotelPickup ? 'Yes' : 'No'}
						/>
					)}
					{type === 'group-activity' && (
						<CustomItemReviewField
							title='Group Capacity'
							value={groupCapacity}
						/>
					)}

					<div className='description'>
						<label>Description:</label>
						<p>{description}</p>
					</div>
					<div className='date-range'>
						<label>Date Range</label>
						<p>
							{`${moment(dateRange.start).format('LL')} to ${moment(
								dateRange.end
							).format('LL')}`}
						</p>
					</div>

					<div className='time-options'>
						<label> Time Options: </label>
						<div className='time-options__main'>{returnTimeOptions()} </div>
					</div>
					<div className='time-options'>
						<label>Days Excluded:</label>
						<div className='time-options__main'>
							{excludedDays && excludedDays.length ? (
								excludedDays.map((day, index) =>
									((index + 1) % 4 === 0 && index > 0) ||
									(index === excludedDays.length - 1 &&
										excludedDays.length < 4) ? (
										<span
											key={`excluded-day-${index}`}
											style={{ width: '25%' }}
											className='data'>
											{day}
										</span>
									) : (
										<span
											key={`excluded-day-${index}`}
											className='data'
											style={{
												borderRight: '1px solid black',
												width: '25%',
											}}>
											{day}
										</span>
									)
								)
							) : (
								<p>None excluded</p>
							)}{' '}
						</div>
					</div>

					{adminNotes && (
						<div>
							<label>Admin Notes:</label>
							<p className='data'>{adminNotes}</p>
						</div>
					)}
				</div>
			)}
		</Fragment>
	);
};

export default connect(null, { deleteItineraryItem })(AddedCustomItem);
