import { checkType } from '../../Utilities/helperFunctions';
import React, { Fragment } from 'react';
// import LodgingReview from './LodgingReview';
import ConfirmationForm from './ConfirmationForm';
import CustomItemReview from './CustomItemReview';
import CCForm from './CCForm';
import CarReview from '../Cars/CarReview';
import sanitizeItem from '../hoc/SanitizeItem';
import FlightGroup from '../Flights/FlightGroup';
import HotelReview from '../Hotels/HotelReview';

const AuthItem = ({
	item,
	ccData,
	formData,
	handleInput,
	handleChange,
	sanitizedData,
	itemInfo,
}) => {
	const { lodging, flight, amadeusCars, booking, curation } = checkType(
		item && item.type
	);

	let { trip, user } = item;
	user = (trip && trip.user) || user;

	const handleConfirmationForm = () => {
		return (
			!lodging && (
				<ConfirmationForm
					user={user}
					flight={flight}
					formData={formData}
					secondaryTravelers={item.trip?.secondaryTravelers}
					handleInput={handleInput}
				/>
			)
		);
	};

	const handleDisplay = () => {
		if (lodging) {
			return (
				<Fragment>
					<HotelReview
						data={itemInfo.data}
						checkLiveSearch={true}
						prevItemId={itemInfo._id}
						{...sanitizedData}
					/>

					<CCForm ccData={ccData} handleChange={handleChange} />
				</Fragment>
			);
		} else if (flight) {
			return (
				<FlightGroup
					isSelected={true}
					checkLiveSearch={true}
					{...sanitizedData}
				/>
			);
		} else if (amadeusCars) {
			return (
				<CarReview
					authorization={true}
					itemId={itemInfo._id}
					rate={itemInfo.data}
					isSelected={true}
					{...sanitizedData}
				/>
			);
		} else if (booking || curation) {
			return (
				<Fragment>
					<h3
						style={{
							fontSize: '1.6rem',
							width: '15rem',
							textAlign: 'center',
							margin: '3rem auto',
						}}>
						Pay for {item && item.type}
					</h3>
				</Fragment>
			);
		} else {
			return <CustomItemReview item={item} />;
		}
	};

	return (
		<>
			{handleDisplay()}
			{handleConfirmationForm()}
		</>
	);
};

export default sanitizeItem(AuthItem);
