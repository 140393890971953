export const autofillOptions = (res) => {
	return (dispatch) => {
		let { payload, bool } = res;
		if (!!bool === true) {
			dispatch({ type: 'AUTOFILL_OPTIONS_SUCCESS', payload });
			dispatch({ type: 'LOADING_SWITCH', bool: false });
		} else {
			dispatch({ type: 'AUTOFILL_OPTIONS_ERROR' });
		}
	};
};
export const autofillEmpty = () => {
	return (dispatch) => {
		dispatch({ type: 'AUTOFILL_EMPTY' });
	};
};
