const config = {
	//LINKS //////////////////
	SignedInLinks: {
		itinerary: '/itinerary/',
		Itinerary: 'View this Trip!',
		purchase: '/purchase/',
		Purchase: 'Start your trip!',
		Trip: 'Trip',
		createTrip: '/create-trip/',
		CreateTrip: 'Create Trip',
		Logout: 'Log Out',
		profile: '/profile/',
		userinfo: '/userinfo/',
		tripDash: '/dashboard/trip',
		userDash: '/dashboard/users',
		Flights: 'Flights',
		flights: '/flights/',
		hotels: '/hotels/',
		Hotels: 'Hotels',
		activity: '/activity/',
		Activity: 'Activity',
		transport: '/transport/',
		Transport: 'Transport',
		trip: '/trip/',
	},
	SignedOutLinks: {
		signup: '/signup/',
		Signup: 'Sign Up',
		signIn: '/sign-in/',
		Login: 'Log In',
	},
};
export default config;
