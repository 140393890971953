const initState = {
	flights: [],
	itineraryFlights: [],
	flightsDictionary: [],
	selectedFlight: null,
	loading: false,
};

const flightReducer = (state = initState, action) => {
	switch (action.type) {
		case 'FETCH_FLIGHTS_SUCCESS':
			return {
				...state,
				flights: action.payload,
				loading: false,
			};
		case 'FETCH_FLIGHTS_ERROR':
			return state;
		case 'FILTER_FLIGHT_REC':
			return {
				...state,
				flights: {
					...state.flights,
					recommendations: state.flights.recommendations.filter(
						(item, index) => {
							console.log('index !== action.payload', index !== action.payload);
							return index !== action.payload;
						}
					),
				},
			};
		case 'SET_FLIGHTS_LOADING':
			return {
				...state,
				loading: true,
			};
		case 'SET_ITINERARY_FLIGHTS':
			return {
				...state,
				itineraryFlights: action.payload,
			};
		case 'CLEAR_FLIGHT_QUERY':
			return {
				flights: [],
				flightsDictionary: [],
				selectedFlight: null,
				loading: false,
			};

		default:
			return state;
	}
};

export default flightReducer;
