import React from 'react';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import LodgingDates from './LodgingDates';
import Guarantee from './Guarantee';
import CancelPenalties from './CancelPenalties';
import {
	returnItemFields,
	returnRatePlanData,
} from '../../Utilities/helperFunctions';

const ItemOptions = ({ item }) => {
	const returnRoomRateDescription = () => {
		const { Text: descriptionText } = returnItemFields(item, ['Text']);

		return (
			<Fragment>
				<label>Room Description:</label>
				<ul>
					{descriptionText.map((item, index) => (
						<li key={`description-text-item-${index}`}>- {item._}</li>
					))}
				</ul>
			</Fragment>
		);
	};

	const returnLodgingInfo = () => {
		const { guaranteeType, guaranteesAccepted, cancelPenalties } =
			returnRatePlanData(item);
		return item ? (
			<Fragment>
				{returnRoomRateDescription()}
				{cancelPenalties && (
					<CancelPenalties
						cancelPenalties={cancelPenalties}
						itemId={item._id}
					/>
				)}
				<Guarantee
					guaranteeType={guaranteeType}
					guaranteesAccepted={guaranteesAccepted}
				/>
				<LodgingDates
					roomRates={item['RoomRates']}
					descriptive_info={item.descriptiveInfo}
				/>
			</Fragment>
		) : null;
	};

	return item ? (
		<Fragment>
			<section className='options-section'>{returnLodgingInfo()}</section>
		</Fragment>
	) : null;
};

const mapStateToProps = (state) => ({
	descriptiveInfo: state.hotel.descriptiveInfo,
});

export default connect(mapStateToProps, null)(ItemOptions);
