import React, { useEffect, useState } from 'react';

import {
	returnItemFields,
	returnRoomStayLength,
} from '../../Utilities/helperFunctions';
import RoomStays from './RoomStays';
import moment from 'moment';
import HotelSummaryNew from './HotelSummaryNew';
import { sanitizeDescriptiveInfo } from '../../Utilities/hotelHelpers';

const Hotel1 = ({
	roomStays,
	hotelDetail,
	thisDescriptiveInfo,
	hotelIndex,
	hotelInfo,
	customUrl,
}) => {
	const [sortedRoomStays, setSortedRoomStays] = useState(null);
	const [showRoomStays, setShowRoomStays] = useState(false);

	let { startDate, endDate } = hotelDetail;

	startDate = startDate && moment(new Date(startDate.slice(0, 10)));
	endDate = endDate && moment(new Date(endDate.slice(0, 10)));

	const hotelDays =
		startDate && endDate ? endDate.diff(startDate, 'days') : null;

	const extractTotal = (roomStay) => {
		const { AmountAfterTax } = returnItemFields(roomStay, ['AmountAfterTax']);
		return AmountAfterTax;
	};

	useEffect(() => {
		setSortedRoomStays(
			roomStays
				.filter((item) => {
					return returnRoomStayLength(item) === hotelDays;
				})
				.sort(
					(a, b) => parseFloat(extractTotal(a)) - parseFloat(extractTotal(b))
				)
		);
	}, []);
	return (
		<div className='hotel__container'>
			<HotelSummaryNew
				showRoomStays={showRoomStays}
				customUrl={customUrl}
				sortedRoomStays={sortedRoomStays}
				setShowRoomStays={setShowRoomStays}
				{...sanitizeDescriptiveInfo(thisDescriptiveInfo, hotelInfo)}
				hotelCode={hotelInfo.info['HotelCode']}
			/>

			{showRoomStays && sortedRoomStays && sortedRoomStays.length ? (
				<RoomStays
					customUrl={customUrl}
					descriptive_info={thisDescriptiveInfo}
					hotelIndex={hotelIndex}
					roomStays={sortedRoomStays}
					hotelCode={hotelInfo.info['HotelCode']}
				/>
			) : null}
		</div>
	);
};

export default Hotel1;
