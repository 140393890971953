import { post } from '../../Utilities/api';
import { setSelectedTrip } from './adminActions';
import { setCurrentSearch } from './trip';

export const fetchHotels =
	({ body, hotelDetail }) =>
	async (dispatch) => {
		await dispatch(setHotelLoading(true));
		dispatch(setHotelDetail(hotelDetail));
		dispatch(setCurrentSearch('hotel'));
		try {
			const authRequired = true;
			const res = await post(
				'/api/v1/amadeus_test/search_cached_hotels',
				body,
				authRequired
			).catch((err) => {
				throw new Error(err.message);
			});
			if (res.data && res.data.success) {
				const descriptiveMap = {};
				res.data.descriptive_info &&
					res.data.descriptive_info.length &&
					res.data.descriptive_info.forEach((item) => {
						descriptiveMap[item['info']['HotelCode']] = item;
					});
				dispatch({
					type: 'SET_HOTELS',
					payload: {
						more_indicator: res.data.data.more_indicator,
						hotels: res.data.data.hotels,
						descriptiveInfo: descriptiveMap,
					},
				});
			}
		} catch (err) {
			throw new Error(err.message);
		}
	};

export const addHotelUrl = (hotelCode, url) => (dispatch) => {
	dispatch({
		type: 'ADD_HOTEL_URL',
		payload: { hotelCode, url },
	});
};

export const setLiveHotelItem = (payload) => async (dispatch) => {
	dispatch(setHotelLoading(true));
	try {
		const authRequired = true;
		const res = await post(
			'/api/v1/amadeus_test/check_hotel_live',
			payload,
			authRequired
		).catch((err) => {
			throw new Error(err.message);
		});
		if (res.data && res.data.hotel_live) {
			dispatch({
				type: 'SET_LIVE_HOTEL_ITEM',
				payload: res.data.hotel_live,
			});
		}
		dispatch(setHotelLoading(false));
	} catch (error) {
		dispatch({
			type: 'SET_HOTEL_ERROR',
			payload: error,
		});
		console.log(error);
	}
};

export const closeLiveHotelSearch = () => async (dispatch) => {
	await dispatch(setHotelLoading(true));
	try {
		const authRequired = true;
		const res = await post(
			'/api/v1/amadeus_test/close_session_wo_purchase',
			{},
			authRequired
		).catch((err) => {
			throw new Error(err.message);
		});
		if (res.data && res.data.success) {
			dispatch({
				type: 'CLEAR_LIVE_HOTEL_ITEM',
			});
		}
	} catch (error) {
		console.log(error);
	}
};

export const clearHotels = () => (dispatch) => {
	dispatch({
		type: 'CLEAR_HOTELS',
	});
};

export const setHotelDetail = (payload) => (dispatch) => {
	dispatch({
		type: 'SET_HOTEL_DETAIL',
		payload,
	});
};

export const setHotelLoading = (payload) => (dispatch) => {
	dispatch({
		type: 'SET_HOTEL_LOADING',
		payload,
	});
};

export const setBookingOptions = (payload) => (dispatch) => {
	dispatch({
		type: 'SET_BOOKING_OPTIONS',
		payload,
	});
};

export const statelessHotelSearch = (trip_id) => async (dispatch) => {
	try {
		const authRequired = true;
		const res = await post(
			'/api/v1/amadeus_test/get_top_10_available',
			{ trip_id },
			authRequired
		).catch((err) => {
			throw new Error(err.message);
		});
		if (res.data && res.data.success) {
			dispatch(setSelectedTrip(res.data.trip));
			const stringified = JSON.stringify({
				bookingOptions: res.data.bookingOptions,
			});
			localStorage.setItem(`hotelCache${res.data.trip._id}`, stringified);
			localStorage.setItem(
				`lastHotelSearch${res.data.trip._id}`,
				JSON.stringify(new Date())
			);
			dispatch({
				type: 'STATELESS_HOTEL_SEARCH',
				payload: {
					bookingOptions: res.data.bookingOptions,
				},
			});
		}
	} catch (err) {
		console.log(err.message);
	}
};
