import React, { Fragment, useEffect } from 'react';
import TripInfo from '../tripProfile/TripInfo';
import TripItemDash from '../tripProfile/TripItemDash';
import { connect } from 'react-redux';
import { getTrip } from '../../redux/Actions/trip';
import requireAuth from '../hoc/AuthComponent';
import redirectHOC from '../hoc/RedirectHOC';

const TripProfile = ({ _id, match, getTrip }) => {
	useEffect(() => {
		console.log('match.params', match.params);
		getTrip(match.params.id);
	});
	return (
		<div className='trip-profile__main'>
			{_id ? (
				<Fragment>
					<TripInfo />
					<TripItemDash />
				</Fragment>
			) : null}
		</div>
	);
};

const mapStateToProps = (state) => ({
	_id: state.trip._id,
});

export default connect(mapStateToProps, { getTrip })(
	redirectHOC(requireAuth(TripProfile))
);
