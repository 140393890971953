import React, { Fragment } from 'react';
import { returnItemFields } from '../../Utilities/helperFunctions';
import Hotel1 from './Hotel1';

const SortedHotels = ({
	lowestOptionPerHotel,
	hotels,
	hotelDetail,
	descriptiveInfo,
	bookingMap,
	min,
	max,
}) => {
	const returnSort = () => {
		let results = [];
		let lowestValues = Object.values(lowestOptionPerHotel);
		console.log('lowestValues', lowestValues);

		lowestValues = lowestValues.filter((item) => {
			console.log('item.roomRateTotal', item.roomRateTotal);
			const parsedPrice = parseFloat(item.roomRateTotal.slice(1));

			return (
				(min ? parsedPrice > min : true) && (max ? parsedPrice < max : true)
			);
		});

		lowestValues = lowestValues.sort(
			(a, b) => a.roomRateTotal - b.roomRateTotal
		);

		results = lowestValues.map((item) => {
			return hotels[item.index];
		});

		return results;
	};

	return hotels
		? returnSort().map((data) => {
				const { HotelCode } = returnItemFields(data, ['HotelCode']);
				const index = data.index;
				const thisDescriptiveInfo =
					HotelCode && descriptiveInfo && descriptiveInfo[HotelCode];

				return (
					<Fragment key={`hotel-${HotelCode}`}>
						<Hotel1
							hotelKey={`hotel-${HotelCode}`}
							customUrl={data.customUrl}
							hotelIndex={index}
							bookingMap={bookingMap}
							thisDescriptiveInfo={thisDescriptiveInfo}
							hotelInfo={data.hotel_info}
							hotelDetail={hotelDetail}
							hotel={data.hotel_info}
							roomStays={data.hotel_room_stays}
						/>
					</Fragment>
				);
		  })
		: null;
};

export default SortedHotels;
