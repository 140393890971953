import { post } from '../../Utilities/api';
import { setCurrentSearch } from './trip';

export const searchCars = (car_sell_data) => async (dispatch) => {
	dispatch(setCarLoading());
	dispatch(setCurrentSearch('carRental'));
	const res = await post(
		'/api/v1/amadeus_test/search_cars',
		{ car_sell_data },
		true
	);
	if (res.data.success) {
		dispatch({
			type: 'SET_CARS',
			payload: res.data.response.companies,
		});
	}
};

export const setCarLoading = () => (dispatch) => {
	dispatch({
		type: 'SET_CARS_LOADING',
	});
};
