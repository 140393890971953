import React, { useEffect, useState } from 'react';
import CMDestinations from '../contentManagement/CMDestinations';
import { get } from '../../Utilities/api';
import FormModal from '../Modal/FormModal';
import CMTestimonials from '../contentManagement/CMTestimonials';
import { setCurrentForm } from '../../redux/Actions/trip';
import { connect } from 'react-redux';

const ContentManagement = ({ setCurrentForm }) => {
	const [window, setWindow] = useState('suggested');
	const [currentList, setCurrentList] = useState([]);

	const setSuggested = async () => {
		setCurrentList([]);
		setWindow(null);
		try {
			const res = await get(
				'/api/v1/data/private/suggested-destination/all',
				{},
				true
			);
			if (res.status === 200) {
				setWindow('suggested');
				setCurrentList(res.data.allDestinations);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const setTestimonial = async () => {
		setCurrentList([]);
		setWindow(null);
		try {
			const res = await get('/api/v1/data/testimonial/all', {}, true);
			if (res.status === 200) {
				setCurrentList(res.data.foundGroups);
				setWindow('testimonials');
			}
		} catch (error) {
			console.log(error);
		}
	};
	const handleWindow = () => {
		switch (window) {
			case 'suggested':
				return <CMDestinations currentList={currentList} window={window} />;

			case 'testimonials':
				return (
					<CMTestimonials
						currentList={currentList}
						setCurrentList={setCurrentList}
						window={window}
					/>
				);
			default:
				return null;
		}
	};
	return (
		<div className='dashboard'>
			<FormModal
				xAxis={0}
				updates={() => {
					if ('testimonials') {
						setTestimonial();
					} else {
						setSuggested();
					}
				}}
			/>
			<nav className='content__subnav'>
				<button onClick={setSuggested} className='content_subnav--button'>
					Suggested Destinations
				</button>
				<button onClick={setTestimonial} className='content_subnav--button'>
					Testimonials
				</button>
			</nav>
			<button
				className='content-form__button-container'
				onClick={() =>
					setCurrentForm(
						window === 'testimonials' ? 'newTestimonial' : 'destination'
					)
				}>
				Add {window === 'testimonials' ? 'Testimonial' : 'Destination'}
			</button>
			{handleWindow()}
		</div>
	);
};

export default connect(null, { setCurrentForm })(ContentManagement);
