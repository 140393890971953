import React from 'react';
import moment from 'moment/moment';
import getSymbolFromCurrency from 'currency-symbol-map';
import { returnItemFields } from '../../Utilities/helperFunctions';
import { Fragment } from 'react';
import HotelPrice from './HotelPrice';
import { connect } from 'react-redux';
import { addItineraryItem } from '../../redux/Actions/trip';

const RoomSummaryNew = (props) => {
	const {
		roomTypeCode,
		descriptionText,
		ratePlanCode,
		cancelPenalties,
		guaranteeType,
		rates,
		policy,
		checkinTime,
		checkoutTime,
		total,
		conversionTotal,
		commission,
		deleteReviewItem,
		addItineraryItem,
		isSelected,
		newData,
		setSelected,
	} = props;

	const returnDateFormat = (date) => moment(new Date(date)).format('LLLL');
	const returnDeadline = (deadline) => {
		return (
			deadline && (
				<>
					<span>
						- Cancellations need to be made by {returnDateFormat(deadline)}{' '}
						local time
					</span>
				</>
			)
		);
	};

	const returnAmountCharged = (amountCharged, currencyCode, deadline) =>
		amountCharged && (
			<>
				<span>
					- You will be charged {getSymbolFromCurrency(currencyCode)}
					{amountCharged} if cancellation is made after{' '}
					{returnDateFormat(deadline)}
				</span>
			</>
		);

	const returnPercentCharged = (percentCharged, deadline) =>
		percentCharged && (
			<>
				<p>
					- You will be charged {percentCharged}% of total cost if cancellation
					is made after {returnDateFormat(deadline)}
				</p>
			</>
		);

	const destructurePenalties = () => {
		return cancelPenalties?.map((penalty, index) => {
			// const { info } = penalty;
			const { AbsoluteDeadline, Amount, Percent, CurrencyCode, NonRefundable } =
				returnItemFields(penalty, [
					'AbsoluteDeadline',
					'Amount',
					'Percent',
					'CurrencyCode',
					'NonRefundable',
				]);
			return NonRefundable !== 'true' ? (
				<Fragment key={`cancel-penalty-item-${index}`}>
					{AbsoluteDeadline && returnDeadline(AbsoluteDeadline)}
					{Amount && CurrencyCode && AbsoluteDeadline
						? returnAmountCharged(Amount, CurrencyCode, AbsoluteDeadline)
						: null}
					{Percent && AbsoluteDeadline
						? returnPercentCharged(Percent, AbsoluteDeadline)
						: null}
				</Fragment>
			) : (
				<span>- Non-Refundable</span>
			);
		});
	};
	return (
		<div className={`room-summary`}>
			<main>
				<div className='codes'>
					<span>Room Type Code: {roomTypeCode}</span>{' '}
					<span>Rate Plan Code: {ratePlanCode}</span>
				</div>
				<section className='description'>
					<label>Description:</label>
					<ul>
						{descriptionText.map((item, index) => (
							<li key={`description-text-item-${index}`}>- {item._}</li>
						))}
					</ul>
					<label>Cancellation Policy:</label>
					{destructurePenalties()}
					<br />
					<label htmlFor=''>Payment Policy:</label>
					<span>
						-{' '}
						{guaranteeType === 'Guarantee Required'
							? 'Guarantee Required'
							: 'Deposit Needed'}
					</span>
					{policy ? (
						<div className='checkin-checkout'>
							<span>
								Check In Time: {rates[0].info.EffectiveDate} {checkinTime}
							</span>
							<span>
								Check Out Time:{' '}
								{rates && rates.length && rates[rates.length - 1]['Total']
									? rates[rates.length - 2].info.ExpireDate
									: rates[rates.length - 1].info.ExpireDate}{' '}
								{checkoutTime}
							</span>
						</div>
					) : (
						<div className='checkin-checkout'>
							<span>
								Check In Date:{' '}
								{rates && rates.length && rates[0].info.EffectiveDate}
							</span>
							<span>
								Check Out Date:{' '}
								{rates && rates.length && rates[rates.length - 1]['Total']
									? rates[rates.length - 2].info.ExpireDate
									: rates[rates.length - 1].info.ExpireDate}
							</span>
						</div>
					)}
				</section>
			</main>
			<aside>
				<HotelPrice
					roomRates={rates}
					total={total}
					conversionTotal={conversionTotal}
					commission={commission}
				/>
				{!isSelected ? (
					<button
						className='item-review__button'
						onClick={() => {
							addItineraryItem('lodging', newData);
							setSelected && setSelected();
						}}>
						Select
					</button>
				) : (
					<button className='item-review__button' onClick={deleteReviewItem}>
						Remove
					</button>
				)}
			</aside>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		trip: state.trip,
	};
};

export default connect(mapStateToProps, { addItineraryItem })(RoomSummaryNew);
