import React from 'react';
import { connect } from 'react-redux';
import { setRedirect } from '../../redux/Actions/redirectActions';
import noImage from '../../images/noun_Camera_prohibited_1554484.svg';
import AttachmentPopup from '../popups/AttachmentPopup';
import TripTitle from './TripTitle';
import { Link } from 'react-router-dom';
import { handleTripEndDate } from '../../Utilities/helperFunctions';

const TripInfo = ({
	flightDetails,
	hotelDetails,
	user,
	purchase,
	returnDate,
	setRedirect,
	_id,
	photoUrl,
}) => {
	const returnFeeInfo = (field) => {
		const noActionTaken = !field || (!field.authorizedOn && !field.paidOn);
		const actionString = () =>
			field && field.paidOn ? (
				<>
					Paid on <span>{field.paidOn.slice(0, 10)}</span>
				</>
			) : (
				<>
					Authorized on <span>{field.authorizedOn.slice(0, 10)}</span>
				</>
			);
		return noActionTaken ? <span>Unpaid</span> : actionString();
	};

	return user ? (
		<div className='trip-profile__info'>
			<div className='left'>
				<img src={photoUrl || noImage} alt='' />
			</div>

			<div className='right'>
				<header>
					<TripTitle hotels={hotelDetails} />
					<div className='actions'>
						<button onClick={() => setRedirect(`/create-itinerary/${_id}`)}>
							Edit
						</button>
						<AttachmentPopup trip_id={_id} buttonText='Add Attachments' />
					</div>
				</header>
				<div className='right bottom'>
					<section>
						<b>User: </b>
						<Link
							to={`/dashboard/user/${user._id}`}>{`${user.firstName} ${user.lastName}`}</Link>
					</section>
					<section>
						<b>Email: </b>
						<span>{user.email}</span>
					</section>
					<section>
						<b>Booking Fee: </b>
						{returnFeeInfo(purchase.booking)}
						{/* {purchase.booking.authorizedOn ? (
							<>
								Authorized on{' '}
								<span>{purchase.booking.authorizedOn.slice(0, 10)}</span>
							</>
						) : (
							<span>Unpaid</span>
						)} */}
					</section>
					<section>
						<b>Curation Fee: </b>
						{returnFeeInfo(purchase.curation)}
						{/* {purchase.curation.authorizedOn ? (
							<>
								Authorized on{' '}
								<span>{purchase.curation.authorizedOn.slice(0, 10)}</span>
							</>
						) : (
							<span>Unpaid</span>
						)} */}
					</section>
					<section>
						<b>Start Date: </b>{' '}
						<span>
							{flightDetails.length
								? flightDetails[0].departureDate.slice(0, 10)
								: 'N/A'}
						</span>
					</section>
					<section>
						<b>End Date: </b>
						<span>
							{handleTripEndDate({ returnDate, hotelDetails }).slice(0, 10)}
						</span>
					</section>
				</div>
			</div>
		</div>
	) : null;
};

const mapStateToProps = (state) => ({
	_id: state.trip._id,
	infants: state.trip.infants,
	children: state.trip.children,
	adults: state.trip.adults,
	flightDetails: state.trip.flightDetails,
	hotelDetails: state.trip.hotelDetails,
	updatedOn: state.trip.updatedOn,
	user: state.trip.user,
	purchase: state.trip.purchase,
	returnDate: state.trip.returnDate,
	travelType: state.trip.travelType,
	activityThemes: state.trip.activityThemes,
	submitted: state.trip.submitted,
	photoUrl: state.trip.photoUrl,
});

export default connect(mapStateToProps, { setRedirect })(TripInfo);
