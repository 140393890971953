import React from 'react';

const PriceFilter = ({ handleChange, range }) => {
	return (
		<div className='hotel__range-filter'>
			<label htmlFor=''>Price Range:</label>
			<select onChange={handleChange} name='range' defaultValue={range.text}>
				<option value={`{"text": "-$150", "min": "", "max": 150}`}>
					-$150
				</option>
				<option value={`{"text": "$150-$250", "min": 150, "max": 250}`}>
					$150-$250
				</option>
				<option value={`{"text": "$250+", "min": 250, "max": ""}`}>
					$250+
				</option>
				<option value={`Custom`}>Custom</option>
				{/* <option value='audi'>Audi</option> */}
			</select>
			{range.text === 'Custom' && (
				<>
					<span className='min'>
						$
						<input
							type='number'
							onChange={handleChange}
							name='min'
							placeholder='min'
						/>
					</span>
					<span className='max'>
						$
						<input
							type='number'
							onChange={handleChange}
							name='max'
							placeholder='max'
						/>
					</span>
				</>
			)}
		</div>
	);
};

PriceFilter.propTypes = {};

export default PriceFilter;
