import React from 'react';
import { handleVehicleType } from '../../Utilities/carHelpers';

const ListItemCar = ({
	vehicleTypeInfo,
	locationName,
	airportCode,
	terminalString,
	pictureURL,
}) => {
	return (
		<section className='item-list__car'>
			<img src={pictureURL} alt='' />
			<div className='item-list__car-details'>
				<h3 className='car-review__title capitalize'>
					{handleVehicleType(vehicleTypeInfo)}
				</h3>
				<div className='car-review__dropoff'>
					Pickup and Dropoff:{' '}
					<span className='capitalize'>
						{locationName && locationName.toLowerCase()}
					</span>{' '}
					({airportCode}) {terminalString}
				</div>
			</div>
		</section>
	);
};

export default ListItemCar;
