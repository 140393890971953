const initState = {
	authError: null,
	token: '',
	signedIn: false,
};

const authReducer = (state = initState, action) => {
	switch (action.type) {
		case 'PURCHASE_SUCCESS':
			return state;
		case 'LOGIN_ERROR':
			if (action.payload) {
				return { authError: action.payload, signedIn: false };
			} else {
				return { authError: 'An error occurred', signedIn: false };
			}

		case 'LOGIN_SUCCESS':
			return {
				...state,
				authError: null,
				token: action.payload,
				signedIn: true,
			};
		case 'LOGOUT_SUCCESS':
			return {
				authError: null,
				token: '',
				signedIn: false,
			};
		case 'LOGOUT_ERROR':
			return {
				...state,
				authError: 'Logout failed',
			};
		case 'TOKEN_RECIEVED':
			return {
				...state,
				token: action.payload.data.token,
				authError: null,
			};
		case 'TOKEN_ERROR':
			return {
				...state,
				token: state.token,
				authError: 'Incorrect email or password, please try again.',
			};

		default:
			return state;
	}
};

export default authReducer;
