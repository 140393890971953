import React, { Fragment, useEffect, useState } from 'react';
import AuthorizedContainer from '../authorizedItems/AuthorizedContainer';
import { connect } from 'react-redux';
import Attachments from './Attachments';

const TripItemDash = ({
	itineraryItems,
	cart,
	trip,
	paid,
	authorized,
	selected,
}) => {
	const [allItems, setAllItems] = useState([]);
	const [cartItems, setCartItems] = useState([]);
	const [paidItems, setPaidItems] = useState([]);
	const [window, setWindow] = useState('all');

	const { transport, flights, activities, lodging, amadeusCars } =
		itineraryItems;
	useEffect(() => {
		setPaidItems([
			...authorized.transport,
			...authorized.lodging,
			...authorized.flights,
			...authorized.activities,
			...authorized.amadeusCars,
			...paid.transport,
			...paid.lodging,
			...paid.flights,
			...paid.activities,
			...paid.amadeusCars,
		]);
		setCartItems([
			...cart.transport,
			...cart.flights,
			...cart.activities,
			...cart.amadeusCars,
			...cart.lodging,
			...selected.transport,
			...selected.flights,
			...selected.activities,
			...selected.lodging,
			...selected.amadeusCars,
		]);
		setAllItems([
			...transport.map((item, index) => ({ ...item, itineraryIndex: index })),
			...flights.map((item, index) => ({ ...item, itineraryIndex: index })),
			...activities.map((item, index) => ({ ...item, itineraryIndex: index })),
			...lodging.map((item, index) => ({ ...item, itineraryIndex: index })),
			...amadeusCars.map((item, index) => ({ ...item, itineraryIndex: index })),
		]);
	}, [
		paid,
		selected,
		authorized,
		transport,
		lodging,
		flights,
		activities,
		cart.transport,
		cart.flights,
		cart.activities,
		cart.lodging,
		itineraryItems,
		cart,
	]);

	const returnSelectedClass = (windowKey) => {
		if (window === windowKey) {
			return 'selected';
		}
	};

	return (
		<Fragment>
			<section className='trip-profile__dash'>
				<div className='trip-profile__dash-nav'>
					<button
						className={returnSelectedClass('all')}
						onClick={() => setWindow('all')}>
						All Available
					</button>
					<button
						className={returnSelectedClass('cart')}
						onClick={() => setWindow('cart')}>
						Saved for Later/Cart
					</button>
					<button
						className={returnSelectedClass('auth')}
						onClick={() => setWindow('auth')}>
						Authorized/Paid
					</button>
					<button
						className={returnSelectedClass('attachments')}
						onClick={() => setWindow('attachments')}>
						Attachments
					</button>
				</div>
				<main>
					{window === 'all' && (
						<AuthorizedContainer trip={trip} items={allItems} />
					)}
					{window === 'auth' && (
						<AuthorizedContainer trip={trip} items={paidItems} />
					)}
					{window === 'cart' && (
						<AuthorizedContainer trip={trip} items={cartItems} />
					)}
					{window === 'attachments' && <Attachments trip_id={trip._id} />}
				</main>
			</section>
		</Fragment>
	);
};

const mapStateToProps = (state) => ({
	trip: state.trip,
	cart: state.trip.cart,
	authorized: state.trip.authorized,
	paid: state.trip.paid,
	selected: state.trip.selected,
	itineraryItems: state.trip.itineraryItems,
});

export default connect(mapStateToProps, null)(TripItemDash);
