import React from 'react';
import UserItem from '../listItems/UserItem';

const UserList = ({ users, searchInput }) => {
	const returnFilteredUsers = () => {
		return users.filter((user) => {
			const compare = user.email.slice(0, searchInput.length);

			return compare === searchInput;
		});
	};

	const mapFilteredUsers = () => {
		return returnFilteredUsers().map((user) => {
			return <UserItem key={user._id} user={user} />;
		});
	};

	const mapCorrectUsers = () => {
		return searchInput
			? mapFilteredUsers()
			: users.map((user, userIndex) => <UserItem key={user._id} user={user} />);
	};
	return (
		<div className='list user-list'>
			<header className='list-header'>
				<span>User ID'</span>
				<span>Username</span>
				<span>Email</span>
				<span>Last Active</span>
			</header>
			<div className='item-list'>{mapCorrectUsers()}</div>
		</div>
	);
};

export default UserList;
