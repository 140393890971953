import React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import CarForm from '../Cars/CarForm';
import CloseSection from './CloseSection';
import { setCurrentForm } from '../../redux/Actions/trip';

const CarQuery = ({
	flightDetails,
	returnDate,
	formRef,
	formPosition,
	setCurrentForm,
}) => {
	const [currentIndex, setCurrentIndex] = useState(0);

	const carFormProps = [];

	flightDetails &&
		flightDetails.length &&
		flightDetails.forEach((detail, index) => {
			if (flightDetails.length > 1 && index === flightDetails.length - 1) {
				return null;
			}
			let start = detail;
			let end =
				flightDetails.length === 1
					? { departure: start.destination, departureDate: returnDate }
					: flightDetails[index + 1];

			const pickup_info = {
				formattedDate: start.departureDate.slice(0, 10),
				formattedTime: '12:00 p.m.',
				year: start.departureDate.slice(0, 4),
				month: start.departureDate.slice(5, 7),
				day: start.departureDate.slice(8, 10),
				hour: '12',
				minutes: '0',
				location: {
					code: '1A',
					name: start.destination.toUpperCase(),
					formattedText: '',
				},
			};

			const dropoff_info = {
				formattedDate: end.departureDate.slice(0, 10),
				formattedTime: '12:00 p.m.',
				year: end.departureDate.slice(0, 4),
				month: end.departureDate.slice(5, 7),
				day: end.departureDate.slice(8, 10),
				hour: '12',
				minutes: '0',
				location: {
					code: '1A',
					name: end.departure.toUpperCase(),
					formattedText: '',
				},
			};
			carFormProps.push({
				formRef,
				flightDetail: detail,
				populate: { pickup_info, dropoff_info },
				disabled: index === currentIndex ? false : true,
				setCurrentIndex,
				currentIndex,
				index,
			});
		});

	return (
		<div className='itinerary-form__container' style={formPosition}>
			<CloseSection />
			{carFormProps && carFormProps.length ? (
				<CarForm
					setCurrentForm={setCurrentForm}
					{...carFormProps[currentIndex]}
					endIndex={carFormProps.length - 1}
				/>
			) : null}
		</div>
	);
};

const mapStateToProps = (state) => ({
	flightDetails: state.trip.flightDetails,
	returnDate: state.trip.returnDate,
});

export default connect(mapStateToProps, { setCurrentForm })(CarQuery);
