import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import AuthorizedContainer from '../authorizedItems/AuthorizedContainer';
import TripContainer from '../newTrips/TripContainer';
import { setDashBoard, tripsQuery } from '../../redux/Actions/adminActions';
import { profileQuery } from '../../redux/Actions/profileActions';
import requireAuth from '../hoc/AuthComponent';
import redirectHOC from '../hoc/RedirectHOC';
import Quicknav from './Quicknav';
import { handleTripEndDate } from '../../Utilities/helperFunctions';
import { FadeLoader } from 'react-spinners';
import FormModal from '../Modal/FormModal';

const TripsDashboard = ({
	trips,
	authorizedItems,
	setDashBoard,
	pendingCurations,
	pendingBookings,
	loading,
}) => {
	const [filteredKey, setFilteredKey] = useState('');
	const isAuthKey =
		filteredKey === 'activities' ||
		filteredKey === 'flights' ||
		filteredKey === 'lodging' ||
		filteredKey === 'transport' ||
		filteredKey === 'curation' ||
		filteredKey === 'booking' ||
		filteredKey === 'amadeusCars';
	const isNewTrips = filteredKey === 'newTrips';
	const isActiveTrips = filteredKey === 'activeTrips';
	const isArchived = filteredKey === 'archivedTrips';
	const isBooked = filteredKey === 'bookedTrips';

	const handleClick = (key) => {
		setFilteredKey(key);
	};

	const today = new Date();

	const handleItems = () => {
		if (filteredKey === 'curation') {
			return pendingCurations;
		} else if (filteredKey === 'booking') {
			return pendingBookings;
		} else {
			return authorizedItems[filteredKey];
		}
	};
	const filteredTrips = {
		newTrips:
			trips &&
			trips.filter(
				(trip) =>
					!trip.curated &&
					typeof trip.submitted === 'object' &&
					trip.submitted.success &&
					(!trip.purchase.booking || !trip.purchase.booking.authorizedOn)
			),
		activeTrips:
			trips &&
			trips.filter((trip) => {
				return (
					trip.submitted.success &&
					new Date(handleTripEndDate(trip)) > new Date(today)
				);
			}),

		bookedTrips: trips.filter(
			(trip) => trip.purchase.booking && trip.purchase.booking.authorizedOn
		),
		activities: authorizedItems.activities,
		flights: authorizedItems.flights,
		lodging: authorizedItems.lodging,
		transport: authorizedItems.transport,
		amadeusCars: authorizedItems.amadeusCars,
		archivedTrips:
			trips &&
			trips.filter((trip) => {
				return (
					trip.submitted.success &&
					new Date(new Date(trip.returnDate)) < new Date(today)
				);
			}),
	};

	useEffect(() => {
		if (!filteredKey) {
			setDashBoard();
		}
	}, [filteredKey]);

	return (
		<div className='dashboard'>
			<FormModal xAxis={0} />
			{/* {<TripSubNav setFilteredKey={setFilteredKey} />} */}

			{loading ? (
				<div className='loading'>
					<FadeLoader />
				</div>
			) : (
				<Fragment>
					{!filteredKey && (
						<Quicknav
							handleClick={handleClick}
							filteredTrips={filteredTrips}
							authorizedItems={authorizedItems}
						/>
					)}{' '}
					{isAuthKey && (
						<AuthorizedContainer
							toggleContainer={() => setFilteredKey('')}
							authKey={filteredKey}
							status='authorized'
							items={handleItems()}
						/>
					)}
					{isNewTrips && (
						<TripContainer
							newTrips={true}
							toggleContainer={() => setFilteredKey('')}
							trips={filteredTrips.newTrips}
							redirectPath='/create-itinerary/'
						/>
					)}
					{isActiveTrips && (
						<TripContainer
							toggleContainer={() => setFilteredKey('')}
							trips={filteredTrips.activeTrips}
							redirectPath='/dashboard/user/'
						/>
					)}
					{isArchived && (
						<TripContainer
							toggleContainer={() => setFilteredKey('')}
							trips={filteredTrips.archivedTrips}
							redirectPath='/dashboard/user/'
						/>
					)}
					{isBooked && (
						<TripContainer
							toggleContainer={() => setFilteredKey('')}
							trips={filteredTrips.bookedTrips}
							redirectPath='/dashboard/user/'
						/>
					)}
				</Fragment>
			)}
		</div>
	);
};

TripsDashboard.propTypes = {
	trips: PropTypes.object,
	token: PropTypes.string,
};

const mapStateToProps = (state) => ({
	trips: state.admin.trips,
	token: state.auth.token,
	authorizedItems: state.admin.authorizedItems,
	pendingCurations: state.admin.pendingCurations,
	pendingBookings: state.admin.pendingBookings,
	loading: state.admin.loading,
});

export default connect(mapStateToProps, {
	tripsQuery,
	profileQuery,
	setDashBoard,
})(redirectHOC(requireAuth(TripsDashboard)));
