const initState = {
	formType: '',
	formData: null,
	loading: false,
};

const customItemForm = (state = initState, action) => {
	switch (action.type) {
		case 'SET_FORM_DATA':
			return {
				...state,
				...action.payload,
				loading: false,
			};

		case 'CLEAR_FORM':
			return initState;

		default:
			return state;
	}
};

export default customItemForm;
