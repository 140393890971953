const initialState = {
	_id: '',
	user: '',
	returnDate: '',
	currentForm: '',
	currentSearch: '',
	currentItemReview: null,
	flightDetails: [],
	hotelDetails: [],
	itineraryItems: {
		flights: [],
		transport: [],
		lodging: [],
		activities: [],
		amadeusCars: [],
	},
	dayTrips: [],
	multiDayTrips: [],
	adults: 1,
	children: 0,
	infants: 0,
	secondaryTravelers: [],
	activityThemes: [],
	travelType: '',
	purchase: {
		authorizedItems: {
			flights: [],
			lodging: [],
			transport: [],
			activities: [],
			amadeusCars: [],
		},
		tripCuration: {
			paid: false,
			paymentDate: null,
			orderConfirmation: null,
		},
		tripBooking: {
			paid: false,
			paymentDate: null,
			orderConfirmation: null,
		},
	},
	authorized: {
		flights: [],
		transport: [],
		lodging: [],
		activities: [],
		amadeusCars: [],
	},
	selected: {
		flights: [],
		transport: [],
		lodging: [],
		activities: [],
		amadeusCars: [],
	},
	paid: {
		flights: [],
		transport: [],
		lodging: [],
		activities: [],
		amadeusCars: [],
	},
	cart: {
		flights: [],
		transport: [],
		lodging: [],
		activities: [],
		amadeusCars: [],
	},
	photoUrl: '',
	comments: '',
	submitted: null,
	updatedOn: null,
	booked: false,
	curated: false,
	itineraryModalOpen: false,
	selectedItem: null,
	loading: true,
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case 'CLEAR_TRIP':
			return {
				...state,
				_id: '',
				user: '',
				returnDate: '',
				currentForm: '',
				currentSearch: '',
				flightDetails: [],
				hotelDetails: [],
				itineraryItems: {
					flights: [],
					transport: [],
					lodging: [],
					activities: [],
					amadeusCars: [],
				},
				dayTrips: [],
				multiDayTrips: [],
				adults: 1,
				children: 0,
				infants: 0,
				secondaryTravelers: [],
				activityThemes: [],
				travelType: '',
				purchase: {
					authorizedItems: {
						flights: [],
						lodging: [],
						transport: [],
						activities: [],

						amadeusCars: [],
					},
					tripCuration: {
						paid: false,
						paymentDate: null,
						orderConfirmation: null,
					},
					tripBooking: {
						paid: false,
						paymentDate: null,
						orderConfirmation: null,
					},
				},
				authorized: {
					flights: [],
					transport: [],
					lodging: [],
					activities: [],
					amadeusCars: [],
				},
				selected: {
					flights: [],
					transport: [],
					lodging: [],
					activities: [],
					amadeusCars: [],
				},
				paid: {
					flights: [],
					transport: [],
					lodging: [],
					activities: [],
					amadeusCars: [],
				},
				cart: {
					flights: [],
					transport: [],
					lodging: [],
					activities: [],
					amadeusCars: [],
				},
				photoUrl: '',
				comments: '',
				submitted: null,
				updatedOn: null,
				booked: false,
				curated: false,
				itineraryModalOpen: true,
				selectedItem: null,
				loading: false,
			};
		case 'SET_TRIP':
			return {
				...state,

				...payload,
				itineraryItems: {
					...state.itineraryItems,
					...payload.itineraryItems,
				},
				purchase: {
					...state.purchase,
					...payload.purchase,
					authorizedItems: {
						...state.purchase.authorizedItems,
						...payload.purchase.authorizedItems,
					},
				},
				authorized: {
					...state.authorized,
					...payload.authorized,
				},
				selected: {
					...state.selected,
					...payload.selected,
				},
				paid: {
					...state.paid,
					...payload.paid,
				},
				cart: {
					...state.cart,
					...payload.cart,
				},
				loading: false,
			};
		case 'ADD_ITINERARY_HOTEL_URL':
			return {
				...state,
				itineraryItems: {
					...state.itineraryItems,
					lodging: state.itineraryItems.lodging.map((item) => {
						const currentHotelCode = item.data.hotel_info.info['HotelCode'];
						if (payload.hotelCode === currentHotelCode) {
							return {
								...item,
								customUrl: payload.url,
							};
						} else {
							return item;
						}
					}),
				},
			};
		case 'ADD_TRIP_URL':
			return {
				...state,
				photoUrl: payload,
			};
		case 'SET_CURRENT_FORM':
			return {
				...state,
				currentForm: payload,
			};
		case 'SET_CURRENT_REVIEW':
			return {
				...state,
				currentItemReview: payload,
			};
		case 'SET_CURRENT_SEARCH':
			return {
				...state,
				currentSearch: payload,
			};
		case 'OVERWRITE_TRIP_SUCCESS':
			return { ...state, itineraryItems: payload };
		case 'ADD_ITINERARY_ITEM':
			return {
				...state,
				itineraryItems: {
					...state.itineraryItems,
					[payload.key]: [...state.itineraryItems[payload.key], payload.item],
				},
			};
		case 'CLEAR_TRIP_MODAL':
			return {
				...state,
				currentForm: '',
				currentItemReview: null,
			};
		case 'EDIT_HOTEL': {
			return {
				...state,
				hotelDetails: state.hotelDetails.map((detail, index) =>
					index === payload.index
						? { ...detail, [payload.key]: payload.value }
						: detail
				),
			};
		}
		case 'SELECT_MODAL_ITEM':
			return {
				...state,
				itineraryModalOpen: true,
				selectedItem: payload,
			};
		case 'DESELECT_MODAL_ITEM':
			return {
				...state,
				itineraryModalOpen: false,
				selectedItem: null,
			};
		case 'REMOVE_ATTACHMENT':
			return {
				...state,
				attachments: state.attachments.filter(
					(item, index) => index !== payload
				),
			};
		case 'SET_ATTACHMENTS':
			return {
				...state,
				attachments: payload,
			};
		case 'EDIT_FLIGHT_DETAIL': {
			return {
				...state,
				flightDetails: state.flightDetails.map((detail, index) =>
					index === payload.index
						? { ...detail, [payload.key]: payload.value }
						: detail
				),
			};
		}

		case 'EDIT_TRIP_FIELD':
			return {
				...state,
				[payload.key]: payload.value,
			};
		case 'EDIT_ITINERARY_ITEM':
			return {
				...state,
				itineraryItems: {
					...state.itineraryItems,
					[payload.key]: state.itineraryItems[payload.key].map(
						(item, index) => {
							if (index === payload.index) {
								return payload.item;
							}
							return item;
						}
					),
				},
			};
		case 'ADD_TRIP_ATTACHMENT':
			return {
				...state,
				attachments: [...state.attachments, payload],
			};
		case 'REMOVE_TRIP_AUTHORIZATION':
			return {
				...state,
				itineraryItems: {
					...state.itineraryItems,
					[payload.key]: state.itineraryItems[payload.key].map((item) =>
						item._id === payload.item._id ? payload.item : item
					),
				},
				authorized: {
					...state.authorized,
					...payload.authorized,
				},
			};
		case 'REMOVE_BY_BOOKING_CODE':
			return {
				...state,
				itineraryItems: {
					...state.itineraryItems,
					lodging: state.itineraryItems.lodging.filter((item) => {
						return !(
							item.data.booking_code === payload.bookingCode &&
							item.data.hotel_info.info['HotelCode'] === payload.hotelCode
						);
					}),
				},
			};
		case 'DELETE_ITINERARY_ITEM':
			return {
				...state,
				itineraryItems: {
					...state.itineraryItems,
					[payload.key]: state.itineraryItems[payload.key].filter(
						(item, index) => index !== payload.index
					),
				},
			};
		default:
			return state;
	}
}
