import React, { Fragment, useEffect } from 'react';
import UserPreference from '../userProfile/UserPreference';
import { useState } from 'react';

const PreferenceSection = ({ preferences, currentSearch }) => {
	const [preferenceGroups, setPreferenceGroups] = useState(null);
	useEffect(() => {
		if (preferences) {
			const {
				flightPrice,
				seatLocation,
				seating,
				fareType,
				tripInsurance,
				lodgingPrice,
				idealLocation,

				lodgingType,
				driveVsDriver,
				publicTransportationPreferences,
				publicVsPrivate,
				activitySamples,
				tours,
				sightSeeingTours,
				physicalActivity,
			} = preferences;

			const handleValue = (value) => {
				if (typeof value === 'string') return () => value;
				if (typeof value === 'number') {
					return () =>
						value
							? `Less than ${value} miles.`
							: "I don't mind commuting to save dollars on lodging";
				} else if (Array.isArray(value)) {
					return () =>
						value.map((item, index) =>
							index === value.length - 1 ? item : `${item}, `
						);
				} else {
					const keys = Object.keys(value);
					return () =>
						keys.map((key, index) =>
							index === keys.length - 1
								? `${key}: ${value[key]}`
								: `${key}: ${value[key]} `
						);
				}
			};

			setPreferenceGroups({
				activity: [
					{ name: 'Activity Examples', value: handleValue(activitySamples) },
					{ name: 'Tour Types', value: handleValue(tours) },
					{ name: 'Tour Themes', value: handleValue(sightSeeingTours) },
					{
						name: 'Physical Activity Levels',
						value: handleValue(physicalActivity),
					},
				],
				hotel: [
					{ name: 'Lodging Price Point', value: handleValue(lodgingPrice) },
					{ name: 'Ideal Location', value: handleValue(idealLocation) },
					{ name: 'Lodging Type', value: handleValue(lodgingType) },
				],
				flight: [
					{ name: 'Seat Section', value: handleValue(seatLocation) },
					{ name: 'Aisle Location', value: handleValue(seating) },
					{ name: 'Fare Type', value: handleValue(fareType) },
					{ name: 'Trip Insurance?', value: handleValue(tripInsurance) },
					{ name: 'Pricing Preference', value: handleValue(flightPrice) },
				],
				transportation: [
					{ name: 'Drive or Be Driven?', value: handleValue(driveVsDriver) },
					{
						name: 'Preferred Mode Of Public Transportation?',
						value: handleValue(publicTransportationPreferences),
					},
					{ name: 'Privacy Preference', value: handleValue(publicVsPrivate) },
				],
			});
		}
	}, [preferences]);

	const returnPreferences = () => {
		return preferenceGroups && currentSearch && preferenceGroups[currentSearch]
			? preferenceGroups[currentSearch].map((preference, index) => {
					return (
						<UserPreference
							key={`preference-item-${index}`}
							name={preference.name}
							value={preference.value()}
						/>
					);
			  })
			: null;
	};

	return (
		<Fragment>
			<div className='preference-container'>
				<h5 className='capitalize'>{currentSearch} Preferences</h5>
				<div className='preferences'>{returnPreferences()}</div>
			</div>
		</Fragment>
	);
};

export default PreferenceSection;
