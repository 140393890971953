import React from 'react';

const CustomItemReviewField = ({ title, value }) => {
	return (
		<div className={`text-field`}>
			<label>{title}:</label>
			<p className='data capitalize'>{value}</p>
		</div>
	);
};

export default CustomItemReviewField;
