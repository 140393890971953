import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import HotelSummaryNew from './HotelSummaryNew';
import RoomSummaryNew from './RoomSummaryNew';
import {
	setLiveHotelItem,
	setHotelLoading,
} from '../../redux/Actions/hotelActions';

import failureImg from '../../images/noun-x-2289990-E91831.svg';
import { FadeLoader } from 'react-spinners';
import css from '@emotion/css';
import ErrMessage from '../Layout/ErrMessage';

const override = css`
	display: block;
	margin: 5rem auto;
	border-color: red;
`;

const HotelReview = (props) => {
	const {
		checkLiveSearch,
		setLiveHotelItem,
		setHotelLoading,
		hotelCode,
		chainCode,
		hotelCityCode,
		startDate,
		endDate,
		roomType,
		bookingCode,
		prevItemId,
		adults,
		children,
		infants,
		loading,
		error,
	} = props;

	useEffect(() => {
		if (checkLiveSearch) {
			setLiveHotelItem({
				hotelItemID: prevItemId,
				chain_code: chainCode,
				hotel_code: hotelCode,
				hotel_city_code: hotelCityCode,
				start_date: startDate,
				end_date: endDate,
				room_type: roomType,
				guest_count: adults + children + infants,
				room_count: 1,
				booking_code: bookingCode,
			});
		} else {
			setHotelLoading(false);
		}
	}, []);
	return !loading ? (
		<div className='hotel-review'>
			{error ? (
				<div className='error'>
					<img src={failureImg} alt='' />
					{error}
				</div>
			) : (
				<>
					<HotelSummaryNew {...props} />
					<hr />
					<RoomSummaryNew {...props} />
				</>
			)}
		</div>
	) : (
		<FadeLoader css={override} size={150} color={'black'} loading={true} />
	);
};

const mapStateToProps = (state) => ({
	liveHotelItem: state.hotel.liveHotelItem,
	loading: state.hotel.loading,
	adults: state.trip.adults,
	children: state.trip.children,
	error: state.hotel.error,
	infants: state.trip.infants,
});

export default connect(mapStateToProps, {
	setLiveHotelItem,
	setHotelLoading,
})(HotelReview);
