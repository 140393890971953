import React, { useState } from 'react';
import { checkType } from '../../Utilities/helperFunctions';
import { connect } from 'react-redux';
import TransportForm from '../CustomData/Transport/TransportForm';
import ActivityForm from '../CustomData/Activity/ActivityForm';
import {
	removeAuthItem,
	setPaidActivity,
	setPaidCar,
	setPaidFlight,
	setPaidLodging,
	setPaidTransport,
} from '../../redux/Actions/authItem';
import getSymbolFromCurrency from 'currency-symbol-map';
import { checkItemStatus } from '../../Utilities/helperFunctions';
import ReviewItem from '../Modal/ReviewItem';
import AuthItem from './AuthItem';
import ReviewActions from '../Modal/ReviewActions';
import { handleFeePurchase } from '../../redux/Actions/adminActions';
import {
	setCurrentForm,
	updateActivity,
	updateTransport,
} from '../../redux/Actions/trip';
import { FadeLoader } from 'react-spinners';
import css from '@emotion/css';
import { setCustomForm } from '../../redux/Actions/customItemForm';
import { closeLiveHotelSearch } from '../../redux/Actions/hotelActions';

const override = css`
	display: block;
	margin: 5rem auto;
	border-color: red;
`;

const AuthItemModal = ({
	item,
	alerts,
	paymentSuccess,
	loading,
	event,
	removeAuthItem,
	setPaidActivity,
	setPaidFlight,
	setPaidTransport,
	setPaidLodging,
	handleFeePurchase,
	updateTransport,
	updateActivity,
	setPaidCar,
	setCustomForm,
	setCurrentForm,
	closeLiveHotelSearch,
	hotelLoading,
}) => {
	const [ccData, setccData] = useState({
		cc_number: '',
		cvc_code: '',
		cc_exp: '',
		cc_name: '',
	});
	const [formData, setFormData] = useState({
		vendor: '',
		confirmation: '',
		amount: '',
		pnr: '',
		adminNotes: '',
		attachments: [],
	});
	const [confirmation, setConfirmation] = useState('');
	const {
		activity,
		flight,
		lodging,
		transportation,
		booking,
		curation,
		amadeusCars,
	} = checkType(item && (item.type || item.data.type));
	const { itemPaid } = checkItemStatus(item && item.trip, item);
	const handleSetPaid = () => {
		activity &&
			setPaidActivity({ item, paymentInformation: formData }, confirmation);
		flight &&
			setPaidFlight({ item, paymentInformation: formData }, confirmation);
		transportation &&
			setPaidTransport({ item, paymentInformation: formData }, confirmation);
		lodging && setPaidLodging({ ...item, ccData }, confirmation);
		amadeusCars && setPaidCar(item._id, confirmation);
		if (curation || booking) handleFeePurchase(item);
		setConfirmation('');
	};

	const handleInput = (e) => {
		e.preventDefault();
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const handleChange = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		setccData({ ...ccData, [name]: value });
	};

	const returnAlerts = () => {
		if (paymentSuccess) {
			return (
				<div className='auth-item__alerts'>
					{alerts.map((alert, index) => {
						return <p key={`auth-item__alert-${index}`}>{alert}</p>;
					})}
				</div>
			);
		}
		return null;
	};

	const handleEditDisplay = () => {
		if (transportation) {
			return (
				<TransportForm
					updateTransport={
						item._id ? (updatedItem) => updateTransport(updatedItem) : null
					}
					item={{ ...item, trip: (item.trip && item.trip._id) || item.trip }}
				/>
			);
		}
		if (activity) {
			return (
				<ActivityForm
					updateActivity={
						item._id ? (updatedItem) => updateActivity(updatedItem) : null
					}
					activity={{
						...item,
						trip: (item.trip && item.trip._id) || item.trip,
					}}
				/>
			);
		}
	};

	const handleAuthDisplay = () => {
		return (
			<AuthItem
				itemInfo={item}
				item={item}
				formData={formData}
				handleInput={handleInput}
				handleChange={handleChange}
				ccData={ccData}
				getSymbolFromCurrency={getSymbolFromCurrency}
			/>
		);
	};

	const handleDisplay = () => {
		if (loading) {
			return (
				<FadeLoader css={override} size={150} color={'black'} loading={true} />
			);
		} else {
			if (event === 'edit') {
				return handleEditDisplay();
			}
			if (event === 'payment') {
				return handleAuthDisplay();
			}
			if (event === 'review') {
				return <ReviewItem />;
			}
		}
	};
	const close = () => {
		setCustomForm('', null);
		setCurrentForm('');
		removeAuthItem();
		lodging && closeLiveHotelSearch();
	};

	return (
		<div className='auth__container'>
			{item ? (
				<>
					<div className='action__container'>
						<ReviewActions
							tripId={item.trip?._id}
							close={close}
							uploadDocument={transportation || activity || flight}
						/>
						<button className='modal-close' onClick={close}>
							<i className='fa fa-times'></i>
						</button>
					</div>
					{handleDisplay()}
					{alerts.length ? returnAlerts() : null}
					<button
						disabled={loading || hotelLoading}
						className='auth-item__button'
						onClick={() => handleSetPaid()}>
						{!itemPaid ? 'Set As Paid' : 'Paid'}
					</button>
				</>
			) : null}
		</div>
	);
};

const mapStateToProps = (state) => ({
	item: state.authItem.item,
	trips: state.admin.trips,
	alerts: state.authItem.alerts,
	paymentSuccess: state.authItem.paymentSuccess,
	loading: state.authItem.loading,
	hotelLoading: state.hotel.loading,
	event: state.authItem.event,
});

export default connect(mapStateToProps, {
	removeAuthItem,
	setPaidActivity,
	setPaidFlight,
	setPaidTransport,
	setPaidLodging,
	handleFeePurchase,
	updateTransport,
	updateActivity,
	setPaidCar,
	setCustomForm,
	setCurrentForm,
	closeLiveHotelSearch,
})(AuthItemModal);
