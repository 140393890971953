import React, { useEffect, useState } from 'react';
import { newReturnCitySearch } from '../Utilities/Utils';
import { connect } from 'react-redux';
import { post } from '../Utilities/api';
import { setCurrentForm } from '../redux/Actions/trip';
import LoadingOverlay from './Layout/LoadingOverlay';

const TestimonialForm = ({
	setCurrentForm,
	customItemForm,
	currentForm,
	updates,
}) => {
	const [testimonialInfo, setTestimonialInfo] = useState(
		customItemForm.formData || {
			photoInput: '',
			photos: [],
			title: '',
			travelerAmount: '',
			destinationInput: '',
			destinations: [],
			description: '',
			feedback: '',
			highlighted: false,
		}
	);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [searchResults, setSearchResults] = useState([]);

	const handleSelectCity = (location) => {
		setTestimonialInfo({
			...testimonialInfo,
			destinationInput: ``,
			destinations: [...testimonialInfo.destinations, location],
		});
		setSearchResults([]);
	};

	const deleteDestination = (currentIndex) => {
		setTestimonialInfo({
			...testimonialInfo,
			destinations: testimonialInfo.destinations.filter((item, index) => {
				return index !== currentIndex;
			}),
		});
	};

	const deletePhoto = (currentIndex) => {
		setTestimonialInfo({
			...testimonialInfo,
			photos: testimonialInfo.photos.filter((item, index) => {
				return index !== currentIndex;
			}),
		});
	};

	const returnDestinations = () => {
		return (
			testimonialInfo &&
			testimonialInfo.destinations &&
			testimonialInfo.destinations.map((location, index) => {
				return location.location ? (
					<div className='location capitalize'>
						{`${location.location.cityName}, ${
							location.location.stateCode !== 'null'
								? location.location.stateCode.toUpperCase() + ', '
								: ''
						}${location.location.countryName}`}
						<button
							onClick={(e) => {
								e.preventDefault();
								deleteDestination(index);
							}}>
							+
						</button>{' '}
					</div>
				) : (
					<div className='location capitalize'>
						{`${location.cityName}, ${
							location.stateCode !== 'null'
								? location.stateCode.toUpperCase() + ', '
								: ''
						}${location.countryName}`}
						<button
							onClick={() => {
								deleteDestination(index);
							}}>
							+
						</button>{' '}
					</div>
				);
			})
		);
	};

	const returnPhotos = () => {
		return (
			testimonialInfo &&
			testimonialInfo.photos &&
			testimonialInfo.photos.map((url, index) => {
				return (
					<div className='location'>
						{url}
						<button
							onClick={(e) => {
								e.preventDefault();
								deletePhoto(index);
							}}>
							+
						</button>{' '}
					</div>
				);
			})
		);
	};

	const returnSearchResults = () => {
		return searchResults && searchResults.length ? (
			<div className='autofill-section testimonial'>
				{searchResults.map((result, resultIndex) => {
					return (
						<div
							key={`destination-form-search-result-${resultIndex}`}
							className='autofill-option'
							onClick={() => handleSelectCity(result)}>
							<p className='autofill-airport-name capitalize'>
								{result['cityName']},{' '}
								{result['stateCode'] !== 'null'
									? result['stateCode'].toUpperCase() + ', '
									: null}
								{result['countryName']}
							</p>
						</div>
					);
				})}
			</div>
		) : null;
	};

	const handleAddDestination = async (e) => {
		e.preventDefault();
		setLoading(true);
		setSubmitted(true);
		const { location, geographicalType, activityTypes } = testimonialInfo;
		// if (location && geographicalType.length && activityTypes.length) {
		let url, body;
		if (currentForm === 'newTestimonial') {
			url = '/api/v1/data/private/testimonial-group/add';
			body = testimonialInfo;
		}
		if (currentForm === 'testimonialGroup') {
			url = '/api/v1/data/private/testimonial-group/update';
			body = testimonialInfo;
		}
		if (currentForm === 'testimonial') {
			url = '/api/v1/data/private/testimonial/update';
			body = {
				_id: null,
			};
		}

		const res = await post(url, testimonialInfo, true);
		if (res.status === 200) {
			setSuccess(true);
			setTimeout(() => {
				setCurrentForm(null);
				updates && updates();
			}, 5000);
		}
		setLoading(false);
		setTimeout(() => {
			setSubmitted(false);
		}, 5000);
		setTestimonialInfo({
			location: null,
			relativeLocation: 'Domestic',
			geographicalType: [],
			activityTypes: [],
			groupType: 'Family Friendly',
			results: [],
		});
		// }
	};

	const handleCitySearch = (value) => {
		if (value.length >= 3) {
			const results = newReturnCitySearch(value);
			console.log('results', results);
			setSearchResults(results);
		} else {
			setSearchResults([]);
		}
	};

	const addPhoto = (e) => {
		e.preventDefault();
		if (testimonialInfo.photos?.length < 3) {
			setTestimonialInfo({
				...testimonialInfo,
				photos: [...testimonialInfo.photos, testimonialInfo.photoInput],
				photoInput: '',
			});
		}
	};

	const handleChange = (e) => {
		const { value, name } = e.target;

		if (name === 'destinationInput') {
			handleCitySearch(value);
		}
		if (name === 'highlighted') {
			setTestimonialInfo((curr) => {
				return {
					...curr,
					[name]: !testimonialInfo[name],
				};
			});
		} else {
			setTestimonialInfo((curr) => {
				return {
					...curr,
					[name]: value,
				};
			});
		}
	};

	return (
		<div className='itinerary-form__container'>
			{' '}
			{submitted ? (
				<LoadingOverlay loading={loading} success={success} />
			) : null}
			<form className='testimonial__form'>
				<button
					className='modal-close'
					onClick={(e) => {
						e.preventDefault();
						setCurrentForm(null);
					}}>
					<i className='fa fa-times'></i>
				</button>

				{currentForm === 'newTestimonial' ||
				currentForm === 'testimonialGroup' ? (
					<>
						<label htmlFor='photoInput'>Photos:</label>
						<div className='flex-section'>
							<div className='text-field' style={{ width: '100%' }}>
								<input
									required
									autocomplete='off'
									value={testimonialInfo.photoInput}
									type='text'
									id='photoInput'
									name='photoInput'
									onChange={handleChange}
								/>
								<button
									style={{
										width: `100px`,
										height: `3.5rem`,
										display: `flex`,
										justifyContent: `center`,
										alignItems: `center`,
									}}
									onClick={addPhoto}>
									Add Photo
								</button>
							</div>
						</div>
						<div className='flex-section'>{returnPhotos()}</div>
						<div className='flex-section'>
							<div className='text-field' style={{ width: '100%' }}>
								<label
									className='red-x'
									style={{ cursor: 'default' }}
									type='text'
									id='destinationLocationCode'>
									Title:
								</label>
								<div>
									<input
										required
										autocomplete='off'
										value={testimonialInfo.title}
										type='text'
										id='title'
										name='title'
										className='capitalize'
										onChange={handleChange}
									/>
								</div>
							</div>
						</div>

						<div className='flex-section'>
							<div className='text-field' style={{ width: '100%' }}>
								<label
									className='red-x'
									style={{ cursor: 'default' }}
									type='text'
									id='destinationLocationCode'>
									Destinations:
								</label>
								<div>
									<input
										required
										autocomplete='off'
										value={testimonialInfo.destinationInput}
										type='text'
										id='destinationInput'
										name='destinationInput'
										className='capitalize'
										onChange={handleChange}
									/>
									{returnSearchResults()}
								</div>
							</div>
						</div>
						<div className='flex-section'>{returnDestinations()}</div>
					</>
				) : null}

				{currentForm === 'newTestimonial' || currentForm === 'testimonial' ? (
					<>
						<div className='flex-section'>
							<div className='text-field' style={{ width: '100%' }}>
								<label
									className='red-x'
									style={{ cursor: 'default' }}
									type='text'
									id='destinationLocationCode'>
									Number Of Travelers:
								</label>
								<div>
									<input
										required
										autocomplete='off'
										value={
											testimonialInfo.travelerAmount ||
											testimonialInfo.travelerAmount
										}
										type='number'
										id='travelerAmount'
										name='travelerAmount'
										onChange={handleChange}
									/>
								</div>
							</div>
						</div>
					</>
				) : null}

				{currentForm === 'newTestimonial' ||
				currentForm === 'testimonialGroup' ? (
					<>
						<label
							className='red-x'
							style={{ cursor: 'default' }}
							type='text'
							id='destinationLocationCode'>
							Description:
						</label>
						<div className='flex-section'>
							<textarea
								required
								autocomplete='off'
								value={testimonialInfo.description}
								type='text'
								id='description'
								name='description'
								onChange={handleChange}
							/>
						</div>
					</>
				) : null}

				{currentForm === 'newTestimonial' || currentForm === 'testimonial' ? (
					<>
						<label
							className='red-x'
							style={{ cursor: 'default' }}
							type='text'
							id='destinationLocationCode'>
							Feedback:
						</label>
						<div className='flex-section'>
							<textarea
								required
								autocomplete='off'
								value={testimonialInfo.feedback}
								type='text'
								id='feedback'
								name='feedback'
								onChange={handleChange}
							/>
						</div>
						<div className='flex-section'>
							<label htmlFor='highlighted'>Highlighted:</label>
							<input
								defaultChecked={testimonialInfo.highlighted}
								type='checkbox'
								name='highlighted'
								id='highlighted'
								onChange={handleChange}
							/>
						</div>
					</>
				) : null}

				<div className='action__container'>
					<button onClick={handleAddDestination} className='submit'>
						Save
					</button>
				</div>
			</form>
		</div>
	);
};

const mapStateToProps = (state) => ({
	customItemForm: state.customItemForm,
	currentForm: state.trip.currentForm,
});

export default connect(mapStateToProps, { setCurrentForm })(TestimonialForm);
