import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import UserPreferenceSection from './UserPreferenceSection';
import {
	removeSelectedTrip,
	setSelectedTrip,
} from '../../redux/Actions/adminActions';
import ItemList from '../lists/ItemList';
import AuthorizedContainer from '../authorizedItems/AuthorizedContainer';
import { useParams } from 'react-router-dom';

import { FadeLoader } from 'react-spinners';
import css from '@emotion/css';
const override = css`
	display: block;
	margin: 3rem auto;
`;

const UserContent = ({
	trips,
	userId,
	selectedUser,
	selectedTrip,
	removeSelectedTrip,
	setSelectedTrip,
	adminLoading,
}) => {
	const { id, tripId } = useParams();

	console.log({ id, tripId });
	const [currentMainContent, setCurrentMainContent] = useState('active');
	const [currentSecondaryContent, setCurrentSecondaryContent] =
		useState('itineraryItems');
	const activeOrArchived =
		currentMainContent === 'active' || currentMainContent === 'archived';
	const isPreferences = currentMainContent === 'preferences';
	const isAuthorized = currentMainContent === 'authorized';
	const isPaid = currentMainContent === 'paid';
	const [sortedTrips, setSortedTrips] = useState({
		active: null,
		archived: null,
	});
	const [userPreferences, setUserPreferences] = useState(null);

	const returnTripItemsByKey = (trip, key) => {
		const { flights, transport, activities, lodging, amadeusCars } = trip[key];
		return [
			...flights.map((item) => ({
				...item,
				status: key,
				trip: {
					user: {
						email: trip.user.email,
					},
				},
			})),
			...transport.map((item) => ({
				...item,
				status: key,
				trip: {
					user: {
						email: trip.user.email,
					},
				},
			})),
			...activities.map((item) => ({
				...item,
				status: key,
				trip: {
					user: {
						email: trip.user.email,
					},
				},
			})),
			...lodging.map((item) => ({
				...item,
				status: key,
				trip: {
					user: {
						email: trip.user.email,
					},
				},
			})),
			...amadeusCars.map((item) => ({
				...item,
				status: key,
				trip: {
					user: {
						email: trip.user.email,
					},
				},
			})),
		];
	};

	const returnAuthorizedItems = (key) => {
		let results = [];
		selectedUser.trips.forEach((trip) => {
			results = [...results, ...returnTripItemsByKey(trip, key)];
		});
		return results;
	};

	const handleShowTrip = (id) => {
		setSelectedTrip(id);
		setCurrentMainContent('trip');
	};
	useEffect(() => {
		const {
			homeAirport,
			nonStop,
			flightPrice,
			seatLocation,
			seating,
			fareType,
			checkedBag,
			tripInsurance,
			lodgingPrice,
			idealLocation,
			lodgingType,
			driveVsDriver,
			publicTransportation,
			publicVsPrivate,
			activitySamples,
			tours,
			privateVsPublicTour,
			sightSeeingTours,
			physicalActivity,
			specialEventInterest,
		} = selectedUser;

		const returnEndDate = (trip) => {
			const sortedFlightDetails = trip.flightDetails.sort((a, b) => {
				return a.departureDate > b.departureDate;
			});

			const sortedHotelDetails = trip.hotelDetails.sort((a, b) => {
				return a.endDate > b.endDate;
			});
			const endDate =
				sortedFlightDetails[0].departureDate > sortedHotelDetails[0].endDate
					? sortedFlightDetails[0].departureDate
					: sortedHotelDetails[0].endDate;

			return trip.returnDate || endDate;
		};

		setSortedTrips({
			active:
				selectedUser && selectedUser.trips && selectedUser.trips.length
					? selectedUser.trips.filter(
							(trip) =>
								trip.submitted.success &&
								new Date(returnEndDate(trip)) > new Date()
					  )
					: [],
			archived:
				selectedUser && selectedUser.trips && selectedUser.trips.length
					? selectedUser.trips.filter((trip) => {
							return (
								trip.submitted.success &&
								new Date(returnEndDate(trip)) <= new Date()
							);
					  })
					: [],
		});
		setUserPreferences({
			homeAirport,
			nonStop,
			flightPrice,
			seatLocation,
			seating,
			fareType,
			checkedBag,
			tripInsurance,
			lodgingPrice,
			idealLocation,
			lodgingType,
			driveVsDriver,
			publicTransportation,
			publicVsPrivate,
			activitySamples,
			tours,
			privateVsPublicTour,
			sightSeeingTours,
			physicalActivity,
			specialEventInterest,
		});
	}, [selectedUser, trips, userId]);

	useEffect(() => {
		if (tripId) {
			handleShowTrip(tripId);
		}
	}, [tripId]);

	const handleComponent = () => {
		if (selectedTrip) {
			return (
				<AuthorizedContainer
					containerClass='user-dashboard'
					items={returnTripItemsByKey(selectedTrip, currentSecondaryContent)}
				/>
			);
		} else if (isAuthorized || isPaid) {
			return (
				<AuthorizedContainer
					containerClass='user-dashboard'
					items={returnAuthorizedItems(currentMainContent)}
				/>
			);
		} else if (activeOrArchived) {
			return (
				<ItemList
					handleShowTrip={handleShowTrip}
					containerClass='user-dashboard'
					user={selectedUser}
					trips={sortedTrips[currentMainContent]}
					isUserProfile={true}
					redirectPath='/dashboard/trip/'
				/>
			);
		} else if (isPreferences) {
			return <UserPreferenceSection preferences={userPreferences} />;
		}
	};

	const toggleMainComponent = (string) => {
		removeSelectedTrip();
		setCurrentMainContent(string);
	};

	const handleMainHighlight = (name) => {
		return currentMainContent === name ? 'highlighted' : '';
	};
	const handleSecondaryHighlight = (name) => {
		return currentSecondaryContent === name ? 'highlighted' : '';
	};

	return !adminLoading ? (
		<>
			<section className='user-content'>
				<nav className='user-subnav'>
					<button
						name='active'
						className={handleMainHighlight('active')}
						onClick={() => toggleMainComponent('active')}>
						Active Trips
					</button>
					<button
						name='archived'
						className={handleMainHighlight('archived')}
						onClick={() => toggleMainComponent('archived')}>
						Archived Trips
					</button>
					<button
						className={handleMainHighlight('authorized')}
						onClick={() => toggleMainComponent('authorized')}>
						Authorized Items
					</button>
					<button
						className={handleMainHighlight('paid')}
						onClick={() => toggleMainComponent('paid')}>
						Paid Items
					</button>
					<button
						className={handleMainHighlight('preferences')}
						onClick={() => toggleMainComponent('preferences')}>
						Preferences
					</button>
				</nav>
				{currentMainContent === 'trip' && selectedTrip ? (
					<nav className='user-subnav'>
						<button
							className={handleSecondaryHighlight('itineraryItems')}
							onClick={() => setCurrentSecondaryContent('itineraryItems')}>
							All
						</button>
						<button
							className={handleSecondaryHighlight('selected')}
							onClick={() => setCurrentSecondaryContent('selected')}>
							Selected
						</button>
						<button
							className={handleSecondaryHighlight('paid')}
							onClick={() => setCurrentSecondaryContent('paid')}>
							Paid
						</button>
						<button
							className={handleSecondaryHighlight('cart')}
							onClick={() => setCurrentSecondaryContent('cart')}>
							Cart
						</button>
						<button
							className={handleSecondaryHighlight('authorized')}
							onClick={() => setCurrentSecondaryContent('authorized')}>
							Authorized
						</button>
						<a href={`/create-itinerary/${selectedTrip._id}`}>Edit</a>
					</nav>
				) : null}

				{handleComponent()}
			</section>
		</>
	) : (
		<div className='newcss-sweet-loading newcss-80vh'>
			<FadeLoader css={override} size={150} color={'black'} loading={true} />
		</div>
	);
};

const mapStateToProps = (state) => ({
	selectedTrip: state.admin.selectedTrip,
	selectedUser: state.admin.selectedUser,
	adminLoading: state.admin.loading,
});

export default connect(mapStateToProps, {
	removeSelectedTrip,
	setSelectedTrip,
})(UserContent);
