import React, { Fragment } from 'react';
import DayOptions from './DayOptions';
import FormField from '../FormField';

const ManyTimes = ({
	handleChange,
	handleDayOptions,
	handleRange,
	formData,
	timeRange,
	handleTimeRange,
	setTimeInterval,
	timeInterval,
	dayOptionCallback,
}) => {
	let { dayOptions, dateRange, startTimeRange, endTimeRange, timeLength } =
		formData || {};

	const cleanDate = (date) => {
		let result;

		if (date instanceof Date) {
			result = date.toISOString();
		} else {
			result = date;
		}

		return typeof result === 'string' ? result.slice(0, 10) : '';
	};
	return (
		<Fragment>
			<DayOptions
				dayOptions={dayOptions}
				handleChange={handleDayOptions}
				dayOptionCallback={dayOptionCallback}
			/>
			<div className='flex-section'>
				<div className='text-field'>
					<label htmlFor='start'>Start Date:</label>
					<input
						required
						value={(dateRange && cleanDate(dateRange.start)) || ''}
						type='date'
						name='start'
						onChange={handleRange}
					/>
				</div>
				<div className='text-field'>
					<label htmlFor='end'>End Date:</label>
					<input
						required
						value={(dateRange && cleanDate(dateRange.end)) || ''}
						type='date'
						name='end'
						onChange={handleRange}
					/>
				</div>
			</div>

			<div className='flex-section'>
				<FormField
					required={true}
					name='startTimeRange'
					type='time'
					step='1800'
					value={startTimeRange}
					handleChange={handleChange}
					title='Start Time'
				/>
				<FormField
					required={true}
					name='endTimeRange'
					type='time'
					step='1800'
					value={endTimeRange}
					handleChange={handleChange}
					title='End Time'
				/>
			</div>

			<div className='flex-section'>
				<div className='text-field'>
					<label htmlFor='timeInterval'>Interval By (in hours):</label>
					<input
						required
						type='text'
						name='timeInterval'
						value={timeInterval || ''}
						onChange={handleChange}
					/>
				</div>
				<div className='text-field'>
					<label htmlFor='timeLength'>Activity Length (in hours):</label>
					<input
						required
						type='number'
						name='timeLength'
						value={timeLength || ''}
						onChange={handleChange}
					/>
				</div>
			</div>
		</Fragment>
	);
};

export default ManyTimes;
