import React, { useEffect } from 'react';
import UserMain from '../userProfile/UserMain';
import { connect } from 'react-redux';
import { selectUser, removeUser } from '../../redux/Actions/adminActions';
import SideNav from '../sidenav/SideNav';

const UserProfile = ({
	selectedUser,
	match,
	selectUser,
	removeUser,
	adminLoading,
}) => {
	const returnComponent = () => {
		if (selectedUser) {
			return (
				<div className='user-profile'>
					<SideNav adminLoading={adminLoading} />

					<UserMain
						adminLoading={adminLoading}
						userId={selectedUser._id}
						trips={selectedUser.trips}
					/>
				</div>
			);
		}
	};

	useEffect(() => {
		if (match && match.params && match.params.id) {
			selectUser(match.params.id, false);
		}
		return removeUser();
	}, [match]);
	return <>{returnComponent()}</>;
};

const mapStateToProps = (state) => ({
	selectedUser: state.admin.selectedUser,
	adminLoading: state.admin.loading,
});

export default connect(mapStateToProps, { selectUser, removeUser })(
	UserProfile
);
