import React from 'react';
import { connect } from 'react-redux';
import { selectAuthItem } from '../../redux/Actions/authItem';
import { checkItemStatus } from '../../Utilities/helperFunctions';
import ListItemMain from './ListItemMain';
import ListItemOptions from './ListItemOptions';
import ListItemType from './ListItemType';

const ListItemNew = ({ item, itemKey, handleClick, isTrip }) => {
	const { itemPaid, itemAuthorized, itemSelected, itemInCart } = !isTrip
		? checkItemStatus(item.trip, item)
		: {};
	const handleCustomer = () => {
		if (isTrip) {
			return item.user.email;
		} else {
			return item.trip.user.email;
		}
	};

	const handleStatus = () => {
		if (isTrip) {
			return item.curated ? 'Curated' : 'New';
		} else {
			console.log({ itemPaid, itemAuthorized, itemSelected, itemInCart });
			return item.status || 'N/A';
		}
	};

	return (
		<div className={`item-list__item${isTrip ? ' trip' : ''}`}>
			<button onClick={handleClick}>
				<ListItemMain item={item} itemKey={itemKey} />
				<ListItemType itemKey={itemKey} />
				<div className='item-list__item-status capitalize'>
					{handleStatus()}
				</div>
				<div className='item-list__item-customer'>{handleCustomer()}</div>
				{isTrip && (
					<div className='item-list__item-customer capitalize'>
						{item.user.firstName} {item.user.lastName}
					</div>
				)}
			</button>
			<ListItemOptions />
		</div>
	);
};

export default connect(null, { selectAuthItem })(ListItemNew);
