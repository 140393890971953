const initState = {
	flights: null,
	amadeusCars: null,
	hotels: null,
};

const selectedItemMapsReducer = (state = initState, action) => {
	const { type, payload } = action;
	switch (type) {
		case 'SET_NEW_MAP':
			return {
				...state,
				[payload.key]: payload,
			};

		case 'ADD_MAP_ITEM':
			return {
				...state,
				[payload.key]: { ...state[payload.key], [payload.item]: true },
			};
		case 'SET_ALL_MAPS':
			return {
				...state,
				...payload,
			};
		case 'FETCH_FLIGHTS_ERROR':
			return state;

		case 'SET_FLIGHTS_LOADING':
			return {
				...state,
				loading: true,
			};
		case 'SET_ITINERARY_FLIGHTS':
			return {
				...state,
				itineraryFlights: action.payload,
			};
		case 'CLEAR_FLIGHT_QUERY':
			return {
				flights: [],
				flightsDictionary: [],
				selectedFlight: [],
				loading: false,
			};

		default:
			return state;
	}
};

export default selectedItemMapsReducer;
