import React from 'react';
import { setRedirect } from '../../redux/Actions/redirectActions';
import { connect } from 'react-redux';
import ListItemNew from '../listItems/ListItemNew';
import { getTrip } from '../../redux/Actions/trip';

const ItemList = ({
	trips,
	redirectPath,
	setRedirect,
	containerClass,
	isUserProfile,
	handleShowTrip,
	newTrips,
}) => {
	const handleClick = (trip) => {
		if (isUserProfile) {
			handleShowTrip(trip._id);
		} else {
			if (newTrips) {
				setRedirect(`${redirectPath}${trip._id}`);
			} else {
				setRedirect(`${redirectPath}${trip.user._id || trip.user}/${trip._id}`);
			}
		}
	};
	const returnTrips = () => {
		return trips && trips.length
			? trips.map((trip, tripIndex) => {
					trip.type = 'trip';
					console.log(
						`${redirectPath}/${trip.user._id || trip.user}/${trip._id}`
					);
					return (
						<ListItemNew
							isUserProfile={isUserProfile}
							handleShowTrip={handleShowTrip}
							isTrip={true}
							key={'trip' + tripIndex}
							itemKey='trip'
							handleClick={(e) => {
								e.preventDefault();
								handleClick(trip);
							}}
							item={trip}
							index={tripIndex}
						/>
					);
			  })
			: null;
	};

	return (
		<div
			className={`item-list__container${
				containerClass ? ` ${containerClass}` : ''
			}`}>
			<header className='item-list__header trip'>
				<span className='item-list__header-item'>Item</span>
				<span className='item-list__header-type'>Type</span>
				<span className='item-list__header-status'>Status</span>
				<span className='item-list__header-customer'>Email</span>
				<span className='item-list__header-customer'>Full Name</span>
				<span className='item-list__header-options'>Options</span>
			</header>
			<div className='item-list__container-main'>{returnTrips()}</div>
		</div>
	);
};

export default connect(null, { setRedirect, getTrip })(ItemList);
