import React from 'react';
import moment from 'moment';

const CustomItemReview = ({ item, lodging }) => {
	return item && item.trip && item.trip._id ? (
		<>
			<h3 className='auth-item__header'>{item.name}</h3>
			<main className='auth-item__main'>
				<div className='left'>
					{item.url ? (
						<button
							href={item.url}
							onClick={() => window.open('https://' + item.url, '_blank')}
							className='itinerary-item-url'
							target='_blank'
							rel='noopener noreferrer'>
							{lodging && 'hotel '}website
						</button>
					) : (
						<span className='no-url'>No URL</span>
					)}
					<div className='text-item'>
						<span>Type: </span>
						<span className='data'>{item.type}</span>
					</div>
					<div className='text-field'>
						<span>Trip ID: </span>

						<span className='data'>{item.trip._id}</span>
					</div>
					<div className='text-field'>
						<span>From: </span>
						<span className='data'>{moment(item.start).format('lll')}</span>
					</div>
					<div className='text-field'>
						<span>To: </span>
						<span className='data'>{moment(item.end).format('lll')}</span>
					</div>
					<div className='text-field'>
						<span>Price Per Units: </span>
						<span className='data'>
							{`$${
								item.price
									? item.price.amount
									: item.data.recommendation.pricing_info.total_amount
							}`}
						</span>
					</div>
				</div>
				<div className='right'>
					<div className='text-field'>
						<span>Units: </span>
						<span className='data'>{item.units}</span>
					</div>
					<div className='text-field'>
						<span>Group Capacity: </span>
						<span className='data'>{item.groupCapacity || 'N/A'}</span>
					</div>
					<div className='text-field'>
						<span>Total: </span>
						<span className='data'>
							{`$${
								item.price
									? item.price.amount * item.units
									: item.data.recommendation.pricing_info.total_amount
							}`}
						</span>
					</div>
				</div>
			</main>
		</>
	) : null;
};

export default CustomItemReview;
