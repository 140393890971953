import React from 'react';
import FormField from '../FormField';

// Referenced in ../TransportForm.js
const FormFieldSection = ({
	formData,
	handleChange,
	handlePrice,
	handleDateRange,
	setTimeInterval,
	timeInterval,
	hotelDetails,
}) => {
	let {
		name,
		description,
		url,
		startLocation,
		endLocation,
		imageUrl,
		price,
		groupCapacity,
		dateRange,
		startTimeRange,
		endTimeRange,
		timeLength,
		type,
	} = formData || {};

	const handleDate = (date) => {
		if (date) {
			return typeof date === 'string'
				? date.slice(0, 10)
				: date.toString().slice(0, 10);
		}
		return null;
	};
	return (
		<>
			<div className='text-field'>
				<label htmlFor='type'>Transport Type</label>
				<select
					name='type'
					id='type'
					onChange={handleChange}
					className='dropbox'>
					<option value=''>--Please choose an option--</option>
					<option value='public-transport'>Public Transport</option>
					<option value='private-transport'>Private Transport</option>
				</select>
			</div>

			<FormField
				required={true}
				name='name'
				type='text'
				value={name}
				handleChange={handleChange}
				title='Name'
			/>
			<div className='text-field'>
				<label htmlFor='description'>Description:</label>
				<textarea
					required
					value={description}
					type='text'
					name='description'
					onChange={handleChange}
					maxLength='500'></textarea>
			</div>

			<FormField
				required={false}
				name='url'
				type='text'
				value={url}
				handleChange={handleChange}
				title='Website'
			/>

			<div className='text-field'>
				<label htmlFor='cityName'>City Name:</label>

				<input
					onChange={handleChange}
					name='startLocation'
					id='startLocation'
					type='text'
					list='locations'
					value={startLocation || ''}
					autoComplete='off'
				/>
				<datalist id='locations'>
					{hotelDetails.map((detail) => {
						return <option name='startLocation'>{detail.city.cityName}</option>;
					})}
					{/* <option>Volvo</option>
						<option>Saab</option>
						<option>Mercedes</option>
						<option>Audi</option> */}
				</datalist>
			</div>
			{/* <FormField
				required={true}
				name='startLocation'
				type='text'
				value={startLocation}
				handleChange={handleChange}
				title='Start Location'
			/> */}
			<FormField
				required={true}
				name='endLocation'
				type='text'
				value={endLocation}
				handleChange={handleChange}
				title='End Location'
			/>
			<FormField
				required={true}
				name='imageUrl'
				type='text'
				value={imageUrl}
				handleChange={handleChange}
				title='Photo Url'
			/>

			<div className='flex-section'>
				<FormField
					required={true}
					name='amount'
					type='number'
					value={price && price.amount}
					handleChange={handlePrice}
					title={
						type === 'public-transport' ? 'Price Per Person' : 'Total Price'
					}
				/>
				<FormField
					required={true}
					name='currencyCode'
					type='text'
					value={price && price.currencyCode}
					handleChange={handlePrice}
					title='Currency Code'
				/>
				{type === 'private-transport' && (
					<div className='text-field'>
						<label htmlFor='groupCapacity'> Group Capacity </label>
						<input
							required
							value={groupCapacity}
							type='number'
							name='groupCapacity'
							onChange={handleChange}
						/>
					</div>
				)}
			</div>

			<h4>Ranges</h4>

			<div className='flex-section'>
				<FormField
					required={true}
					name='start'
					type='date'
					value={handleDate(dateRange?.start)}
					handleChange={handleDateRange}
					title='Start Date'
				/>

				<FormField
					required={true}
					name='end'
					type='date'
					value={handleDate(dateRange?.end)}
					handleChange={handleDateRange}
					title='End Date'
				/>
			</div>

			<div className='flex-section'>
				<FormField
					required={true}
					name='startTimeRange'
					type='time'
					step='1800'
					value={startTimeRange}
					handleChange={handleChange}
					title='Start Time'
				/>
				<FormField
					required={true}
					name='endTimeRange'
					type='time'
					step='1800'
					value={endTimeRange}
					handleChange={handleChange}
					title='End Time'
				/>
			</div>

			<div className='flex-section'>
				<FormField
					required={true}
					name='timeInterval'
					type='number'
					value={timeInterval}
					handleChange={(e) => {
						e.preventDefault();
						setTimeInterval(e.target.value);
					}}
					title='Interval By (in hours):'
				/>
				<FormField
					required={true}
					name='timeLength'
					type='number'
					handleChange={handleChange}
					title='Activity Length (in hours):'
					value={timeLength}
				/>
			</div>

			<button className='cta-button '>Generate Card</button>
		</>
	);
};

export default FormFieldSection;
