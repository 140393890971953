import React, { Fragment } from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import moment from 'moment';
import { returnItemFields } from '../../Utilities/helperFunctions';
const CancelPenalties = ({ cancelPenalties }) => {
	const returnDateFormat = (date) => moment(new Date(date)).format('LLLL');
	const returnDeadline = (deadline) =>
		deadline && (
			<div>
				<span>
					- Cancellations need to be made by {returnDateFormat(deadline)} local
					time
				</span>
			</div>
		);

	const returnAmountCharged = (amountCharged, currencyCode, deadline) =>
		amountCharged && (
			<div>
				<span>
					- You will be charged {getSymbolFromCurrency(currencyCode)}
					{amountCharged} if cancellation is made after{' '}
					{returnDateFormat(deadline)}
				</span>
			</div>
		);

	const returnPercentCharged = (percentCharged, deadline) =>
		percentCharged && (
			<div>
				<span>
					- You will be charged {percentCharged}% of total cost if cancellation
					is made after {returnDateFormat(deadline)}
				</span>
			</div>
		);

	const destructurePenalties = () => {
		return cancelPenalties.map((penalty, index) => {
			const { AbsoluteDeadline, Amount, Percent, CurrencyCode, NonRefundable } =
				returnItemFields(penalty, [
					'AbsoluteDeadline',
					'Amount',
					'Percent',
					'CurrencyCode',
					'NonRefundable',
				]);
			return NonRefundable !== 'true' ? (
				<Fragment key={`cancel-penalty-item-${index}`}>
					<label>Cancellation Policy: </label>
					{AbsoluteDeadline && returnDeadline(AbsoluteDeadline)}
					{Amount && CurrencyCode && AbsoluteDeadline
						? returnAmountCharged(Amount, CurrencyCode, AbsoluteDeadline)
						: null}
					{Percent && AbsoluteDeadline
						? returnPercentCharged(Percent, AbsoluteDeadline)
						: null}
				</Fragment>
			) : (
				<span>- Non-Refundable</span>
			);
		});
	};
	return <div className='cancel-penalties'>{destructurePenalties()}</div>;
};

export default CancelPenalties;
