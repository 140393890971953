import React, { Fragment } from 'react';
import { connect } from 'react-redux';

const HotelPrice = ({
	roomRates,
	total,
	currencyCode,
	conversionTotal,
	commission,
}) => {
	const handleNightRateDisplay = () => {
		return (
			<>
				{conversionTotal ? (
					<>
						{conversionTotal} /night
						<br />
						{total} /night
					</>
				) : (
					<>{total} /night</>
				)}
				<br />

				{commission && (
					<>
						{commission.info.StatusType}
						{commission.info.Percent
							? ` by ${parseFloat(commission.info.Percent)}%`
							: ''}
					</>
				)}
			</>
		);
	};

	return roomRates ? (
		<Fragment>
			<span className='price'>{handleNightRateDisplay()}</span>
		</Fragment>
	) : null;
};

const mapStateToProps = (state) => {
	return {
		hotelsDictionaries: state.hotel.hotelsDictionaries,
	};
};

export default connect(mapStateToProps, null)(HotelPrice);
