import React from 'react';
import arrow from '../../images/noun_Arrow_1256499.svg';

import noImage from '../../images/noun_Camera_prohibited_1554484.svg';
import FeeItem from './FeeItem';
import ListItemNew from '../listItems/ListItemNew';
import { selectAuthItem } from '../../redux/Actions/authItem';
import { connect } from 'react-redux';
import { checkType } from '../../Utilities/helperFunctions';

const AuthorizedContainer = ({
	items,
	toggleContainer,
	authKey,
	selectAuthItem,
	containerClass,
}) => {
	const mapItems = () => {
		return authKey === 'booking' || authKey === 'curation'
			? items.map((item, index) => {
					return <FeeItem index={index} item={item} type={authKey} />;
			  })
			: items.map((item, index) => {
					const { key: itemKey } = checkType(item.type);

					return (
						<ListItemNew
							item={item}
							itemKey={itemKey}
							handleClick={() => selectAuthItem(item._id, itemKey)}
							index={index}
						/>
					);
			  });
	};

	return (
		<div
			className={`item-list__container ${
				containerClass ? ` ${containerClass}` : ''
			}`}>
			{toggleContainer && (
				<button onClick={toggleContainer} className='auth-item__back'>
					<img
						src={arrow}
						alt=''
						onError={({ currentTarget }) => {
							currentTarget.onerror = null; // prevents looping
							currentTarget.src = noImage;
						}}
					/>
				</button>
			)}
			<header
				className={`item-list__header${
					authKey === 'booking' || authKey === 'curation' ? ' fee-item' : ''
				}`}>
				<span className='item-list__header-item'>Item</span>
				<span className='item-list__header-type'>Type</span>
				<span className='item-list__header-status'>Status</span>
				<span className='item-list__header-customer'>Customer</span>
				{authKey === 'booking' || authKey === 'curation' ? (
					<span className='item-list__header-customer'>Customer ID</span>
				) : null}
				<span className='item-list__header-options'>Options</span>
			</header>

			{items && items.length ? mapItems() : null}
		</div>
	);
};

export default connect(null, { selectAuthItem })(AuthorizedContainer);
