export const addError = (string) => (dispatch) => {
	dispatch({
		type: 'ADD_ERROR',
		payload: string,
	});
};

export const removeErrors = () => (dispatch) => {
	dispatch({
		type: 'REMOVE_ERRORS',
	});
};
