import React from 'react';
import UserContent from './UserContent';

const UserMain = ({ userId }) => {
	return (
		<div className='user-main'>
			<UserContent userId={userId} />
		</div>
	);
};

export default UserMain;
