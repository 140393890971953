import React from 'react';

const Guarantee = ({ guaranteeType }) => {
	return (
		<div className='guarantee'>
			<label htmlFor=''>Payment:</label>
			<span>
				-{' '}
				{guaranteeType === 'Guarantee Required'
					? 'Guarantee Required'
					: 'Deposit Needed'}
			</span>
		</div>
	);
};

export default Guarantee;
