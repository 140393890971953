import React from 'react';
import { connect } from 'react-redux';
import { FadeLoader } from 'react-spinners';
import css from '@emotion/css';
import CarReview from './CarReview';
import { sanitizeCarData } from '../../Utilities/carHelpers';

const override = css`
	display: block;
	margin: 5rem auto;
	border-color: red;
`;

const CarResults = ({ cars, loading, itineraryCars }) => {
	const returnEstimatedTotal = (rate) => {
		const { rateDetailsInfo } = rate.carDetail;
		const estimatedTotal =
			rateDetailsInfo &&
			rateDetailsInfo.find((item) => item.tariffInfo.rateType === '906');
		return parseFloat(estimatedTotal.tariffInfo.rateAmount);
	};
	const returnDetails = () => {
		const selectedRates = {};

		itineraryCars.forEach((rate) => {
			sanitizeCarData(rate.data);
			const companyCode = rate.data.carCompanyData.companyCode;
			const rateIdentifier = rate.data.rateIdentifier.referenceDetails[0].value;
			selectedRates[`${companyCode}${rateIdentifier}`] = true;
		});

		let spreadRates =
			cars && cars.length
				? cars
						.map((company) => {
							return company.rates.map((rate) => {
								return {
									companyLocation: company.location,
									carDetail: rate,
									companyCode: company.data.companyCode,
									termsAndConditions: company.termsAndConditions,
								};
							});
						})
						.flat()
						.sort((a, b) => {
							const aEstimated = returnEstimatedTotal(a);
							const bEstimated = returnEstimatedTotal(b);
							return aEstimated - bEstimated;
						})
						.filter((rate) => {
							const companyCode = rate.carDetail.carCompanyData.companyCode;
							const rateIdentifier =
								rate.carDetail.rateIdentifier.referenceDetails[0].value;
							return !selectedRates[`${companyCode}${rateIdentifier}`];
						})
				: null;

		return spreadRates && spreadRates.length
			? spreadRates.map((rate, index) => {
					if (index < 10) {
						return (
							<CarReview
								rate={rate}
								{...sanitizeCarData(rate.carDetail, rate.companyLocation)}
							/>
						);
					} else {
						return null;
					}
			  })
			: null;
	};

	return (
		<>
			<div></div>
			<div className='car-results'>
				{loading ? (
					<FadeLoader
						css={override}
						size={150}
						color={'black'}
						loading={true}
					/>
				) : (
					returnDetails()
				)}
			</div>
		</>
	);
};

CarResults.propTypes = {};

const mapStateToProps = (state) => ({
	itineraryCars: state.trip.itineraryItems.amadeusCars,
	cars: state.cars.cars,
	loading: state.cars.loading,
});

export default connect(mapStateToProps, null)(CarResults);
