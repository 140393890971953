import React from 'react';
import Popup from 'reactjs-popup';
import ImageInput from '../authorizedItems/ImageInput';
import { useState } from 'react';
import FormField from '../CustomData/FormField';
import LoadingOverlay from '../Layout/LoadingOverlay';
import { uploadDocument } from '../../redux/Actions/trip';
import { connect } from 'react-redux';

const AttachmentPopup = ({
	handleClick,
	trip_id,
	uploadDocument,
	buttonText,
}) => {
	const [name, setName] = useState('');
	const [file, setFile] = useState('');
	const [fileName, setFileName] = useState('');
	const [loading, setLoading] = useState(true);
	const [submitted, setSubmitted] = useState(false);
	const [success, setSuccess] = useState(false);

	const handleSetImage = (e) => {
		const { files } = e.target;
		const imageFile = files[0];
		setFileName(imageFile.name);
		setFile(imageFile);
	};

	const handleUploadImage = async (e, close) => {
		setSubmitted(true);
		setLoading(true);
		let body = new FormData();
		body.append('file', file);
		if (name) {
			body.append('name', name);
		}

		body.append('trip_id', trip_id);

		try {
			const res = await uploadDocument(body);

			if (res) {
				setSuccess(true);
				setLoading(false);
			}
			setTimeout(() => {
				setSubmitted(false);
				setSuccess(false);
				setFile('');
				setName('');
				setLoading(true);
				close();
			}, 3000);
		} catch (err) {
			setSuccess(false);
			setLoading(false);
			setTimeout(() => {
				setSubmitted(false);
				setLoading(true);
			}, 3000);
		}
	};
	console.log('file.target', file.name);
	return (
		<>
			<Popup
				trigger={
					<button
						onClick={handleClick}
						style={{
							backgroundColor: 'none',
							border: 'none',
						}}>
						{buttonText}
					</button>
				}
				modal>
				{(close) => (
					<div className='popup' id='popup'>
						<div className='popup__content'>
							{submitted && (
								<LoadingOverlay loading={loading} success={success} />
							)}
							<div className='popup__header'>
								<h3 className='popup__header--text'>Add Attachment</h3>
								<button
									className='popup__close'
									onClick={(e) => {
										e.preventDefault();
										close();
									}}>
									<i className='fa fa-times'></i>
								</button>
							</div>
							<FormField
								value={name}
								name='name'
								type='text'
								title='Title'
								handleChange={(e) => setName(e.target.value)}
							/>
							<ImageInput fileName={fileName} handleImage={handleSetImage} />
							<button onClick={(e) => handleUploadImage(e, close)}>
								Submit
							</button>
						</div>
					</div>
				)}
			</Popup>
		</>
	);
};

export default connect(null, { uploadDocument })(AttachmentPopup);
