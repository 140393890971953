import { get, post, put } from '../../Utilities/api';
import { filterAuthItem } from './adminActions';
import { setCurrentForm } from './trip';

const types = require('creditcards-types');

export const selectAuthItem = (item_id, key) => async (dispatch) => {
	try {
		const authRequired = true;
		const res = await get(
			`/api/v1/data/private/itinerary-item/${key}/${item_id}`,
			{},
			authRequired
		).catch((err) => {
			throw new Error(err.message);
		});

		if (res.data && res.data.item) {
			dispatch(setCurrentForm('authItem'));
			dispatch({
				type: 'SELECT_AUTH_ITEM',
				payload: { item: res.data.item },
			});
		}
	} catch (error) {
		console.log(error);
	}
};

export const selectPurchaseObject = (item) => (dispatch) => {
	dispatch({
		type: 'SELECT_AUTH_ITEM',
		payload: { item },
	});
};
export const selectEditItem = (item) => (dispatch) => {
	dispatch({
		type: 'SELECT_EDIT_ITEM',
		payload: { item },
	});
};

export const selectReviewItem = (item) => (dispatch) => {
	dispatch({
		type: 'SELECT_REVIEW_ITEM',
		payload: { item },
	});
};

export const removeAuthItem = (id, item) => (dispatch) => {
	dispatch({
		type: 'REMOVE_AUTH_ITEM',
	});
};

export const updateAuthorization =
	(item, authorizedReq, key) => async (dispatch) => {
		try {
			const authRequired = true;
			const res = await put(
				'/api/v1/data/private/authorizations/update-authorization/',
				{ item, authorizedId: authorizedReq },
				authRequired
			).catch((err) => {
				throw new Error(err.message);
			});

			const { authorization } = res.data;
			if (!authorization) {
				dispatch(filterAuthItem(key, item._id));
			}
		} catch (err) {
			console.log(err);
		}
	};

const setPaidAction = (item, pathname, type) => async (dispatch) => {
	try {
		const authRequired = true;
		const res = await put(
			`/api/v1/data/private/auth-item/set-paid/${pathname}`,
			item,
			authRequired
		).catch((err) => {
			throw new Error(err.message);
		});
		if (res.data && res.data.item) {
			const payload = res.data.item;
			dispatch({
				type,
				payload,
			});
			dispatch(filterAuthItem(pathname, payload._id));
			dispatch(setCurrentForm(null));
		}
	} catch (err) {
		const message = err.message;
		console.log(type, message);
	}
};

export const setPaidLodging = (item, confirmation) => async (dispatch) => {
	dispatch({
		type: 'AUTH_ITEM_LOADING',
	});

	const { trip, data, ccData } = item;
	console.log({ trip, data });
	const { adults, children, infants, user, secondaryTravelers } = trip;
	const { hotel_info, RoomRates, RatePlans } = data;
	const { ChainCode, HotelCode, HotelCityCode } = hotel_info && hotel_info.info;
	const roomRateExists =
		RoomRates &&
		RoomRates.length &&
		RoomRates[0]['RoomRate'] &&
		RoomRates[0]['RoomRate'].length;
	const { Rate } =
		roomRateExists &&
		RoomRates[0]['RoomRate'][0]['Rates'] &&
		RoomRates[0]['RoomRate'][0]['Rates'].length &&
		RoomRates[0]['RoomRate'][0]['Rates'][0];
	const { BookingCode, RoomTypeCode, NumberOfUnits } =
		roomRateExists && RoomRates[0]['RoomRate'][0].info;
	const { GuaranteeCode, GuaranteeType } =
		RatePlans &&
		RatePlans.length &&
		RatePlans[0]['RatePlan'] &&
		RatePlans[0]['RatePlan'].length &&
		RatePlans[0]['RatePlan'][0]['Guarantee'] &&
		RatePlans[0]['RatePlan'][0]['Guarantee'].length &&
		RatePlans[0]['RatePlan'][0]['Guarantee'][0].info;
	const handleGuaranteeType = () => {
		if (GuaranteeCode === '31' && GuaranteeType === 'GuaranteeRequired')
			return '1';
		if (
			GuaranteeCode === '8' &&
			(GuaranteeType === 'DepositRequired' || GuaranteeType === 'Deposit')
		)
			return '2';
	};
	const vendorCodes = {
		Visa: 'VI',
		MasterCard: 'MC',
	};

	const cardType = types.find((type) => type.test(ccData.cc_number, true));
	let cleanedExp = ccData.cc_exp;
	cleanedExp = cleanedExp.replace(/\//g, '');
	cleanedExp = cleanedExp.replace(/\s/g, '');
	const body = {
		...ccData,
		vendor_code: vendorCodes[cardType.name],
		cc_exp: cleanedExp,
		trip_user: trip.user,
		trip_id: trip._id,
		paid_id: trip.paid,
		lodging_id: item._id,
		authorized_id: trip.authorized,
		num_adults: adults,
		num_children: children,
		num_infants: infants,
		chain_code: ChainCode,
		hotel_code: HotelCode,
		hotel_city_code: HotelCityCode,
		start_date: Rate[0].info.EffectiveDate,
		end_date: Rate[Rate.length - 1].info.ExpireDate,
		room_type: RoomTypeCode,
		guest_count: adults + children + infants,
		room_count: NumberOfUnits,
		booking_code: BookingCode,
		start_session: true,
		is_session: true,
		last_name: user.lastName,
		first_name: user.firstName,
		email: user.email,
		passengers: [
			...secondaryTravelers.map((traveler) => {
				return {
					first_name: traveler.firstName,
					last_name: traveler.lastName,
				};
			}),
			{ first_name: user.firstName, last_name: user.lastName },
		],
		rooms: [{ first_name: user.firstName, last_name: user.lastName }],

		name_title: user.nameTitle || '',
		gender: user.gender === 'female' ? 'F' : 'M',
		document_type: user.travelerDocument.idType,
		document_number: user.travelerDocument.idNum,
		deposit_or_guarantee: handleGuaranteeType(),
		form_of_payment: '1',
	};

	try {
		const authRequired = true;
		const res = await post(
			`/api/v1/amadeus_test/make_amadeus_purchase`,
			body,
			authRequired
		);
		if (res.data.success) {
			dispatch({
				type: 'SET_PAYMENT_SUCCESS',
				payload: [
					'Payment Success!',
					`Amadeus Ref: ${res.data.pnr_amadeus_reference}`,
					`Customer Ref: ${res.data.pnr_customer_reference}`,
				],
			});
			dispatch(filterAuthItem('lodging', res.data.paidItem._id));

			dispatch(setCurrentForm(null));
			return true;
		}
	} catch (err) {
		console.log(err.message);
	}
};

export const setAuthItemLoading = () => (dispatch) => {
	dispatch({
		type: 'AUTH_ITEM_LOADING',
	});
};

export const setPaidCar = (item_id) => async (dispatch) => {
	console.log('setPaidCar', item_id);
	dispatch({
		type: 'AUTH_ITEM_LOADING',
	});
	try {
		const res = await post(
			'/api/v1/amadeus_test/car_purchase',
			{ item_id },
			true
		);
		if (res.data.success) {
			dispatch({
				type: 'SET_PAYMENT_SUCCESS',
				payload: [
					'Payment Success!',
					`Amadeus Ref: ${res.data.reservationInfo.reservation[0].controlNumber[0]}`,
					res.data.pnr_customer_reference
						? `Customer Ref: ${res.data.pnr_customer_reference}`
						: null,
				],
			});
			dispatch(filterAuthItem('amadeusCars', item_id));
		}
	} catch (error) {
		console.log(error);
	}
};

export const setPaidActivity = (item, confirmation) =>
	setPaidAction(item, 'activities', 'SET_PAID_ACTIVITY');
export const setPaidTransport = (item, confirmation) =>
	setPaidAction(item, 'transport', 'SET_PAID_TRANSPORT');
export const setPaidFlight = (item, confirmation) =>
	setPaidAction(item, 'flights', 'SET_PAID_FLIGHT');
