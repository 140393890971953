import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import requireAuth from './hoc/AuthComponent';
import { getTrip } from '../redux/Actions/trip';
import redirectHOC from './hoc/RedirectHOC';
import QueryWindow from './Queries/QueryWindow';

const CreateItinerary = ({ _id, match, getTrip }) => {
	useEffect(() => {
		console.log('match', match);
		if (match.params.id !== _id) {
			getTrip(match.params.id);
		}
	}, []);
	console.log('create itinerary rerender');

	return <QueryWindow />;
};

CreateItinerary.propTypes = {
	_id: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
	_id: state.trip._id,
});

export default connect(mapStateToProps, { getTrip })(
	redirectHOC(requireAuth(CreateItinerary))
);
