import React from 'react';

const FileInput = ({
	handleChange,
	type,
	name,
	step,
	title,
	value,
	required,
	handleBlur,
	cname,
	fileName,
}) => {
	return (
		<div className={`text-field ${type}`}>
			<label htmlFor={name}>
				Choose File:
				<input
					onBlur={(e) => {
						e.preventDefault();
						handleBlur && handleBlur();
					}}
					id={name}
					required={required}
					value={value || ''}
					type={type}
					name={name}
					step={step || 'false'}
					onChange={handleChange}
					className={cname}
				/>
			</label>

			<span>{fileName || 'None Selected'}</span>
		</div>
	);
};

export default FileInput;
