import React from 'react';
import arrow from '../../images/noun_Arrow_1256499.svg';
import { Fragment } from 'react';

const TripTitle = ({ hotels }) => {
	const handleDestinations = (details) => {
		if (details && details.length) {
			return details.map((detail, index) => {
				if (index > 0) {
					return (
						<Fragment key={`destination-title-text-${index}`}>
							<img src={arrow} alt='' />
							<span className='location capitalize'>
								{detail.city.cityName.toLowerCase()}
							</span>
						</Fragment>
					);
				} else {
					return (
						<span className='location capitalize'>
							{detail.city.cityName.toLowerCase()}
						</span>
					);
				}
			});
		} else return null;
	};

	return <div className='trip__title'>{handleDestinations(hotels)}</div>;
};

export default TripTitle;
