import axios from 'axios';

// managed by a redirect in public/_redirects
const baseURL = process.env.REACT_APP_API_URL || ''; //process.env.REACT_APP_API_URL;

const makeRequest = (method, pathname, data, authRequired) => {
	const token = localStorage.getItem('authToken');
	const url = baseURL + pathname;

	let headers = {
		Accept: '*/*',
		'Content-Type':
			data instanceof FormData ? 'multipart/form-data' : 'application/json',
	};

	if (authRequired) headers = { ...headers, authorization: `Bearer ${token}` };

	return axios({ method, url, headers, data });
};

export const get = (pathname, data, authRequired) =>
	makeRequest('get', pathname, data, authRequired);

export const put = (pathname, data, authRequired) =>
	makeRequest('put', pathname, data, authRequired);

export const post = (pathname, data, authRequired) =>
	makeRequest('post', pathname, data, authRequired);

export const remove = (pathname, data, authRequired) =>
	makeRequest('delete', pathname, data, authRequired);

export const getTripById = async (id) => {
	try {
		const res = await get(`/api/v1/admin/fetch-trip/${id}`, {}, true).catch(
			(err) => {
				throw { message: err.message };
			}
		);

		return res.data.trip;
	} catch (error) {
		console.log(error);
	}
};
const returnItemsSorted = (results, prevItems) => {
	const resultsById = {};
	results.forEach((item) => (resultsById[item._id] = item));
	const mappedItems = prevItems.map((item) => resultsById[item._id]);
	return mappedItems;
};

export const amadeusRefresh = async (trip_id, type, prevItems) => {
	let endPoint = '';

	switch (type) {
		case 'hotel':
			endPoint = 'get_top_10_available';
			break;
		case 'flight':
			endPoint = 'get_top_10_flights';
			break;
		case 'carRental':
			endPoint = 'cars_to_client';
			break;
		default:
			break;
	}
	const res = await post(`/api/v1/amadeus_test/${endPoint}`, { trip_id }, true);
	switch (type) {
		case 'hotel':
			return returnItemsSorted(
				[...res.data.bookingOptions, ...res.data.notFound],
				prevItems
			);
		case 'flight':
			const flights = [...res.data.updated_flights, ...res.data.not_found];
			const sortedFlights = returnItemsSorted(flights, prevItems);
			return sortedFlights;
		case 'carRental':
			const foundValues = Object.values(
				res.data.recommended_car_rentals_by_location
			).flat();
			const notFoundValues = Object.values(
				res.data.admin_choices_with_no_match
			).flat();
			return returnItemsSorted([...foundValues, ...notFoundValues], prevItems);
		default:
			break;
	}
};
