import React, { useState } from 'react';
import ListItemOptions from '../listItems/ListItemOptions';
import { setCurrentForm } from '../../redux/Actions/trip';
import { connect } from 'react-redux';
import down from '../../images/noun-down-2367384-DEDDDD.svg';
import { setCustomForm } from '../../redux/Actions/customItemForm';
import { post } from '../../Utilities/api';

const CMTestimonials = ({
	currentList,
	containerClass,
	authKey,
	setCurrentForm,
	window,
	setCustomForm,
	setCurrentList,
}) => {
	const [currentExpanded, setCurrentExpanded] = useState(null);

	const mapItems = () => {
		return currentList.map((item, index) => {
			return (
				<>
					<div className='item-list__item testimonial' key={item._id}>
						<button
							onClick={() => {
								if (index === currentExpanded) {
									setCurrentExpanded(null);
								} else {
									setCurrentExpanded(index);
								}
							}}>
							<img
								style={{
									width: `3rem`,
									objectFit: 'initial',
									justifySelf: 'center',
									transform: `rotate(${
										index === currentExpanded ? '180deg' : '0deg'
									})`,
								}}
								src={down}
								alt=''
							/>
							<div className='item-list__item-testimonial-group'>
								{/* <img src={item.photos[0]} alt='' /> */}
								<div className='item-list__item-type capitalize'>
									{item.title}
								</div>
							</div>
							<h3 className='capitalize destinations clip-1'>
								{item &&
									item.destinations &&
									item.destinations.map((d, index) => {
										console.log('item', d);
										return index < item.destinations.length - 1
											? `${d.location.cityName}${
													d.location.stateCode !== 'null'
														? ', ' + d.location.stateCode
														: ''
											  } -> `
											: `${d.location.cityName}${
													d.location.stateCode !== 'null'
														? ', ' + d.location.stateCode
														: ''
											  }`;
									})}
							</h3>

							<div className='item-list__item-status clip-1'>
								{item.description}
							</div>
						</button>
						<div className='secondary-actions'>
							<ListItemOptions
								// show={<>
								// 	<button
								// 		onClick={(e) => {
								// 			e.preventDefault()
								// 			setCustomForm('testimonialGroup', item);
								// 			setCurrentForm('testimonialGroup');
								// 		}}>
								// 		Show Group
								// 	</button>
								// </>}
								edit={
									<>
										<button
											onClick={(e) => {
												e.preventDefault();
												setCustomForm('testimonialGroup', item);
												setCurrentForm('testimonialGroup');
											}}>
											Edit Group
										</button>
									</>
								}
								remove={
									<>
										<button
											onClick={async (e) => {
												e.preventDefault();
												const res = await post(
													'/api/v1/data/private/testimonial-group/remove',
													item,
													true
												);
												if (res.status === 200) {
													setCurrentList(
														currentList.filter((item, listIndex) => {
															return listIndex !== index;
														})
													);
												}
											}}>
											Remove Group
										</button>
									</>
								}
							/>
						</div>
					</div>
					{index === currentExpanded
						? item.testimonials?.map((t) => {
								return (
									<div className='item-list__item feedback' key={t._id}>
										<button
										// onClick={() => {
										// 	if (index === currentExpanded) {
										// 		setCurrentExpanded(null);
										// 	} else {
										// 		setCurrentExpanded(index);
										// 	}
										// }}
										>
											<h3 className='capitalize destinations'>
												{t.travelerAmount} travelers
											</h3>

											<div className='item-list__item-status clip-1'>
												{t.feedback}
											</div>
										</button>
										<div className='secondary-actions'>
											<ListItemOptions
												edit={
													<>
														<button
															onClick={(e) => {
																e.preventDefault();
																setCustomForm('testimonial', t);
																setCurrentForm('testimonial');
															}}>
															Edit Testimonial
														</button>
													</>
												}
											/>
										</div>
									</div>
								);
						  })
						: null}
				</>
			);
		});
	};

	return (
		<>
			<div
				className={`item-list__container testimonials ${
					containerClass ? ` ${containerClass}` : ''
				}`}>
				<header className={`item-list__header testimonial`}>
					<span></span>
					<span className='item-list__header-item'>Group</span>
					<span className='item-list__header-type'>Destinations</span>
					{/* <span className='item-list__header-type'>Traveler Amount</span> */}
					<span className='item-list__header-status'>Description</span>
					{/* <span className='item-list__header-status'>Feedback</span> */}

					<span className='item-list__header-options'>Options</span>
				</header>

				{currentList && currentList.length && window === 'testimonials'
					? mapItems()
					: null}
			</div>
		</>
	);
};

export default connect(null, { setCurrentForm, setCustomForm })(CMTestimonials);
