import React from 'react';

const ListItemHotel = ({
	hotelName,
	roomTypeCode,
	ratePlanCode,
	rates,
	policy,
	checkinTime,
	checkoutTime,
	imageURL,
}) => {
	return (
		<section className='item-list__hotel'>
			<img src={imageURL} alt='' />
			<div className='item-list__hotel-details'>
				<h3 className='capitalize'>{hotelName.toLowerCase()}</h3>
				<div className='codes'>
					<span>Room Type Code: {roomTypeCode}</span>{' '}
					<span>Rate Plan Code: {ratePlanCode}</span>
				</div>
				{policy ? (
					<div className='checkin-checkout'>
						<span>
							Check In Time: {rates[0].info.EffectiveDate} {checkinTime}
						</span>
						<span>
							Check Out Time:{' '}
							{rates && rates.length && rates[rates.length - 1]['Total']
								? rates[rates.length - 2].info.ExpireDate
								: rates[rates.length - 1].info.ExpireDate}{' '}
							{checkoutTime}
						</span>
					</div>
				) : (
					<div className='checkin-checkout'>
						<span>
							Check In Date:{' '}
							{rates && rates.length && rates[0].info.EffectiveDate}
						</span>
						<span>
							Check Out Date:{' '}
							{rates && rates.length && rates[rates.length - 1]['Total']
								? rates[rates.length - 2].info.ExpireDate
								: rates[rates.length - 1].info.ExpireDate}
						</span>
					</div>
				)}
			</div>
		</section>
	);
};

export default ListItemHotel;
