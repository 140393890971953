import React, { Fragment, useEffect, useState } from 'react';
import RoomSummaryNew from './RoomSummaryNew';
import { sanitizeHotelData } from '../../Utilities/hotelHelpers';
import { connect } from 'react-redux';

const RoomStays = ({
	roomStays,
	hotelIndex,
	descriptive_info,
	trip,
	hotelDetail,
	customUrl,
}) => {
	const [currentSelected, setCurrentSelected] = useState(null);
	const trimData = (payload) => {
		const trimmedData = {
			type: 'hotel-offer',
			data: payload,
			customUrl,
			hotelDetail,
			trip: trip._id || trip,
		};
		return trimmedData;
	};

	useEffect(() => {
		const selectedMap = {};
		trip.itineraryItems.lodging.forEach((item) => {
			let comparisonHotel = {
				type: 'hotel-offer',
				hotelDetail,
				data: item.data,
				trip: item.trip?._id || item.trip,
			};

			selectedMap[JSON.stringify(comparisonHotel)] = true;
		});
		setCurrentSelected(selectedMap);
	}, []);

	return (
		<>
			{roomStays && roomStays.length
				? roomStays.map((room, index) => {
						const trimmedData = trimData({
							descriptive_info,
							...room,
						});

						if (
							currentSelected &&
							currentSelected[JSON.stringify(trimmedData)]
						) {
							return null;
						}
						return (
							<Fragment key={`hotel-${hotelIndex}-room-${index}`}>
								<RoomSummaryNew
									setSelected={() =>
										setCurrentSelected({
											...currentSelected,
											[JSON.stringify(trimmedData)]: true,
										})
									}
									index={index}
									hotelDetail={hotelDetail}
									isSelected={false}
									newData={trimmedData}
									data={{ room, descriptive_info }}
									{...sanitizeHotelData({ room, descriptive_info })}
								/>
							</Fragment>
						);
				  })
				: null}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		hotelDetail: state.hotel.hotelDetail,
		trip: state.trip,
		descriptiveInfo: state.hotel.descriptiveInfo,
	};
};

export default connect(mapStateToProps, null)(RoomStays);
